import * as Sentry from "@sentry/gatsby";
import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";

import { ScanTicketBulk, ScanTicketOutput } from "@graphql/sdk";
import { uuid4 } from "@sentry/utils";
import actions from "@state/actions";
import { getEventId, getLoggedIn, getOrgId } from "@state/selectors";
import * as api from "@util/api";
import { scanStorage } from "@util/cache";
import { groupBy } from "lodash";

type ScanTicketBulkWithError = ScanTicketBulk & { error?: boolean };

export default function* syncPendingScans(): SagaIterator {

  const loggedIn = yield select(getLoggedIn);

  if (!loggedIn) {
    console.log("Not logged in, no sync");
    return;
  }

  // Grab prending scans from the local scan cache
  const pendingScans: ScanTicketBulkWithError[] = yield call(scanStorage!.values);

  if (pendingScans.length === 0) {
    console.log("No tickets to sync");
    return;
  }

  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  console.debug("Syncing scans:", pendingScans);
  yield put(actions.syncPendingScans.request());

  try {
    const result = yield call(api.syncTickets, { scans: pendingScans, orgId });
    if (result && result.scanTicketBulk) {

      yield put(actions.syncPendingScans.success(result));
      yield put(actions.updatePendingScanCount.success(0))

      // Mark unsuccessful scans with an error prop so we can filter them below
      result.scanTicketBulk.map((item: ScanTicketOutput, index: number) => {
        if (item.status?.toLowerCase() !== "ok") {
          console.error('bulk ticket Status not OK', item)
          pendingScans[index].error = true
        }
      })

      // Filter out the successfully saved scans and update the local ticket cache
      // with the successful scans.
      const successFullScans = pendingScans.filter(scan => !scan.error && scan.eventId === eventId).map(({ error, ...scan }) => scan)
      const byTicketNumber = groupBy(successFullScans, "ticketNumber");

      for (const ticketNumber in byTicketNumber) {

        // Necessary to convert from bulk scan type to App scan type first
        const scans = byTicketNumber[ticketNumber].map(scan => ({
          direction: scan.scanDirection,
          id: uuid4(),
          scannedAt: scan.scannedAt,
          type: scan.scanType!
        }));

        // Perform the update
        yield put(actions.updateLocalTicket({
          ticketNumber,
          ticket: { scans }
        }));
      }

      // Clean up by removing ALL synced pending scans, regardless if they 
      // have errored or not. We want this to be a silent update and there is no
      // value in keeping the errors to be retried. We should however, save a log
      // somewhere of the the unsuccessful scan saves for Flicket's own insight.
      const scanKeys = pendingScans.map(scan => scan.scannedAt);
      yield call(scanStorage!.delMany, scanKeys);

      // Send unsuccessful saves to Sentry for initial insight. This is probably 
      // not the best service for this though.
      const errorScans = pendingScans.filter(scan => scan.error).map(({ error, ...scan }) => scan);
      if (errorScans.length > 0) {
        Sentry.captureException(new Error("Offline scan sync errors"), {
          extra: {
            scans: JSON.stringify(errorScans)
          }
          
        });
      }
    } else {
      yield put(actions.syncPendingScans.failure("nothing-burger"));
    }
  } catch (error) {
    yield put(actions.syncPendingScans.failure("nothing-burger"));
  }
}
