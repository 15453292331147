import { navigate } from "gatsby";
import { useActions, useReduxState } from "re-reduced";
import React, { useState } from "react";
import styled from "styled-components";

import actions from "@state/actions";
import * as selectors from "@state/selectors";
import { assets, colors, DEBUG_MODE, pages } from "@util/constants";
import { Container, H3, P, Separator } from "@util/standard";
import { Routes } from "@util/types";
import ConfirmModal from "./confirmModal";
import { IconButton, MenuDrawer } from "./sub";

const pjson = require("../../../package.json");

const StyledHeader = styled.header`
  display: flex;
  background-color: ${colors.transparent};
  padding: 5px 10px;
  overflow: hidden;
`;

const PendingScans = styled.div`
  padding: 8px;
  background: #1E92F4;
  border-radius: 5px;
  font-size: 17px;
`

const Header = () => {
  const {
    route,
    loggedIn,
    syncing,
    isOnline,
    pendingScans,
  } = useReduxState({
    isOnline: selectors.getOnline,
    route: selectors.getRoute,
    loggedIn: selectors.getLoggedIn,
    requestStatus: selectors.getRequestStatus,
    status: selectors.getScannedTicketStatus,
    scanDirection: selectors.getScanDirection,
    pendingScans: selectors.getPendingScans,
    syncing: selectors.getSyncing,
    selectedEvent: selectors.getSelectedEvent,
    mode: selectors.getMode,
    eventId: selectors.getEventId,
  });
  const {
    updateRoute,
    logout,
    syncPendingScans,
    clearPendingScans,
    setErrorMessage,
  } = useActions(actions);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleNavigate = (route: Routes, navigateTo: string) => {
    updateRoute(route);
    navigate(navigateTo);
    if (drawerVisible) {
      setDrawerVisible(false);
    }
  };

  const handleBackClick = () => {
    if (route === "search-org") {
      updateRoute("login");
    }
    if (route === "search-tickets") {
      handleNavigate("scanner", pages.home);
    }
  };

  const handleLogoutClick = () => {
    setDrawerVisible(false);
    setModalVisible(false);
    logout();
  };


  const handleScanClick = () => {
    handleNavigate("scanner", pages.home);
  };

  const handleEventsClick = () => {
    if (!isOnline) {
      setErrorMessage("Cannot change events when offline");
    } else {
      handleNavigate("events", pages.events);
    }
  };




  const Menu = () => {
    return (
      <Container
        flexDirection="column"
        margin="40px 20px"
        justifyContent="space-between"
        height="85%"
      >
        <Container flexDirection="column">
          <Container justifyContent="space-between">
            <img
              width="30px"
              height="auto"
              src={assets.altLogo}
              onClick={handleScanClick}
            />
            <img src={assets.close} onClick={() => setDrawerVisible(false)} />
          </Container>
          <P margin="12px 0">
            <span style={{
              display: "inline-block",
              width: 10,
              height: 10,
              backgroundColor: isOnline ? "greenyellow" : "tomato",
              borderRadius: "50%"
            }} /><span style={{
              marginLeft: 5,
              fontSize: 14,
              color: "lightGrey",
              display: "inline-block"
            }}>{isOnline ? "ONLINE" : "OFFLINE"}</span></P>
             <P margin="0 0 12px" color="lightGrey">v {pjson.version}</P>
          <Separator height="0" />
          {loggedIn && <H3 onClick={handleScanClick}>SCAN</H3>}
          {loggedIn && <H3 onClick={handleEventsClick}>EVENTS</H3>}
          <H3 onClick={() => handleNavigate("config", pages.settings)}>
            SETTINGS
          </H3>
        </Container>

        <Container flexDirection="column">

          {pendingScans > 0 && (
            <PendingScans>
              {syncing && `Syncing ${pendingScans} scan${pendingScans === 1 ? "" : "s"}`}
              {!syncing && `${pendingScans} scan${pendingScans === 1 ? "" : "s"} will be synced when back online`}
            </PendingScans>
          )}

          {DEBUG_MODE && (
            <Container flexDirection="row">
              <button onClick={() => syncPendingScans()}>Sync</button>
              <button onClick={() => clearPendingScans()}>Clear</button>
            </Container>
          )}


          {loggedIn && (
            <H3
              onClick={() => {
                setDrawerVisible(false);
                setModalVisible(true);
              }}
            >
              LOGOUT
            </H3>
          )}
          {!loggedIn && (
            <H3
              onClick={() => {
                setDrawerVisible(false);
                navigate(pages.home);
              }}
            >
              LOGIN
            </H3>
          )}
        </Container>

      </Container>
    );
  };

  const handleSearchClick = () => {
    handleNavigate("search-tickets", pages.search);
  };

  return (
    <StyledHeader>
      <Container width="40px">
        {route.includes("search") ? (
          <IconButton icon="leftArrow" onClick={handleBackClick} />
        ) : (
          <IconButton icon="hamburger" onClick={() => setDrawerVisible(true)} />
        )}
        <MenuDrawer
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          placement="left"
          duration={300}
        >
          <Menu />
        </MenuDrawer>
      </Container>
      <Container width="100%" justifyContent="center">
        {route !== "login" && (
          <img
            width="70%"
            height="auto"
            src={assets.logo}
            onClick={() => navigate(pages.home)}
          />
        )}
      </Container>
      <Container width="40px">
        {route === "scanner" && (
          <IconButton icon="search" onClick={handleSearchClick} />
        )}
      </Container>

      <ConfirmModal
        modalVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        onYes={handleLogoutClick}
        text="Are you sure you want to logout?"
      />
    </StyledHeader>
  );
};

export default Header;
