import { createActions } from "re-reduced";

import {
  SearchTicketsByEventAppQueryVariables,
  TicketScanDirection,
  User
} from "@graphql/sdk";
import { Auth, DeviceTickets, Event, InitialScanPayload, Mode, ScannedTicket, ScanPayload, UpdateTicketPayload } from "@state/types";
import { ListEvent } from "@util/api";
import {
  LocalServerParams, LoginParams, ModeParams
} from "@util/interfaces";
import { Routes } from "@util/types";


export default createActions("STATE", (create) => ({
  clearState: create.action(),
  login: create.asyncAction<User, LoginParams, string>(),
  logout: create.asyncAction<boolean, void, string>(),
  getEvents: create.asyncAction<ListEvent[], void, string>(),
  selectEvent: create.asyncAction<Event>(),
  fetchEvent: create.asyncAction<Event, string, string>(),
  updateAuth: create.action<Auth>(),
  updateUser: create.asyncAction<User, void, string>(),
  selectGateId: create.action<string>(),
  setScanDirection: create.action<TicketScanDirection>(),
  updateRoute: create.action<Routes>(),
  updateLocalTicketScansList: create.asyncAction<void, void, string>(),
  updateLocalTicketList: create.asyncAction<void, void, string>(),
  updateLocal: create.action<LocalServerParams>(),
  updateOnline: create.action<boolean>(),
  setSuccessMessage: create.action<string | undefined>(),
  setErrorMessage: create.action<string | undefined>(),
  fetchSearchTicketsByEventApp: create.asyncAction<
    DeviceTickets,
    SearchTicketsByEventAppQueryVariables,
    string
  >(),
  rehydrateTicketsByEventApp: create.action<DeviceTickets>(),
  setMode: create.asyncAction<Mode, ModeParams, string>(),

  clearScanTicket: create.action(),

  setBaseUrl: create.action<string>(),
  setCameraPermission: create.action<boolean>(),
  setScannedTicketError: create.action<string>(),
  searchDeviceTickets: create.asyncAction<
    DeviceTickets,
    SearchTicketsByEventAppQueryVariables,
    string
  >(),

  scanTicket: create.asyncAction<void, InitialScanPayload>(),
  scanDeviceTicket: create.asyncAction<void, ScanPayload, string>(),
  scanTicketOnline: create.asyncAction<ScannedTicket, ScanPayload, string>(),

  syncPendingScans: create.asyncAction<void, void, string>(),
  clearPendingScans: create.asyncAction<void, void, string>(),
  updatePendingScanCount: create.asyncAction<number, void>(),
  updateLocalTicket: create.asyncAction<void, UpdateTicketPayload, void>(),
}));
