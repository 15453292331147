import { all, takeLatest } from "redux-saga/effects";

import actions from "../actions";
import clearPendingScans from "./clearPendingScans";
import fetchEvent from "./fetchEvent";
import fetchSearchTicketsByEventApp from "./fetchSearchTicketsByEventApp";
import getEvents from "./getEvents";
import login from "./login";
import logout from "./logout";
import scanTicket from "./scanTicket";
import scanTicketOnline from "./scanTicketOnline";
import scanDeviceTicket from "./scanDeviceTicket";
import searchDeviceTicket from "./searchDeviceTickets";
import setMode from "./setMode";
import syncPendingScans from "./syncPendingScans";
import updatePendingScanCount from "./updatePendingScanCount";
import updateLocalTicket from "./updateLocalTicket";

export default function* sagaWatcher() {
  yield all([
    takeLatest(actions.login.type, login),
    takeLatest(actions.login.success.type, getEvents),
    takeLatest(actions.logout.type, logout),
    takeLatest(actions.getEvents.type, getEvents),
    takeLatest(actions.fetchEvent.type, fetchEvent),
    takeLatest(
      actions.fetchSearchTicketsByEventApp.type,
      fetchSearchTicketsByEventApp
    ),
    takeLatest(actions.scanTicket.type, scanTicket),
    takeLatest(actions.scanTicketOnline.type, scanTicketOnline),
    takeLatest(actions.scanDeviceTicket.type, scanDeviceTicket),
    takeLatest(actions.setMode.type, setMode),
    takeLatest(actions.searchDeviceTickets.type, searchDeviceTicket),
    takeLatest(actions.syncPendingScans.type, syncPendingScans),
    takeLatest(actions.clearPendingScans.type, clearPendingScans),
    takeLatest(actions.updateLocalTicket.type, updateLocalTicket),
    takeLatest(actions.updatePendingScanCount.type, updatePendingScanCount),


  ]);
}
