import { AppTicketScan, FetchEventQuery, ScanTicketBulk, TicketScanDirection, TicketStatus } from "@graphql/sdk";
import * as Sentry from "@sentry/gatsby";
import { DeviceTicket } from "@state/types";
import { SCAN_STATUS } from "@util/types";
import moment from "moment";

const getScanDirection = (scan: AppTicketScan | ScanTicketBulk) => {
  return (scan as AppTicketScan)?.direction || (scan as ScanTicketBulk)?.scanDirection;
}
interface Props {
  ticket: DeviceTicket;
  scan: ScanTicketBulk;
  allScans: (AppTicketScan | ScanTicketBulk)[];
  gates: FetchEventQuery['event']['venue']['gates'];
}
interface Result {
  status: SCAN_STATUS;
  message: string;
}

export const validateScan = ({
  ticket,
  scan,
  allScans,
  gates
}: Props): Result => {

  // Asserts if ticket has been transferred
  if (ticket.status === TicketStatus.Transferred) {
    return {
      status: "issue",
      message: "Ticket has been transferred and is no longer valid.",
    };
  }

  // Asserts if ticket has been transferred
  if (ticket.status === TicketStatus.TransferPending) {
    return {
      status: "issue",
      message:
        "Ticket is currently in a transfer pending state, please cancel the transfer and try again.",
    };
  }

  // Asserts if ticket is active
  if (ticket.status !== TicketStatus.Active) {
    return {
      status: "issue",
      message: "Ticket is not active.",
    };
  }

  // Asserts if order is not on hold 
  if (
    ticket.isHold
  ) {
    return {
      status: "issue",
      message: "Ticket is on hold. Order needs to be completed before scanning in",
    };
  }

  // Asserts if the ticket has been scanned in if the user is trying to scan it out
  if (allScans.length === 0 && scan.scanDirection === TicketScanDirection.ScanOut) {
    return {
      status: "issue",
      message: "Ticket needs to be scanned in first.",
    };
  }

  const firstScanIn = allScans.find(
    (scan) => getScanDirection(scan) === TicketScanDirection.ScanIn
  );
  const lastScan = allScans[allScans.length - 1];

  // Asserts if has scanned in already (unless last scan direction was out)
  if (
    scan.scanDirection === TicketScanDirection.ScanIn &&
    (getScanDirection(lastScan) === TicketScanDirection.ScanIn || getScanDirection(lastScan) === "scan-in")
  ) {
    return {
      status: "issue",
      message: `Ticket scanned in at (${moment(firstScanIn?.scannedAt).format(
        "hh:mm A"
      )})`,
    };
  }


  // Asserts if the ticket has its gates set
  if (ticket.gateIds.length === 0) {
    return {
      status: "issue",
      message: `Gate not set for ticket`,
    };
  }

  // Asserts if selected gate has been assigned to ticket type
  if (!ticket.gateIds.includes(scan.gateId)) {
    const gateNames = ticket.gateIds.map((gateId) => gates.find(gate => gate.id === gateId)?.name).filter(v => v);

    if (!gateNames.length) {
      const message = `Gate not found for ticket. Check venue setup.`;

      Sentry.captureException(new Error(message), {
        extra: {
          gateId: scan.gateId
        },
      });

      return {
        status: "wrongGate",
        message
      };
    }

    return {
      status: "wrongGate",
      message: `Incorrect gate, allowed gate(s): ${gateNames.join(", ")}`
    };
  }

  return {
    status: "success",
    message: ""
  }
}