import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import actions from "@state/actions";
import { scanStorage } from "@util/cache";

export default function* updatePendingScanCount(): SagaIterator {
 const keys: string[] = yield call(scanStorage!.keys);
  yield put(actions.updatePendingScanCount.success(keys.length))
}


