import { createSelector } from "reselect";

import { State } from "@state/types";

export const getState = (state: State) => state;

export const getBaseUrl = (state: State) => state.baseUrl;

export const getAuth = (state: State) => state.auth;

export const getEventList = (state: State) => state.events;

export const getEvent = (state: State) => state.selectedEvent;

export const getUser = (state: State) => state.user;

export const getOnline = (state: State) => state.online;

export const getMode = (state: State) => state.mode;

export const getSyncing = (state: State) => state.syncing;

export const getCameraPermission = (state: State) => state.cameraPermission;

export const getSuccessMessage = (state: State) => state.successMessage;

export const getSelectedEvent = (state: State) => state.selectedEvent;

export const getGates = (state: State) => state.selectedEvent?.venue?.gates;

export const getFetchingEvent = (state: State) => state.fetchingEvent;

export const getGateId = (state: State) => state.gateId;

export const getScanDirection = (state: State) => state.scanDirection;

export const getScannedTicket = (state: State) => state.scannedTicket;

export const getPendingScans = (state: State) => state.pendingScans;

export const getSearchDeviceTickets = (state: State) =>
  state.searchDeviceTickets;

export const getScannedTicketTicket = createSelector(
  getScannedTicket,
  (scannedTicket) => scannedTicket && scannedTicket.ticket
);

export const getScannedTicketErrorMsg = createSelector(
  getScannedTicket,
  (scannedTicket) => scannedTicket && scannedTicket.errorMsg
);

export const getScannedTicketStatus = createSelector(
  getScannedTicket,
  (scannedTicket) => scannedTicket && scannedTicket.status
);

export const getLoggedIn = createSelector(getState, (state) => state.loggedIn);

export const getRoute = createSelector(getState, (state) => state.route);

export const getEventId = createSelector(
  getSelectedEvent,
  (selectedEvent) => selectedEvent && selectedEvent.id
);


export const getEventName = createSelector(
  getSelectedEvent,
  (selectedEvent) => selectedEvent && selectedEvent.title
);

export const getRequestStatus = createSelector(
  getState,
  (state) => state.requestStatus
);

export const getError = createSelector(getState, (state) => state.error);

export const getSessionExpiry = createSelector(
  getAuth,
  (auth) => auth.sessionExpiry
);

export const getOrgId = createSelector(getAuth, (auth) => auth.orgId);
