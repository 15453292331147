import { Moment } from "moment";

import {
  AppTicket, FetchEventQuery, Ticket, TicketScanDirection, TicketScanType, User
} from "@graphql/sdk";
import { ListEvent } from "@util/api";
import { Routes, SCAN_STATUS } from "@util/types";

export interface State {
  cameraPermission: boolean;
  baseUrl: string;
  loggedIn: boolean;
  online: boolean;
  route: Routes;
  mode: Mode;
  successMessage?: string;
  requestStatus: RequestStatus;
  fetchingEvent: boolean;
  gateId?: string;
  scanDirection?: TicketScanDirection;
  auth: Auth;
  syncing: boolean;
  pendingScans: number;
  events?: ListEvent[] | undefined;
  user?: User | undefined;
  error?: string;
  selectedEvent?: Event | undefined;
  localTicketList?: any[] | undefined;
  localServer?: LocalServer;
  searchDeviceTickets?: DeviceTicket[];
  scannedTicket?: ScannedTicket;
}

export interface ScannedTicket {
  ticket?: DeviceTicket;
  errorMsg?: string;
  status: SCAN_STATUS;
}
export interface Auth {
  sessionExpiry: Moment | undefined;
  orgId?: string | undefined;
  orgName?: string | undefined;
}

export type Event = FetchEventQuery["event"];
export interface LocalServer {
  status?: RequestStatus;
  error?: string;
  url?: string;
  ipAddress?: string;
  port?: string;
}

export enum RequestStatus {
  Idle = "Idle",
  Pending = "Pending",
  Success = "Success",
  Failed = "Failed",
}

export type DeviceTicket = AppTicket & {
  event?: Ticket["event"]
}


export type DeviceTickets = Array<DeviceTicket>;

export type Mode = "online" | "local";

export type InitialScanPayload = { 
  ticketNumber: string;
  direction?: TicketScanDirection; 
};
export interface ScanPayload extends InitialScanPayload  { 
  scanType?: TicketScanType;
};

export type UpdateTicketPayload = { ticket: Partial<DeviceTicket>, ticketNumber: string; };
