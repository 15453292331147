import { assets } from "./constants"
import * as Sentry from "@sentry/gatsby";

const audio: HTMLAudioElement | undefined = typeof Audio !== "undefined" ? new Audio(assets.ding) : undefined;

const scanBarcode = () => {
  if (audio) {
    // Reset audio if already playing
    audio.pause();
    audio.currentTime = 0;

    audio.play().catch(error => {
      Sentry.captureException(error);
    });
  }
}

const play = {
  scanBarcode
}

export default play;

