import { checkForIOS } from "@util/helper";
import { ScanGuide, ScanGuideWrapper, VideoWrapper } from "@util/standard";
import { useReduxState } from "re-reduced";
import React, {MutableRefObject} from "react";
import Video from "./video";
import * as selectors from "@state/selectors";

interface QRCodeScannerProps {
  videoRef: MutableRefObject<HTMLVideoElement | undefined>;
  ready: boolean;
}

const QRCodeScanner = (props: QRCodeScannerProps) => {
  const isIOS = checkForIOS();
  const { cameraAllowed } = useReduxState({
    cameraAllowed: selectors.getCameraPermission,
  });
  return (
    <VideoWrapper ready={props.ready} cameraAllowed={cameraAllowed}>
      <Video ref={props.videoRef} ready={props.ready} crossOrigin={ isIOS ? "use-credentials" : undefined} />
      {isIOS && (
        <ScanGuideWrapper>
          <ScanGuide />
        </ScanGuideWrapper>
      )}

    </VideoWrapper>
  )
}

export default QRCodeScanner;