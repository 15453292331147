import { Action } from "re-reduced";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { SearchTicketsByEventAppQueryVariables } from "@graphql/sdk";
import actions from "@state/actions";
import { DeviceTicket } from "@state/types";
import { ticketStorage } from "@util/cache";
import { getErrorMessage } from "@lib/graphql-error";


const searchFunction = (query: string, ticket: DeviceTicket) => {
  return [
    ticket?.firstName,
    ticket?.lastName,
    ticket.buyerInformation?.firstName,
    ticket.buyerInformation?.lastName,
    ticket.buyerInformation?.email,
    ticket.orderNumber,
    ticket.ticketNumber,
    ticket.parentTicketNumber,
  ].some(item => item?.toLowerCase().includes(query))
}

export default function* searchDeviceTicket(
  action: Action<SearchTicketsByEventAppQueryVariables>
): SagaIterator {
  yield put(actions.searchDeviceTickets.request());
  const variables = action.payload;

  if (!variables.q) {
    yield put(
      actions.searchDeviceTickets.failure(
        "Need further detail to be able to search"
      )
    );
    return;
  }

  try {
    const query = variables.q!.toLocaleLowerCase();
    const results = yield call(ticketStorage!.search, query, searchFunction);
    yield put(actions.searchDeviceTickets.success(results.slice(0, 50)));

  } catch (error) {
    yield put(actions.searchDeviceTickets.failure(getErrorMessage(error)));
  }
}

