import { navigate } from "gatsby";
import moment from "moment";
import { Action } from "re-reduced";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { getErrorMessage } from "@lib/graphql-error";
import actions from "@state/actions";
import * as api from "@util/api";
import { pages } from "@util/constants";
import { LoginParams } from "@util/interfaces";
import { toast } from "react-toastify";


export default function* login(action: Action<LoginParams>): SagaIterator {
  yield put(actions.login.request());
  
  try {
    const { login: user } = yield call(api.login, action.payload);

    // TODO: Return organization not found on UAT. 
    // const { currentOrganization } = yield call(api.getCurrentOrganization, action.payload.orgId);
    
    const auth = {
      sessionExpiry: moment().add(3, "days"),
      orgId: action.payload.orgId,
      // TODO: replace with org name when above query is resolved
      orgName: action.payload.orgId
    };

    yield put(actions.updateAuth(auth));
    yield put(actions.login.success(user));

    navigate(pages.events);

    toast.success(user.fullName ? `Welcome ${user.fullName}!` : "Logged in!", {
      delay: 300,
      icon: false
    });

    yield put(actions.syncPendingScans());

  } catch (error) {
    const errorMsg = getErrorMessage(error);
    yield put(actions.login.failure(errorMsg.slice(0, errorMsg.indexOf(":"))));
  }
}
