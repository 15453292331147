import React, { forwardRef } from "react";
import styled from "styled-components";

const StyledVideo = styled.video<{ ready?: boolean, height: string }>`
  display: block;
  height: ${(props) => props.height};
  max-height: 100%;
  width: 100%;
  transition: opacity .4s; 
  opacity: ${(props) => props.ready ? 1 : 0};
  object-fit: ${(props) => props.ready ? "cover" : "none"};
`;

const padding = 0;
const aspectRatio = 0.8;

interface Props {
  ready?: boolean
  crossOrigin?: "use-credentials" | undefined;
}

const Video = forwardRef((props: Props, ref) => {
  if (typeof window == "undefined") {
    return null;
  }

  const height = ((window.innerWidth - padding) * aspectRatio) + "px"

  return (
    <StyledVideo
      autoPlay
      playsInline
      muted
      // @ts-ignore
      ref={ref}
      id="qr-video"
      data-testid="qr-video"
      height={height}
      ready={!!props.ready}
      {...(props.crossOrigin && { crossOrigin: props.crossOrigin})}
    />
  );
});

export default Video;
