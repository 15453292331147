import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";

import { OrderByDirection } from "@graphql/sdk";
import actions from "@state/actions";
import { getOrgId } from "@state/selectors";
import { ListEvent, listEvents } from "@util/api";
import moment from "moment";
import { getErrorMessage } from "@lib/graphql-error";


export default function* getEvents(): SagaIterator {
  const organizationId = yield select(getOrgId);
  yield put(actions.getEvents.request());
  try {

    const threeDaysAgo = moment().subtract(3, 'days').toISOString();
    const params = {
      where: {
        startDate: threeDaysAgo
      },
      orderBy: {
        startDate: OrderByDirection.Asc
      }
    }

    const events: ListEvent[] = yield call(listEvents, organizationId, params);
    yield put(actions.getEvents.success(events));
  } catch (error) {
    console.log(getErrorMessage(error))
    yield put(actions.getEvents.failure(getErrorMessage(error)));
  }
}


