import { Action } from "re-reduced";
import { SagaIterator } from "redux-saga";
import { put } from "redux-saga/effects";

import { TicketScanType } from "@graphql/sdk";
import actions from "@state/actions";
import { InitialScanPayload } from "@state/types";
import play from "@util/audio";


const parseTicketNumber = (ticketNumber: string) => {
  return {
    ticketNumber: ticketNumber.replace("-1", ""),
    scanType: ticketNumber.includes("-1") ? TicketScanType.Hardcopy : TicketScanType.Digital
  }
}
export default function* scanTicket(action: Action<InitialScanPayload>): SagaIterator {

  const { ticketNumber, direction } = action.payload;

  play.scanBarcode();

  yield put(actions.scanTicketOnline({ ...parseTicketNumber(ticketNumber), direction }));
}
