import { SCAN_STATUS } from "./types";

export const BUTTON_HEIGHT = 55;
export const HEADER_HEIGHT = 84;

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 80;
export const MOBILE_OFFSET = 60;

export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  flightBlue: "#001CA8",
  yellow: "#EEE8AA",
  red: "red",
  green: "#68D148",
  orange: "#E89252",
  dark: "#22201f",
  cyan: "#005858",
  purple: "#C56AFD",
  grey: "#3c3c3c",
  darkGrey: "#2a2929",
  lightGrey: "#8c8c8c",
};

export const PRIMARY_COLOR = colors.white;
export const SECONDARY_COLOR = colors.dark;

export const fontSizes = {
  p: { default: 18, mobile: 18 },
  h1: { default: 50, mobile: 34 },
  h2: { default: 28, mobile: 28 },
  h3: { default: 24, mobile: 20 },
  h6: { default: 18, mobile: 14 },
};

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.8"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
  green: (opacity?: number) => `rgba(0, 128, 0, ${opacity ?? "0.8"})`,
  red: (opacity?: number) => `rgba(255, 0, 0, ${opacity ?? "0.8"})`,
  orange: (opacity?: number) => `rgba(232, 146, 82, ${opacity ?? "0.8"})`,
  purple: (opacity?: number) => `rgba(176, 87, 224, ${opacity ?? "0.8"})`,
};

export const pages = {
  home: "/",
  login: "/login",
  settings: "/settings",
  search: "/search",
  events: "/events",
};

// define paths to asset
export const assets = {
  logo: require("../../static/logo.svg"),
  ding: require("../../static/tone.mp3"),
  close: require("../../static/icons/close.svg"),
  downArrow: require("../../static/icons/down-arrow.svg"),
  hamburger: require("../../static/icons/hamburger.svg"),
  leftArrow: require("../../static/icons/left-arrow.svg"),
  rightArrow: require("../../static/icons/right-arrow.svg"),
  search: require("../../static/icons/search.svg"),
  altLogo: require("../../static/icons/alt-logo.svg"),
  stadium: require("../../static/stadium.png"),
  qrIcon: require("../../static/qr-icon.png"),
  qrIcon1: require("../../static/icons/qr-code-1.svg"),
  qrIcon2: require("../../static/icons/qr-code-2.svg"),
  qrIcon3: require("../../static/icons/qr-code-3.svg"),
  camera: require("../../static/icons/camera.svg")
};

export const buttonStyle = {
  base: {
    bg: `${colors.white}`,
    text: `${colors.black}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.black}`,
    hoverText: `${colors.white}`,
    hoverBorder: `${colors.transparent}`,
  },
  inverse: {
    bg: `${colorsRGB.white()}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colorsRGB.orange(0.3)}`,
    hoverBorder: `${colors.orange}`,
    hoverText: `${colors.orange}`,
  },
  black: {
    bg: `${colors.black}`,
    text: `${colors.white}`,
    border: `${colors.black}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.black}`,
    hoverText: `${colors.white}`,
  },
};

// API URL and Links
export const BaseURL = process.env.GATSBY_API_URL;

export const loadingText = "Loading results...";
export const noResultText = "No results here, try another search query";

export const getStatusColor = (status: SCAN_STATUS) => {
  switch (status) {
    case "success":
      return "green"
    case "error":
      return "red"
    case "wrongGate":
      return "purple"
    case "issue":
      return "orange"
    default:
      return "black"
  }
}

export const AESSecretKey = process.env.GATSBY_PASSWORD_SECRET;
export const DEBUG_MODE = process.env.GATSBY_DEBUG;