import { applyMiddleware, compose, createStore } from "redux";
import { PersistedState, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

import reducers, { INITIAL_STATE } from "./reducers";
import sagas from "./sagas";

const pjson = require("../../package.json");

const persistConfig = {
  key: "state",
  version: pjson.version,
  storage,
  blacklist: ["baseUrl", "syncing", "online", "pendingScans", "requestStatus", "error", "deviceTickets", "cameraPermission", "searchDeviceTickets"],
  migrate: (state: (any & PersistedState) | undefined) => {
    if (state?._persist.version !== pjson.version) {
      console.log('Persist version changed to:', pjson.version)
      return Promise.resolve({
        ...INITIAL_STATE,
        _persist: state?._persist
      })
    }

    return Promise.resolve(state);
  }
};

const persistedReducer = persistReducer(persistConfig, reducers);
const sagaMiddleware = createSagaMiddleware();

const enhancers = compose(
  applyMiddleware(sagaMiddleware),
  typeof window !== "undefined" &&
    //@ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    process.env.GATSBY_NODE_ENV !== "production"
    ? //@ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f: any) => f
);
const store = createStore(persistedReducer, enhancers);

sagaMiddleware.run(sagas);

export const persistor = persistStore(store);
export default store;
