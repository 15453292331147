import { Action } from "re-reduced";
import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";

import { getErrorMessage } from "@lib/graphql-error";
import actions from "@state/actions";
import {
  getEventId, getGateId, getOrgId,
  getScanDirection
} from "@state/selectors";
import { DeviceTicket, ScanPayload } from "@state/types";
import * as api from "@util/api";
import { determineTicketStatus } from "@util/helper";

export default function* scanTicketOnline(action: Action<ScanPayload>): SagaIterator {
  yield put(actions.scanTicketOnline.request());
  
  const ticketNumber = action.payload.ticketNumber;
  const scanType = action.payload.scanType;
  const orgId = yield select(getOrgId);
  const scanDirectionState = yield select(getScanDirection);
  const scanDirection = action.payload.direction || scanDirectionState;
  const gateId = yield select(getGateId);
  const eventId = yield select(getEventId);

  const params = {
    scanDirection,
    gateId,
    eventId,
    orgId,
    scanType,
    ticketNumber,
  };

  try {
    const result = yield call(api.scanTicket, params);
    if (result) {

      const { setStatus, setTicket, errorMsg } = determineTicketStatus(result);

      // Transform returned ticket into DeviceTicket type
      // so we only have to deal with a single format
      const ticket: DeviceTicket = {
        ticketNumber,
        ...setTicket,
        buyerInformation: setTicket?.order?.buyerInformation,
        orderNumber: setTicket?.order?.orderNumber,
        ticketTypeName: setTicket?.ticketType?.name,
        isEventAddon: !!setTicket?.addon?.id && !setTicket?.ticketType,
        eventAddonName: setTicket?.addon?.name,
        gateIds: setTicket?.gates.map((gate: any) => gate.id),
        event: setTicket?.event,
      }

      const scannedTicket = { errorMsg, ticket, status: setStatus };
      yield put(actions.scanTicketOnline.success(scannedTicket));

      // Update local ticket with latest data
      yield put(actions.updateLocalTicket({ ticketNumber, ticket }));
    }
  } catch (error) {
    // If the network request fails, handle as we would offline;
    if ((error as Error)?.message.match(/network request failed/i)) {
      yield put(actions.scanDeviceTicket({ ticketNumber, scanType, direction: scanDirection }));
    } else {
      const errorMsg = getErrorMessage(error);
      yield put(actions.scanTicketOnline.failure(errorMsg));
    }
  }
}
