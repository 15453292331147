import { Action } from "re-reduced";
import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";

import { Query, SearchTicketsByEventAppQueryVariables } from "@graphql/sdk";
import actions from "@state/actions";
import { getOrgId } from "@state/selectors";
import { DeviceTicket } from "@state/types";
import * as api from "@util/api";
import { ticketStorage } from "@util/cache";
import { getErrorMessage } from "@lib/graphql-error";
import { toast } from "react-toastify";

export default function* fetchSearchTicketsByEventApp(
  action: Action<SearchTicketsByEventAppQueryVariables>
): SagaIterator {
  yield put(actions.fetchSearchTicketsByEventApp.request());
  const orgId = yield select(getOrgId);
  const variables = action.payload;
  const params = { orgId, variables };

  if (!variables.eventId) {
    yield put(
      actions.fetchSearchTicketsByEventApp.failure(
        "Please login first, before switching to Device Mode."
      )
    );
    return;
  }
  const toastId = toast.loading("Downloading tickets");

  try {
    const result: Query = yield call(api.searchTicketsByEventApp, params);
    const { tickets } = result.searchTicketsByEventApp;

    toast.update(toastId, { render: "Tickets downloaded", isLoading: false, delay: 400, type: "success", autoClose: 2000 });
    yield put(actions.fetchSearchTicketsByEventApp.success(tickets));

    const ticketEntries: [IDBValidKey, DeviceTicket][] = tickets.map((ticket) => ([ticket.ticketNumber, ticket]));


    if (variables.q) {
      // Queried update, add the returned tickets to the store
      ticketStorage?.setMany(ticketEntries);
    } else {
      // Fresh pull, clear current store and set new tickets
      ticketStorage?.clear().then(() => {
        ticketStorage?.setMany(ticketEntries);
      });
    }
  } catch (error) {
    toast.update(toastId, { render: "Error downloading tickets", type: "error", delay: 400, isLoading: false, autoClose: 3000 });
    yield put(actions.fetchSearchTicketsByEventApp.failure(getErrorMessage(error)));
  }
}
