import { SagaIterator } from "redux-saga";
import { put, call } from "redux-saga/effects";
import * as Sentry from "@sentry/gatsby";

import actions from "@state/actions";
import { scanStorage } from "@util/cache";


export default function* syncPendingScans(): SagaIterator {
  try {
    yield call(scanStorage!.clear);
    yield put(actions.updatePendingScanCount.success(0))
  } catch(error) {
    Sentry.captureException(error)
  }
}
