import * as Sentry from "@sentry/gatsby";
import { navigate } from "gatsby";
import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";

import actions from "@state/actions";
import { getOrgId } from "@state/selectors";
import * as api from "@util/api";
import { ticketStorage } from "@util/cache";
import { pages } from "@util/constants";

export default function* logout(): SagaIterator {
  const organizationId = yield select(getOrgId);
  yield put(actions.logout.request());
  try {
    const result = yield call(api.logout, organizationId);

    if (result.logout) {
      yield put(actions.logout.success(result));
    } else {
      Sentry.captureException(result);
      yield put(actions.logout.failure(result.message));
    }
  } catch (error) {
  } finally {
    Sentry.configureScope((scope) => scope.setUser(null));
    ticketStorage?.clear();
    yield put(actions.clearState());
    navigate(pages.login);
  }
}
