import React from "react";

import { Container, P } from "@util/standard";
import { Modal, Button } from "./sub";

interface Props {
  modalVisible: boolean;
  onClose: () => void;
  onYes: () => void;
  text: string;
}

const ConfirmModal = (props: Props) => {
  const { modalVisible, onClose, onYes, text } = props;

  return (
    <Modal height="40%" visible={modalVisible} onClose={onClose}>
      <Container flexDirection="column" padding={30}>
        <P>{text}</P>
        <Container margin="30px 0 0 0" justifyContent="space-between">
          <Button theme="base" text="No" onClick={onClose} />
          <Button theme="inverse" text="Yes" onClick={onYes} />
        </Container>
      </Container>
    </Modal>
  );
};

export default ConfirmModal;
