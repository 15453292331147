import React from "react";
import styled from "styled-components";
import Toggle from "react-toggle";
import "react-toggle/style.css";

import { Color, Dimensions } from "@util/types";
import { P } from "@util/standard";

const StyledToggle = styled(Toggle)<{
  baseColor?: Color;
  color?: Color;
  dimensions?: Dimensions;
}>``;

interface Props {
  color?: Color;
  checked?: boolean;
  onChange?: () => void;
  labels?: string[];
}

const ToggleComponent = (props: Props) => {
  return (
    <label>
      <StyledToggle
        icons={false}
        checked={props.checked}
        onChange={props.onChange}
      />
      {props.labels && (
        <P noMargin>{props.checked ? props.labels[0] : props.labels[1]}</P>
      )}
    </label>
  );
};

export default ToggleComponent;
