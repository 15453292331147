import { getLoggedIn, getOnline } from "./../selectors";
import { SagaIterator } from "redux-saga";
import { put, select } from "redux-saga/effects";
import { Action } from "re-reduced";

import { BaseURL } from "@util/constants";
import { ModeParams } from "@util/interfaces";
import actions from "@state/actions";

export default function* setMode(action: Action<ModeParams>): SagaIterator {
  const { mode, params, quietErrors } = action.payload;
  yield put(actions.setMode.request());

  if (mode === "online") {
    const online = yield select(getOnline);
    if (online) {
      yield put(actions.setSuccessMessage("Back online"));
      yield put(actions.setMode.success(mode));
      yield put(actions.setBaseUrl(BaseURL!));
    } else {
      yield put(actions.updateOnline(false));

      if (!!!quietErrors) {
        yield put(
          actions.setErrorMessage("Error going online. Try again later")
        );
      }

      yield put(
        actions.setMode.failure(
          "Error going online. Try again later"
        )
      );
    }
  }

  if (mode === "local" && params) {
    const loggedIn = yield select(getLoggedIn);
    if (loggedIn) {
      yield put(
        actions.setErrorMessage("Please logout before changing to local")
      );
      return;
    }

    const url = params.port
      ? `https://${params.ipAddress}:${params.port}/graphql/`
      : `https://${params.ipAddress}/graphql/`;

    yield put(actions.setSuccessMessage("Connected to local server"));
    yield put(actions.setMode.success(mode));
    yield put(actions.setBaseUrl(url));
  }
}
