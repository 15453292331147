import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Upload: any;
};

/** An individual access code */
export type AccessCodeInput = {
  /** The access code */
  accessCode: Scalars['String'];
  /** Whether this access code is enabled */
  isEnabled: Scalars['Boolean'];
  /** The number of tickets that can be purchased with this access code, set null for no limit */
  quantity?: Maybe<Scalars['Float']>;
  /** The number of free tickets for this access code, set null for no limit */
  quantityFree?: Maybe<Scalars['Float']>;
};

/** Portable representation of an access code. */
export type AccessCodeOutput = {
  __typename?: 'AccessCodeOutput';
  /** The access code */
  accessCode: Scalars['String'];
  /** Is enabled */
  isEnabled: Scalars['Boolean'];
  /** The link to the access code */
  link: Scalars['String'];
  /** The number of tickets that can be purchased with this access code */
  quantity?: Maybe<Scalars['Float']>;
  /** The number of free tickets that can been redeemed with this access code */
  quantityFree?: Maybe<Scalars['Float']>;
  /** the number of free tickets that have been redeemed with this access code */
  quantityFreeUsed: Scalars['Float'];
  /** The number of tickets that have been purchased with this access code */
  quantityUsed: Scalars['Float'];
};

/** How you can create or update an access code. */
export type AccessCodeReleaseInput = {
  /** The access codes for the release */
  accessCodes: Array<AccessCodeInput>;
  /** The event id for the access code */
  eventId: Scalars['String'];
  /** The release id for the access code, set null to create new */
  releaseId?: Maybe<Scalars['String']>;
  /** The tickets that can be purchased with this access code */
  tickets: Array<AccessCodeTicketInput>;
};

/** Portable representation of an access code release. */
export type AccessCodeReleaseOutput = {
  __typename?: 'AccessCodeReleaseOutput';
  /** The access codes for the release */
  accessCodes: Array<AccessCodeOutput>;
  /** The event id for the access code */
  eventId: Scalars['String'];
  /** The release id for the access code */
  releaseId: Scalars['String'];
  /** The tickets that can be purchased with this access code */
  tickets: Array<AccessCodeTicketOutput>;
};

/** Access code ticket input for the event. */
export type AccessCodeTicketInput = {
  /** The ticket id */
  id: Scalars['String'];
  /** The type of ticket */
  type: TicketTypeKind;
};

/** Access code ticket output for the event. */
export type AccessCodeTicketOutput = {
  __typename?: 'AccessCodeTicketOutput';
  /** The ticket id */
  id: Scalars['String'];
  /** If ticket is hidden by the release */
  isHidden: Scalars['Boolean'];
  /** The ticket name */
  name: Scalars['String'];
  /** The ticket price */
  price?: Maybe<Scalars['Float']>;
  /** The ticket quantity */
  quantity?: Maybe<Scalars['Float']>;
  /** The type of ticket */
  type: TicketTypeKind;
  /** The ticket zone name */
  zoneName?: Maybe<Scalars['String']>;
};

export type ActionEngagementRecordOutput = {
  __typename?: 'ActionEngagementRecordOutput';
  clickCount: Scalars['Float'];
  label: Scalars['String'];
};

export type ActiveCompetitionByTypeInput = {
  competitionType: CompetitionType;
  eventId: Scalars['String'];
};

export type Activity = {
  __typename?: 'Activity';
  /** The admin that make this activity */
  admin?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  /** human readable description of what has gone */
  description?: Maybe<Scalars['String']>;
  /** Detail is relevant to the action that cant be captured just by the refId and refType */
  detail: Scalars['JSON'];
  fingerPrint?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  /** Origin of activities */
  origin: ActivityOrigin;
  /** Target entity id */
  refId?: Maybe<Scalars['String']>;
  /** Target entity */
  refName?: Maybe<Scalars['String']>;
  /** order:creation */
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** The user that an activity belongs to */
  user: User;
};

export type ActivityConnection = {
  __typename?: 'ActivityConnection';
  edges: Array<ActivityEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  cursor: Scalars['String'];
  node: Activity;
};

/** Origin of activities */
export enum ActivityOrigin {
  Admin = 'Admin',
  System = 'System',
  User = 'User'
}

export type ActivityWhereInput = {
  userId: Scalars['String'];
};

export type AddGatesToEventAddonInput = {
  addonId: Scalars['String'];
  names: Array<Scalars['String']>;
};

export type AddGatesToZoneInput = {
  names: Array<Scalars['String']>;
  zoneId: Scalars['String'];
};

export type AddPosTransactionInput = {
  amount: Scalars['Float'];
  /** Selected payment method (allowed: cash, eftpos, cc). */
  method: PaymentProvider;
  returned?: Maybe<Scalars['Float']>;
};

export type AddReminderToScoutCommunicationUserInput = {
  message?: Maybe<Scalars['String']>;
  remindAt: Scalars['DateTime'];
};

export type AddUnsubscribeReasonInput = {
  otherUnsubscribeReason?: Maybe<Scalars['String']>;
  unsubscribeReason: UnsubscribeReason;
  userId: Scalars['String'];
};

/** Represents additional info to be included with digital event or membership emails. */
export type AdditionalEmailInfoInput = {
  /** The text that will be displayed on the action button. Required if buttonUrl is provided. */
  buttonText?: Maybe<Scalars['String']>;
  /** The url that the action button will link to. Required if buttonText is provided. */
  buttonUrl?: Maybe<Scalars['String']>;
  /** Additional info to be included with digital event or membership emails. */
  text?: Maybe<Scalars['String']>;
};

export type AddonUnion = EventAddon | MembershipAddon;

/** Address details. */
export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  formattedAddress?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
};

/** Address details. */
export type AddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  formattedAddress?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
};

export type AdobeExperiencePlatformIntegration = {
  __typename?: 'AdobeExperiencePlatformIntegration';
  /** Also known as the API key */
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customerDatasetId?: Maybe<Scalars['String']>;
  customerEndpoint?: Maybe<Scalars['String']>;
  customerSchemaRefId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imsOrgId: Scalars['String'];
  privateKeyBase64: Scalars['String'];
  sandboxName: Scalars['String'];
  scanDatasetId?: Maybe<Scalars['String']>;
  scanEndpoint?: Maybe<Scalars['String']>;
  scanSchemaRefId?: Maybe<Scalars['String']>;
  technicalAccountId: Scalars['String'];
  tenant: Scalars['String'];
  ticketDatasetId?: Maybe<Scalars['String']>;
  ticketEndpoint?: Maybe<Scalars['String']>;
  ticketSchemaRefId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type AdobeExperiencePlatformIntegrationInput = {
  /** Also known as the API key */
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  customerDatasetId?: Maybe<Scalars['String']>;
  customerEndpoint?: Maybe<Scalars['String']>;
  customerSchemaRefId?: Maybe<Scalars['String']>;
  imsOrgId: Scalars['String'];
  privateKeyBase64: Scalars['String'];
  sandboxName: Scalars['String'];
  scanDatasetId?: Maybe<Scalars['String']>;
  scanEndpoint?: Maybe<Scalars['String']>;
  scanSchemaRefId?: Maybe<Scalars['String']>;
  technicalAccountId: Scalars['String'];
  tenant: Scalars['String'];
  ticketDatasetId?: Maybe<Scalars['String']>;
  ticketEndpoint?: Maybe<Scalars['String']>;
  ticketSchemaRefId?: Maybe<Scalars['String']>;
};

export type AgeBreakDownOutput = {
  __typename?: 'AgeBreakDownOutput';
  seg1: Scalars['Float'];
  seg2: Scalars['Float'];
  seg3: Scalars['Float'];
  seg4: Scalars['Float'];
  seg5: Scalars['Float'];
  seg6: Scalars['Float'];
  seg7: Scalars['Float'];
};

export type AppTicket = {
  __typename?: 'AppTicket';
  buyerInformation?: Maybe<AppTicketUserDetails>;
  email?: Maybe<Scalars['String']>;
  eventAddonName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gateIds: Array<Scalars['String']>;
  id: Scalars['String'];
  isEventAddon: Scalars['Boolean'];
  isHold: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  parentTicketNumber?: Maybe<Scalars['String']>;
  scans: Array<AppTicketScan>;
  seatZone?: Maybe<Scalars['String']>;
  status: TicketStatus;
  ticketNumber: Scalars['String'];
  ticketTypeName?: Maybe<Scalars['String']>;
  user?: Maybe<AppTicketUserDetails>;
};

export type AppTicketScan = {
  __typename?: 'AppTicketScan';
  direction: Scalars['String'];
  id: Scalars['String'];
  scannedAt: Scalars['DateTime'];
  type: Scalars['String'];
};

export type AppTicketUserDetails = {
  __typename?: 'AppTicketUserDetails';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type ApplyPromoCode = {
  promoCode: Scalars['String'];
};

export type AssignScoutCommunicationUsersToAgentInput = {
  agentUserId: Scalars['String'];
  scoutCommunicationId: Scalars['String'];
  scoutCommunicationUserIds: Array<Scalars['String']>;
};

export type AudienceCountInput = {
  addonId?: Maybe<Array<Scalars['String']>>;
  audience?: Maybe<BroadcastAudience>;
  eventId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  releaseId?: Maybe<Array<Scalars['String']>>;
  ticketTypeId?: Maybe<Array<Scalars['String']>>;
  transactionalType: BroadcastTransactionalType;
  zoneId?: Maybe<Array<Scalars['String']>>;
};

/** Id of the broadcast audience. One broadcast only has one id. */
export type AudienceData = {
  __typename?: 'AudienceData';
  addonId?: Maybe<Array<Scalars['String']>>;
  releaseId?: Maybe<Array<Scalars['String']>>;
  ticketTypeId?: Maybe<Array<Scalars['String']>>;
  zoneId?: Maybe<Array<Scalars['String']>>;
};

/** Id of the broadcast audience. One broadcast only has one id. */
export type AudienceDataInput = {
  addonId?: Maybe<Array<Scalars['String']>>;
  releaseId?: Maybe<Array<Scalars['String']>>;
  ticketTypeId?: Maybe<Array<Scalars['String']>>;
  zoneId?: Maybe<Array<Scalars['String']>>;
};

export type Automation = {
  __typename?: 'Automation';
  /** When to send the email */
  automationType: AutomationType;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Active status. */
  isActive: Scalars['Boolean'];
  /** Id of Mailchimp audience */
  listId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Subject of email */
  subject?: Maybe<Scalars['String']>;
  /** Release Id of the source */
  targetReleaseId?: Maybe<Scalars['String']>;
  /** Used to show what we are targeting */
  targetSource: ReportingFilterSource;
  /** Id of source */
  targetSourceId?: Maybe<Scalars['String']>;
  /** Id of Mailchimp template */
  templateId?: Maybe<Scalars['Float']>;
  /** Postmark template name */
  templateName?: Maybe<Scalars['String']>;
  /** send time for a birthday email, only hour and minute are used */
  timeOfDay?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export enum AutomationType {
  Birthday = 'birthday',
  OrderAbandoned = 'orderAbandoned'
}

export type BarchartOutput = {
  __typename?: 'BarchartOutput';
  range: Scalars['String'];
  value: Scalars['Float'];
};

export type BrandingInput = {
  /** Organization background image. */
  background?: Maybe<Scalars['Upload']>;
  /** Organization favicon. */
  favicon?: Maybe<Scalars['Upload']>;
  /** Organization background image. */
  fullBackground?: Maybe<Scalars['Upload']>;
  /** Grey scale option. */
  greyScale?: Maybe<GreyScale>;
  /** Organization logo. */
  logo?: Maybe<Scalars['Upload']>;
  /** Organization mobile logo. */
  mobileLogo?: Maybe<Scalars['Upload']>;
  /** Primary color. */
  primaryColor: Scalars['String'];
  /** Secondary color. */
  secondaryColor: Scalars['String'];
};

export type BrandingOutputBase = {
  __typename?: 'BrandingOutputBase';
  /** Organization background image. */
  background?: Maybe<ExtendedFile>;
  /** Organization favicon. */
  favicon?: Maybe<ExtendedFile>;
  /** Organization full background image. */
  fullBackground?: Maybe<ExtendedFile>;
  /** Grey scale option. */
  greyScale?: Maybe<GreyScale>;
  /** Organization logo. */
  logo?: Maybe<ExtendedFile>;
  /** Organization mobile logo. */
  mobileLogo?: Maybe<ExtendedFile>;
  /** Primary color. */
  primaryColor: Scalars['String'];
  /** Secondary color. */
  secondaryColor: Scalars['String'];
};

export type Broadcast = {
  __typename?: 'Broadcast';
  audienceData: AudienceData;
  /** When to send the email */
  audienceType?: Maybe<BroadcastAudience>;
  count?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  /** Array an object with the error responses from Postmark */
  errors?: Maybe<Scalars['JSON']>;
  event?: Maybe<Event>;
  fromName: Scalars['String'];
  id: Scalars['ID'];
  marketingFilters?: Maybe<Array<BroadcastMarketingFilters>>;
  marketingType?: Maybe<BroadcastMarketingType>;
  membership?: Maybe<Membership>;
  /** The rich text object */
  message: Scalars['String'];
  scheduledAt: Scalars['DateTime'];
  /** The Broadcast email delivery stats from postmark */
  stats?: Maybe<PostmarkEmailStatsOverviewOutput>;
  status: BroadcastStatus;
  subject: Scalars['String'];
  transactionalType?: Maybe<BroadcastTransactionalType>;
  type: BroadcastType;
  updatedAt: Scalars['DateTime'];
};

/** Type of broadcast audience */
export enum BroadcastAudience {
  Addon = 'ADDON',
  AutomaticMembershipRenewal = 'AUTOMATIC_MEMBERSHIP_RENEWAL',
  ManualMembershipRenewal = 'MANUAL_MEMBERSHIP_RENEWAL',
  MembershipBuyers = 'MEMBERSHIP_BUYERS',
  RegistrationWaitlistCustomers = 'REGISTRATION_WAITLIST_CUSTOMERS',
  Release = 'RELEASE',
  ResaleWaitlistCustomers = 'RESALE_WAITLIST_CUSTOMERS',
  TicketBuyers = 'TICKET_BUYERS',
  TicketType = 'TICKET_TYPE',
  Zone = 'ZONE'
}

export type BroadcastConnection = {
  __typename?: 'BroadcastConnection';
  edges: Array<BroadcastEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type BroadcastEdge = {
  __typename?: 'BroadcastEdge';
  cursor: Scalars['String'];
  node: Broadcast;
};

export type BroadcastFilterCountInput = {
  filters: Array<MarketingFiltersInput>;
};

export enum BroadcastFilterCriteria {
  Is = 'is',
  Not = 'not'
}

export enum BroadcastFilterType {
  Region = 'region',
  Tag = 'tag',
  TicketBuyer = 'ticketBuyer',
  Waitlist = 'waitlist'
}

export type BroadcastMarketingFilters = {
  __typename?: 'BroadcastMarketingFilters';
  criteria?: Maybe<BroadcastFilterCriteria>;
  filterType: BroadcastFilterType;
  ids: Array<Scalars['String']>;
};

export enum BroadcastMarketingType {
  Custom = 'custom',
  OptIn = 'optIn'
}

export type BroadcastReportingOpenInput = {
  broadcastId: Scalars['String'];
  eventId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  waitlistUserId?: Maybe<Scalars['String']>;
};

export type BroadcastReportingOrderInput = {
  broadcastId: Scalars['String'];
  eventId: Scalars['String'];
  orderId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  waitlistUserId?: Maybe<Scalars['String']>;
};

export type BroadcastReportingOutput = {
  __typename?: 'BroadcastReportingOutput';
  clicksOpened: Scalars['Float'];
  conversions: Scalars['Float'];
  revenue: Scalars['Float'];
  spamReported: Scalars['Float'];
};

export type BroadcastReportingSpamInput = {
  broadcastId: Scalars['String'];
  eventId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  waitlistUserId?: Maybe<Scalars['String']>;
};

export type BroadcastReportingStatsInput = {
  broadcastId: Scalars['String'];
  type: BroadcastType;
};

export enum BroadcastStatus {
  Draft = 'DRAFT',
  Scheduled = 'SCHEDULED',
  Sent = 'SENT',
  Test = 'TEST'
}

export enum BroadcastTransactionalType {
  Event = 'Event',
  Membership = 'Membership'
}

export enum BroadcastType {
  Marketing = 'marketing',
  Transactional = 'transactional'
}

export type BroadcastWhereInput = {
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
  status?: Maybe<BroadcastStatus>;
};

/** Bulk orders item type */
export type BulkOrdersItem = {
  __typename?: 'BulkOrdersItem';
  createdAt: Scalars['DateTime'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  /** bulk orders item status. */
  status?: Maybe<BulkOrdersItemStatus>;
  updatedAt: Scalars['DateTime'];
};

/** Bulk order items status */
export enum BulkOrdersItemStatus {
  Failed = 'Failed',
  Success = 'Success'
}

/** Buyer information. */
export type BuyerInformation = {
  __typename?: 'BuyerInformation';
  city?: Maybe<Scalars['String']>;
  /** Company name provided by the customer. */
  companyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** Postal code provided by the customer. Returns `null` if `UpdateOrderInput.deliveryMethod` is set to other than `eTicket`. */
  postalCode?: Maybe<Scalars['String']>;
  /** Tax number provided by the customer. */
  taxNumber?: Maybe<Scalars['String']>;
};

/** Buyer information. */
export type BuyerInformationInput = {
  city?: Maybe<Scalars['String']>;
  /** Company name provided by the customer. */
  companyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  /** Postal code provided by the customer. Pass `null` if `UpdateOrderInput.deliveryMethod` is set to other than `eTicket`. */
  postalCode?: Maybe<Scalars['String']>;
  /** Tax Number provided by the customer. */
  taxNumber?: Maybe<Scalars['String']>;
};

export type CapacityOverview = {
  __typename?: 'CapacityOverview';
  capacity: Scalars['Float'];
  /** The zone id */
  id: Scalars['String'];
  sellableCapacity: Scalars['Float'];
  totalAllocated: Scalars['Float'];
  totalCompTicketCount: Scalars['Float'];
  totalHoldByMembershipCount: Scalars['Float'];
  totalHolds: Scalars['Float'];
  totalMembershipTicketCount: Scalars['Float'];
  totalNotForSaleCount: Scalars['Float'];
  totalPaidTicketCount: Scalars['Float'];
  zoneName: Scalars['String'];
};

export type ClaimTicketOutput = {
  __typename?: 'ClaimTicketOutput';
  eventId: Scalars['String'];
  parentTicketId: Scalars['String'];
  result: ClaimTicketResult;
};

export enum ClaimTicketResult {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export type Competition = {
  __typename?: 'Competition';
  bannerImage?: Maybe<ExtendedFile>;
  competitionState: CompetitionState;
  competitionType: CompetitionType;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isCompetitionRunning: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  prizes?: Maybe<Array<CompetitionPrize>>;
  promoter?: Maybe<Array<CompetitionReward>>;
  referralRewardValue?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type CompetitionCustomerReporting = {
  __typename?: 'CompetitionCustomerReporting';
  competitionBannerImage?: Maybe<ExtendedFile>;
  competitionEndDate: Scalars['DateTime'];
  competitionEventLogo?: Maybe<ExtendedFile>;
  competitionEventTitle: Scalars['String'];
  competitionId: Scalars['String'];
  competitionReferralLink?: Maybe<Scalars['String']>;
  competitionSharePageLink?: Maybe<Scalars['String']>;
  competitionTitle: Scalars['String'];
  entries: Scalars['Int'];
  eventId: Scalars['String'];
  isCompetitionRunning: Scalars['Boolean'];
  referralsMade: Scalars['Int'];
};

export type CompetitionOnsaleOutput = {
  __typename?: 'CompetitionOnsaleOutput';
  conversionRate: Scalars['Float'];
  hasSalesOutsideOfCompetition: Scalars['Boolean'];
  newCustomers: Scalars['Float'];
  pageViews: Scalars['Float'];
  revenue: Scalars['Float'];
  ticketsSold: Scalars['Float'];
  totalEntries: Scalars['Float'];
  totalOrders: Scalars['Float'];
};

export type CompetitionPrize = {
  __typename?: 'CompetitionPrize';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<ExtendedFile>;
  prizeOrder: Scalars['Float'];
  quantity: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type CompetitionPrizeInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  prizeOrder?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type CompetitionPromoterInput = {
  actionType: Scalars['String'];
  enabled: Scalars['Boolean'];
  platform: Scalars['String'];
  value: Scalars['Int'];
};

export type CompetitionRegistrationOutput = {
  __typename?: 'CompetitionRegistrationOutput';
  conversionRate: Scalars['Float'];
  /** Total page views for the event details page. */
  eventDetailsPageViews: Scalars['Float'];
  eventPageConversionRate: Scalars['Float'];
  newCustomers: Scalars['Float'];
  pageViews: Scalars['Float'];
  registrationPageConversionRate: Scalars['Float'];
  /** Total page views for the registration sign up form page. */
  registrationPageViews: Scalars['Float'];
  totalEntries: Scalars['Float'];
  totalRegistrations: Scalars['Float'];
};

export type CompetitionReportingInput = {
  competitionId: Scalars['String'];
  eventId: Scalars['String'];
};

export type CompetitionReportingOnsaleOutput = {
  __typename?: 'CompetitionReportingOnsaleOutput';
  actionEngagement?: Maybe<Array<ActionEngagementRecordOutput>>;
  competition: CompetitionOnsaleOutput;
  promoter: PromoterOnsaleOutput;
};

export type CompetitionReportingRegistrationOutput = {
  __typename?: 'CompetitionReportingRegistrationOutput';
  actionEngagement?: Maybe<Array<ActionEngagementRecordOutput>>;
  competition: CompetitionRegistrationOutput;
  promoter: PromoterRegistrationOutput;
};

export type CompetitionReward = {
  __typename?: 'CompetitionReward';
  actionType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
  platform: Scalars['String'];
  socialLinkType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export enum CompetitionState {
  Ended = 'ENDED',
  Running = 'RUNNING',
  Upcoming = 'UPCOMING'
}

/** Competition type. */
export enum CompetitionType {
  Onsale = 'ONSALE',
  Registration = 'REGISTRATION'
}

export type Coordinate = {
  __typename?: 'Coordinate';
  /** Latitude */
  lat: Scalars['Float'];
  /** Longitude */
  lng: Scalars['Float'];
};

export type CoordinateInput = {
  /** Latitude */
  lat: Scalars['Float'];
  /** Longitude */
  lng: Scalars['Float'];
};

export type CreateAutomationInput = {
  automationType: AutomationType;
  listId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  targetReleaseId?: Maybe<Scalars['String']>;
  targetSource: ReportingFilterSource;
  targetSourceId?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Float']>;
  templateName?: Maybe<Scalars['String']>;
  timeOfDay?: Maybe<Scalars['DateTime']>;
};

export type CreateCompetitionInput = {
  bannerImage?: Maybe<Scalars['Upload']>;
  competitionType: CompetitionType;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  prizes?: Maybe<Array<CompetitionPrizeInput>>;
  promoter?: Maybe<Array<CompetitionPromoterInput>>;
  referralRewardValue?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Represents additional info required to host a digital event. */
export type CreateDigitalInfoInput = {
  additionalEmailInfo?: Maybe<AdditionalEmailInfoInput>;
  /** The Postmark email template that is used to send digital pass codes. */
  emailTemplateId?: Maybe<Scalars['String']>;
  /** The url that digital pass codes are appended to in order to access the digital event/membership. */
  url: Scalars['String'];
};

export type CreateEventAddonInput = {
  /** Image banner specific for a ticket type on the print at home ticket */
  addonImage?: Maybe<Scalars['Upload']>;
  /** The brief description of the addon. */
  description?: Maybe<Scalars['String']>;
  /** Indicates whether naming the addon is possible or not. */
  enableNaming: Scalars['Boolean'];
  /** The event id that is associated with the addon. */
  eventId: Scalars['String'];
  /** Whether hide QR code on ticket. */
  hideQRCode?: Maybe<Scalars['Boolean']>;
  insideFee?: Maybe<Scalars['Float']>;
  /** The active status of the addon. */
  isActive: Scalars['Boolean'];
  /** The name of the addon. */
  name: Scalars['String'];
  namingFields: Array<FormFieldInput>;
  /** The price associated with the addon. */
  price: Scalars['Float'];
  /** Maximum quantity to be sold for the addon. */
  quantity: Scalars['Float'];
  /** The restrictions applied to the addon. */
  restrictions?: Maybe<Scalars['String']>;
};

export type CreateEventDate = {
  endDate: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
};

/** Creates a new event. */
export type CreateEventInput = {
  accessibility?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['Upload']>;
  backgroundImageFullPage?: Maybe<Scalars['Upload']>;
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** The fee the buyer pays for transferring a ticket. */
  buyerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** Whether changing seats is available. */
  canChangeSeats?: Maybe<Scalars['Boolean']>;
  /** Whether naming ticket is available. */
  canNameTicket?: Maybe<Scalars['Boolean']>;
  /** Whether renaming ticket is available. */
  canRenameTicket?: Maybe<Scalars['Boolean']>;
  /** Whether transfering ticket is available. */
  canTransferTicket?: Maybe<Scalars['Boolean']>;
  canUsePoints?: Maybe<Scalars['Boolean']>;
  /** The fee for changing seats for the event ticket. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** Whether event is checking for vaccine passports on entry */
  checkForVaccinePass?: Maybe<Scalars['Boolean']>;
  /** Event dates. */
  dates: Array<CreateEventDate>;
  /** Event description. */
  description?: Maybe<Scalars['String']>;
  descriptionImage?: Maybe<Scalars['Upload']>;
  /** Represents additional info required to host a digital event. */
  digitalInfo?: Maybe<CreateDigitalInfoInput>;
  emailNotification?: Maybe<Scalars['String']>;
  /** Whether release codes are visible on the event information page */
  enableReleaseCodes?: Maybe<Scalars['Boolean']>;
  /** Whether the waitlist is visible on the event information page */
  enableWaitlist?: Maybe<Scalars['Boolean']>;
  /** The kind of event. */
  eventKind?: Maybe<EventKind>;
  gaVenue?: Maybe<CreateGaEventVenueInput>;
  /** The payment gateways enabled for the event. */
  gateways?: Maybe<Array<Scalars['String']>>;
  hardCopyTicketBanner?: Maybe<Scalars['Upload']>;
  importantNotice?: Maybe<Scalars['String']>;
  /** Inside fees for the event */
  insideFeeDetails?: Maybe<Array<InsideFeeDetailsInput>>;
  invoiceInformation?: Maybe<Scalars['String']>;
  /** Event active status. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Event featured status. */
  isFeatured?: Maybe<Scalars['Boolean']>;
  /** Whether event is R-18. */
  isR18?: Maybe<Scalars['Boolean']>;
  listedReleaseOptions?: Maybe<ListedReleaseOptionsInput>;
  logo?: Maybe<Scalars['Upload']>;
  merchantReference?: Maybe<Scalars['String']>;
  pixelId?: Maybe<Scalars['String']>;
  presaleInformation?: Maybe<Scalars['String']>;
  printBanner1?: Maybe<Scalars['Upload']>;
  printBanner2?: Maybe<Scalars['Upload']>;
  redirectURL?: Maybe<Scalars['String']>;
  /** The renaming fee for the event ticket. */
  renamingFee?: Maybe<Scalars['Float']>;
  /** Event season. */
  season?: Maybe<Scalars['String']>;
  /** The fee the seller pays for transferring a ticket. */
  sellerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** Shipping options. */
  shippingOptions?: Maybe<ShippingOptionsInput>;
  tags?: Maybe<Array<Scalars['String']>>;
  termsAndConditions?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  ticketCover?: Maybe<TicketCoverInput>;
  /** Date at which tickets are viewable/printable from. */
  ticketsViewableFromDate?: Maybe<Scalars['String']>;
  /** Event title. */
  title: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  /** The end date of the presale registration. */
  waitlistEndDate?: Maybe<Scalars['DateTime']>;
  /** The start date of the presale registration. */
  waitlistStartDate?: Maybe<Scalars['DateTime']>;
  zonesAllocatedGates?: Maybe<Array<ZoneAllocatedGateInput>>;
};

export type CreateEventMultiBuyPromotionInput = {
  buyQuantity: Scalars['Float'];
  buyTicketTypeId: Scalars['String'];
  /** The event id that is associated with the promotion. */
  eventId: Scalars['String'];
  getQuantity: Scalars['Float'];
  getTicketTypeId: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
};

export type CreateEventPromoCodeInput = {
  /** The unique code that represents the promotion. */
  code: Scalars['String'];
  /** The discount amount of the promotion. */
  discountAmount: Scalars['Float'];
  /** The discount type associated with the promotion. */
  discountType: DiscountType;
  /** The end date condition of the promotion. Returns `null` when inactive. */
  endDate?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['String'];
  /** The maximum order amount condition of the promotion. Returns `null` when inactive. */
  maxOrderAmount?: Maybe<Scalars['Float']>;
  /** The maximum amount of use condition of the promotion. Returns `null` when inactive. */
  maxUsage?: Maybe<Scalars['Float']>;
  /** The minimum order amount condition of the promotion. Returns `null` when inactive. */
  minOrderAmount?: Maybe<Scalars['Float']>;
  /** The start date condition of the promotion. Returns `null` when inactive. */
  startDate?: Maybe<Scalars['DateTime']>;
  ticketTypes?: Maybe<Array<Scalars['String']>>;
  type: EventPromoCodeType;
};

export type CreateGaEventVenueInput = {
  address?: Maybe<AddressInput>;
  location?: Maybe<CoordinateInput>;
  name?: Maybe<Scalars['String']>;
};

export type CreateHistoryInput = {
  /** The description of the history item. */
  description: Scalars['String'];
  /** The reference id of the history item. */
  refId: Scalars['String'];
  /** The reference name of the history item. */
  refName: HistoryReferenceType;
};

export type CreateIntegrationInput = {
  bnplEnabled?: Maybe<Scalars['Boolean']>;
  bnplTransactionFeePercent?: Maybe<Scalars['Float']>;
  facebookPageId?: Maybe<Scalars['String']>;
  facebookTempToken?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentMethodType?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  secretKey?: Maybe<Scalars['String']>;
  transactionFee?: Maybe<Scalars['Float']>;
  transactionFee2?: Maybe<Scalars['Float']>;
  transactionFeeType?: Maybe<FeeType>;
  transactionFeeType2?: Maybe<FeeType>;
  type: IntegrationType;
  wcPxPostPublicKey?: Maybe<Scalars['String']>;
  wcPxPostSecretKey?: Maybe<Scalars['String']>;
  webhookUrl?: Maybe<Scalars['String']>;
};

export type CreateMembershipAddonInput = {
  /** Image banner specific for a ticket type on the print at home ticket */
  addonImage?: Maybe<Scalars['Upload']>;
  /** The brief description of the addon. */
  description?: Maybe<Scalars['String']>;
  /** Indicates whether naming the addon is possible or not. */
  enableNaming: Scalars['Boolean'];
  /** The active status of the addon. */
  isActive: Scalars['Boolean'];
  /** The membership id that is associated with the addon. */
  membershipId: Scalars['String'];
  /** The name of the addon. */
  name: Scalars['String'];
  namingFields: Array<FormFieldInput>;
  /** The price associated with the addon. */
  price: Scalars['Float'];
  /** Maximum quantity to be sold for the addon. */
  quantity: Scalars['Float'];
  /** The restrictions applied to the addon. */
  restrictions?: Maybe<Scalars['String']>;
};

export type CreateMembershipInput = {
  accessibility?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['Upload']>;
  banner?: Maybe<Scalars['Upload']>;
  /** The booking fee for the membership. */
  bookingFee: Scalars['Float'];
  /** The booking fee type of the membership. */
  bookingFeeType: FeeType;
  /** Whether naming a membership is available. */
  canChangeSeats?: Maybe<Scalars['Boolean']>;
  /** Whether naming a membership is available. */
  canNameMembership?: Maybe<Scalars['Boolean']>;
  /** Whether renaming a membership is available. */
  canRenameMembership?: Maybe<Scalars['Boolean']>;
  /** Whether individual event tickets can be transferred. */
  canTransferTickets?: Maybe<Scalars['Boolean']>;
  canUsePoints?: Maybe<Scalars['Boolean']>;
  /** The fee for changing seats for the membership. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** The description of the membership. */
  description: Scalars['String'];
  descriptionImage?: Maybe<Scalars['Upload']>;
  /** Represents additional info required to host a digital membership. */
  digitalInfo?: Maybe<CreateDigitalInfoInput>;
  emailNotification?: Maybe<Scalars['String']>;
  enableAutoRenew?: Maybe<Scalars['Boolean']>;
  /** The end date of the membership. */
  endDate: Scalars['DateTime'];
  /** The payment gateways enabled for the membership. */
  gateways?: Maybe<Array<Scalars['String']>>;
  /** Flag to determine if the membership is hidden from the public */
  hiddenFromPublic?: Maybe<Scalars['Boolean']>;
  importantNotice?: Maybe<Scalars['String']>;
  invoiceInformation?: Maybe<Scalars['String']>;
  /** The active status of the membership. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The kind of membership. */
  membershipKind: MembershipKind;
  /** Name of the membership. */
  name: Scalars['String'];
  /** The previous memberships related to the membership. */
  previousMemberships?: Maybe<Array<Scalars['String']>>;
  printBanner1?: Maybe<Scalars['Upload']>;
  printBanner2?: Maybe<Scalars['Upload']>;
  /** The renaming fee for the membership. */
  renamingFee?: Maybe<Scalars['Float']>;
  /** Shipping options. */
  shippingOptions?: Maybe<ShippingOptionsInput>;
  showSchedule?: Maybe<Scalars['Boolean']>;
  /** The start date of the membership. */
  startDate: Scalars['DateTime'];
  tags?: Maybe<Array<Scalars['String']>>;
  termsAndConditions?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  ticketCover?: Maybe<MembershipTicketCoverInput>;
};

export type CreateMembershipMultiBuyPromotionInput = {
  buyMembershipTypeId: Scalars['String'];
  buyQuantity: Scalars['Float'];
  getMembershipTypeId: Scalars['String'];
  getQuantity: Scalars['Float'];
  /** The membership id that is associated with the promotion. */
  membershipId: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
};

export type CreateMembershipPromoCodeInput = {
  /** The unique code that represents the promotion. */
  code: Scalars['String'];
  /** The discount amount of the promotion. */
  discountAmount: Scalars['Float'];
  /** The discount type associated with the promotion. */
  discountType: DiscountType;
  /** The end date condition of the promotion. Returns `null` when inactive. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The maximum order amount condition of the promotion. Returns `null` when inactive. */
  maxOrderAmount?: Maybe<Scalars['Float']>;
  /** The maximum amount of use condition of the promotion. Returns `null` when inactive. */
  maxUsage?: Maybe<Scalars['Float']>;
  membershipId: Scalars['String'];
  membershipTypes?: Maybe<Array<Scalars['String']>>;
  /** The minimum order amount condition of the promotion. Returns `null` when inactive. */
  minOrderAmount?: Maybe<Scalars['Float']>;
  /** The start date condition of the promotion. Returns `null` when inactive. */
  startDate?: Maybe<Scalars['DateTime']>;
  type: MembershipPromoCodeType;
};

export type CreateMembershipTypeInput = {
  /** Restrictions for the membership type. */
  description?: Maybe<Scalars['String']>;
  isGuestCard?: Maybe<Scalars['Boolean']>;
  /** The kind of the membership type. */
  kind: MembershipTypeKind;
  maxPurchaseQuantity?: Maybe<Scalars['Float']>;
  /** The associated membership of the membership type. */
  membershipId: Scalars['String'];
  minPurchaseQuantity?: Maybe<Scalars['Float']>;
  /** Name of the membership type. */
  name: Scalars['String'];
  namingFields: Array<FormFieldInput>;
  /** Restrictions for the membership type. */
  restrictions?: Maybe<Scalars['String']>;
  /** The ticket fee. */
  ticketFee: Scalars['Float'];
  /** The ticket fee type. */
  ticketFeeType: FeeType;
};

export type CreateOrderInput = {
  /** Seats.IO hold token. */
  holdToken?: Maybe<Scalars['String']>;
  isCreatedFromRecoveredCart?: Maybe<Scalars['Boolean']>;
  /** List of the order's line items. */
  lineItems: Array<LineItemInput>;
  /** Membership id associated with the order. */
  membershipId?: Maybe<Scalars['String']>;
  /** Multi buy promotion id. */
  multiBuyId?: Maybe<Scalars['String']>;
  /** Order type */
  orderType?: Maybe<OrderType>;
  posId?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  referralId?: Maybe<Scalars['String']>;
  /** Release id associated with the order. */
  releaseId?: Maybe<Scalars['String']>;
  /** Release password id associated with the order. */
  releasePasswordId?: Maybe<Scalars['String']>;
  transferFee?: Maybe<Scalars['Float']>;
  /** User id can be provided for back-office orders */
  userId?: Maybe<Scalars['String']>;
};

export type CreateOrganizationInput = {
  address: AddressInput;
  branding?: Maybe<BrandingInput>;
  currency?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  helpLink?: Maybe<Scalars['String']>;
  landingPage: LandingPage;
  marketing?: Maybe<MarketingInput>;
  name: Scalars['String'];
  phone: Scalars['String'];
  privacyPolicy?: Maybe<Scalars['String']>;
  showMembershipRenewal?: Maybe<Scalars['Boolean']>;
  showMemberships?: Maybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  supportInfo?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  termsAndConditions?: Maybe<Scalars['String']>;
};

export type CreateOrganizationOutput = {
  __typename?: 'CreateOrganizationOutput';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type CreatePaymentInput = {
  /** Payment gateway chosen by the customer (leave empty if paying with credits only). */
  gateway?: Maybe<Scalars['String']>;
  /** Indicates if the order is a Stripe BNPL order. If set, the Afterpay BNPL option will be used instead of credit card. */
  isStripeBNPL?: Maybe<Scalars['Boolean']>;
  /** Whether the customer opts-in for marketing & promotions. */
  optInForMarketingPromotions: Scalars['Boolean'];
  pointRedemptionAmount?: Maybe<Scalars['Float']>;
  /** Whether the customer wants to initiate a reoccurring payment. */
  reoccurringPayment?: Maybe<Scalars['Boolean']>;
  /** Whether the customer gets a hold order confirmation. */
  sendHoldOrderCustomerEmail?: Maybe<Scalars['Boolean']>;
  /** Whether the customer receives a receipt by email. */
  skipOrderConfirmation?: Maybe<Scalars['Boolean']>;
};

/** Represents the order creation result. */
export type CreatePaymentOutput = {
  __typename?: 'CreatePaymentOutput';
  /** The order. */
  order: Order;
  /** The payment creation result of the order. */
  result?: Maybe<PaymentResult>;
};

export type CreatePinPaymentCharge = {
  cardToken: Scalars['String'];
  refId: Scalars['String'];
};

export type CreatePointInput = {
  gatewayIds: Array<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['String']>;
};

export type CreatePointItemInput = {
  amount: Scalars['Float'];
  backgroundImage?: Maybe<Scalars['Upload']>;
  banner?: Maybe<Scalars['Upload']>;
  description?: Maybe<Scalars['String']>;
  descriptionImage?: Maybe<Scalars['Upload']>;
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
  pointId: Scalars['String'];
  price: Scalars['Float'];
  thumbnail?: Maybe<Scalars['Upload']>;
};

export type CreatePosInput = {
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  /** Booking fee type. */
  bookingFeeType: FeeType;
  cashAmount?: Maybe<Scalars['Float']>;
  deliveryOptions: Array<ShippingOption>;
  /** Whether receipts are turned on. */
  enableReceipts?: Maybe<Scalars['Boolean']>;
  eventId: Scalars['String'];
  name: Scalars['String'];
  paymentOptions: PosPaymentOptionsInput;
  releaseId: Scalars['String'];
};

export type CreatePosUserInput = {
  accessCode: Scalars['String'];
  eventId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreatePurchaseRestrictionInput = {
  isAlwaysVisible?: Maybe<Scalars['Boolean']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  quantity: Scalars['Float'];
  requiredEntities: Array<PurchaseRestrictionRequiredEntityInput>;
  restrictedId: Scalars['String'];
  restrictedType: LineItemType;
};

export type CreateRedirectUrlInput = {
  /** Full URL path. */
  redirectURL: Scalars['String'];
  /** Reference entity ID. */
  refId: Scalars['String'];
  /** Type of entity this redirect is for. */
  refName: Scalars['String'];
};

export type CreateReferralCampaignDto = {
  eventId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  membershipId?: Maybe<Scalars['String']>;
  referralOwnerRewardQuantity: Scalars['Float'];
  referralOwnerRewardQuantityType: ReferralRewardQuantityType;
  referralOwnerRewardType: ReferralRewardType;
  referralReleaseId?: Maybe<Scalars['String']>;
  referralUserRewardQuantity: Scalars['Float'];
  referralUserRewardQuantityType: ReferralRewardQuantityType;
  referralUserRewardType: ReferralRewardType;
};

export type CreateReferralRewardsDto = {
  orderId: Scalars['String'];
  referralCampaignId: Scalars['String'];
  referralOwnerUserReferralId: Scalars['String'];
  referralUserId: Scalars['String'];
};

/** Creates a refund. */
export type CreateRefundInput = {
  /** Desired amount of the refund. */
  amount: Scalars['Float'];
  gateway?: Maybe<RefundGateway>;
  /** Whether the refund is a manual one. */
  isManual?: Maybe<Scalars['Boolean']>;
  /** Notes why the refund was created. */
  notes: Scalars['String'];
  /** Order id for which the refund will be created. */
  orderId: Scalars['String'];
  /** Whether to send a refund confirmation email. */
  sendEmail?: Maybe<Scalars['Boolean']>;
  /** Tickets to realease (will also release child tickets). */
  ticketIds?: Maybe<Array<Scalars['String']>>;
};

export type CreateReleaseInput = {
  /** Can this release use Windcaves Account2Account functionality */
  allowAccount2Account?: Maybe<Scalars['Boolean']>;
  /** Can customers create hold orders for this release */
  allowHoldOrders?: Maybe<Scalars['Boolean']>;
  /** Are comps visible to public */
  allowPublicComp?: Maybe<Scalars['Boolean']>;
  /** Request purchasers  */
  askForDietaryRequirements?: Maybe<Scalars['Boolean']>;
  /** Ask how did you heard about us during the purchasing flow */
  askHowDidYouHearAboutUs?: Maybe<Scalars['Boolean']>;
  /** The end condition of the release. */
  endCondition: ReleaseEndCondition;
  /** The end date of the release. Pass null if the endCondition is set to other than `date`. */
  endConditionDate?: Maybe<Scalars['DateTime']>;
  /** The quantity limit of the release. Pass null if the endCondition is set to other than `quantity`. */
  endConditionQuantity?: Maybe<Scalars['Float']>;
  event: Scalars['String'];
  /** The status of the release. */
  isActive?: Maybe<Scalars['Boolean']>;
  maxPurchaseQuantity?: Maybe<Scalars['Float']>;
  minPurchaseQuantity?: Maybe<Scalars['Float']>;
  /** Release name. */
  name: Scalars['String'];
  /** The password for the unlisted release. Pass null if the release type is set to other than `unlisted`. */
  password?: Maybe<Scalars['String']>;
  presaleHeader?: Maybe<Scalars['Upload']>;
  /** The information of the unlisted release. Pass null if the `type` is set to other than `unlisted`. */
  presaleInformation?: Maybe<Scalars['String']>;
  presaleLogo?: Maybe<Scalars['Upload']>;
  /** The title of the unlisted release. Pass null if the `type` is set to other than `unlisted`. */
  presaleTitle?: Maybe<Scalars['String']>;
  releaseEventAddons?: Maybe<Array<Scalars['String']>>;
  /** The information that will be shown to customers on the ticketing page. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** The zones of the release. */
  releaseZones: Array<ReleaseZoneInput>;
  /** The slug for the unlisted release. Pass null if the release type is set to other than `unlisted`. */
  slug?: Maybe<Scalars['String']>;
  /** The start condition of the release. */
  startCondition: ReleaseStartCondition;
  /** The start date of the release. Pass null if the startCondition is set to other than `date`. */
  startConditionDate?: Maybe<Scalars['DateTime']>;
  /** Release type. */
  type: ReleaseType;
};

export type CreateReleaseZoneInput = {
  admission: Scalars['Boolean'];
  capacity?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  eventId: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateResaleTicketTypeInput = {
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** The event id that is associated with the ticket type. */
  event: Scalars['String'];
  /** Ticket group name. */
  name: Scalars['String'];
  /** Price for v2 event */
  price?: Maybe<Scalars['Float']>;
  /** The ticket types that map to this resale ticket. */
  ticketTypes: Array<Scalars['String']>;
};

export type CreateScoutCommunicationInput = {
  campaignId?: Maybe<Scalars['String']>;
  includeOptedOutOfMarketing?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  listId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  purpose: ScoutPurpose;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentId?: Maybe<Scalars['String']>;
  shouldUpdate?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Float']>;
  trackReleaseId?: Maybe<Scalars['String']>;
  trackSource?: Maybe<ReportingFilterSource>;
  trackSourceId?: Maybe<Scalars['String']>;
  type: ScoutCommunicationType;
};

export type CreateScoutInput = {
  exclude?: Maybe<Array<FilterSelectionInput>>;
  name: Scalars['String'];
  promoteTo: Array<FilterSelectionInput>;
};

export type CreateSeasonInput = {
  backgroundImageFullPage?: Maybe<Scalars['Upload']>;
  /** Season description. */
  description: Scalars['String'];
  displayOrder: Scalars['Float'];
  /** Season active status. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Season name. */
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['Upload']>;
};

export type CreateTagInput = {
  name: Scalars['String'];
};

export type CreateTemporaryPosUserInput = {
  eventId: Scalars['String'];
  fullName: Scalars['String'];
};

export type CreateTemporaryPosUserOutput = {
  __typename?: 'CreateTemporaryPosUserOutput';
  /** Access token to create a single temporary POS user */
  oneTimeAccessToken: Scalars['String'];
  password: Scalars['String'];
  user: User;
};

export type CreateTestEmailInput = {
  communicationId: Scalars['String'];
  emails: Array<Scalars['String']>;
  subject: Scalars['String'];
  templateId: Scalars['Float'];
};

export type CreateTicketTypeDate = {
  alternateEntryTime?: Maybe<Scalars['String']>;
  eventDateId: Scalars['String'];
};

export type CreateTicketTypeInput = {
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** Event dates. */
  dates: Array<CreateTicketTypeDate>;
  /** Restrictions for the ticket group. */
  description?: Maybe<Scalars['String']>;
  /** The event id that is associated with the ticket type. */
  event: Scalars['String'];
  /** The amount of people in a group. Returns null if the ticket type is not grouped. */
  groupAmount?: Maybe<Scalars['Float']>;
  /** Mark a ticket type as high demand */
  inHighDemand: Scalars['Boolean'];
  /** isActive for v2 event */
  isActive?: Maybe<Scalars['Boolean']>;
  /** isComplimentary for v2 event */
  isComplimentary?: Maybe<Scalars['Boolean']>;
  /** Whether the ticket type is grouped. */
  isGrouped?: Maybe<Scalars['Boolean']>;
  /** The kind of the ticket type. */
  kind: TicketTypeKind;
  maxPurchaseQuantity?: Maybe<Scalars['Float']>;
  minPurchaseQuantity?: Maybe<Scalars['Float']>;
  /** Ticket group name. */
  name: Scalars['String'];
  namingFields: Array<FormFieldInput>;
  /** Price for v2 event */
  price?: Maybe<Scalars['Float']>;
  /** Print at home information for the ticket group. */
  printAtHomeInformation?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  /** releaseZoneId for v2 event */
  releaseZoneId?: Maybe<Scalars['String']>;
  /** Restrictions for the ticket group. */
  restrictions?: Maybe<Scalars['String']>;
  /** MMark a ticket type as sold out */
  soldOut: Scalars['Boolean'];
  /** Per ticket stadium levy ticket fee. */
  stadiumLevyFee?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<Scalars['String']>>;
  /** Image banner specific for a ticket type on the print at home ticket */
  ticketBanner?: Maybe<Scalars['Upload']>;
  /** Image banner specific for a ticket type on the print at home ticket */
  ticketImage?: Maybe<Scalars['Upload']>;
};

export type CreateUserInput = {
  accessEventIds?: Maybe<Array<Scalars['String']>>;
  accessMembershipIds?: Maybe<Array<Scalars['String']>>;
  /** Billing address provided by the user. */
  billingAddress?: Maybe<PartialAddressInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  companyName?: Maybe<Scalars['String']>;
  credits?: Maybe<Scalars['Float']>;
  /** Elevate users privileges from user to admin. */
  elevatePrivileges?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Gender>;
  instagramUsername?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  referralId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
  /** Shipping address provided by the user. */
  shippingAddress?: Maybe<PartialAddressInput>;
  /** Whether the shipping address matches the billing address. */
  shippingAddressMatchesBillingAddress?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  taxNumber?: Maybe<Scalars['String']>;
  twoFactorAuthenticationSecret?: Maybe<Scalars['String']>;
  usableForMarketing?: Maybe<Scalars['Boolean']>;
  userAcquisition?: Maybe<UserAcquisition>;
};

export type CreateVenueInput = {
  accessibilityInfo?: Maybe<Scalars['String']>;
  address: AddressInput;
  capacity?: Maybe<Scalars['Int']>;
  capacityUtilization?: Maybe<Scalars['Float']>;
  gates?: Maybe<Array<GateInput>>;
  location?: Maybe<CoordinateInput>;
  name: Scalars['String'];
  seating: VenueSeating;
  /** Seats.IO chart id. */
  seatsChartKey?: Maybe<Scalars['String']>;
  zones?: Maybe<Array<ZoneInput>>;
};

export type CurrentOrganizationOutput = {
  __typename?: 'CurrentOrganizationOutput';
  _taxRate?: Maybe<Scalars['Float']>;
  /** Address details. */
  address?: Maybe<Address>;
  branding: OrganizationOutputBranding;
  createdAt: Scalars['DateTime'];
  /** Currency */
  currency?: Maybe<Scalars['String']>;
  /** Contact e-mail address. */
  email: Scalars['String'];
  /** Beta features. */
  features?: Maybe<Array<Scalars['String']>>;
  helpLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Landing page settings. */
  landingPage: LandingPage;
  /** Marketing settings. */
  marketing?: Maybe<Marketing>;
  /** Organization name. */
  name: Scalars['String'];
  /** Contact phone. */
  phone: Scalars['String'];
  point?: Maybe<Point>;
  /** The privacy policy of the organistion */
  privacyPolicy?: Maybe<Scalars['String']>;
  /** Shipping labels. */
  shippingLabels?: Maybe<ShippingLabels>;
  shopify?: Maybe<ShopifyIntegration>;
  /** Show memberships renewal banner. */
  showMembershipRenewal?: Maybe<Scalars['Boolean']>;
  /** Show memberships in client navigation. */
  showMemberships: Scalars['Boolean'];
  singleEvent?: Maybe<Event>;
  /** Organization slug. */
  slug: Scalars['String'];
  /** The support information of the organistion */
  supportInfo?: Maybe<Scalars['String']>;
  /** Tax calculation method. either inclusive or exclusive. */
  taxCalculationMethod: TaxCalculationMethod;
  /** Tax number */
  taxNumber?: Maybe<Scalars['String']>;
  taxRate: Scalars['Float'];
  /** The terms and conditions of the organistion */
  termsAndConditions?: Maybe<Scalars['String']>;
  ticketCoverPlatformFeePercentage?: Maybe<Scalars['Float']>;
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CustomField = {
  __typename?: 'CustomField';
  fieldName: Scalars['String'];
  fieldValue: Scalars['String'];
};

export type CustomFieldInput = {
  fieldName: Scalars['String'];
  fieldValue: Scalars['String'];
};

export type CustomSaleOutput = {
  __typename?: 'CustomSaleOutput';
  data?: Maybe<Scalars['JSON']>;
};

export type CustomerCompetitionReportingInput = {
  customerId: Scalars['String'];
};

export type CustomerReferralReportingInput = {
  customerId: Scalars['String'];
};


export type DeleteOneOutput = {
  __typename?: 'DeleteOneOutput';
  deletedId?: Maybe<Scalars['String']>;
};

export type DeleteScoutUserInput = {
  ids: Array<Scalars['String']>;
};

/** Represents additional info required to host a digital event/membership. */
export type DigitalInfo = {
  __typename?: 'DigitalInfo';
  /** Additional email info button text to be added in digital event/membership access emails. */
  additionalEmailButtonText?: Maybe<Scalars['String']>;
  /** Additional email info button URL to be added in digital event/membership access emails. */
  additionalEmailButtonUrl?: Maybe<Scalars['String']>;
  /** Additional email info HTML be added in digital event/membership access emails. */
  additionalEmailInfoHTML?: Maybe<Scalars['String']>;
  /** Additional email info to be added in digital event/membership access emails. */
  additionalEmailInfoText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** The Postmark email template that is used to send digital pass codes. */
  emailTemplateId?: Maybe<Scalars['String']>;
  /** Event. */
  event?: Maybe<Event>;
  id: Scalars['ID'];
  /** Membership. */
  membership?: Maybe<Membership>;
  updatedAt: Scalars['DateTime'];
  /** The url that digital pass codes are appended to in order to access the digital event/membership. */
  url: Scalars['String'];
};

export type DigitalPassCodeStatusOutput = {
  __typename?: 'DigitalPassCodeStatusOutput';
  remainingPassCodes: Scalars['Int'];
  totalPassCodes: Scalars['Int'];
};

export enum DiscountType {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE'
}

/** Duplicate an existing release. */
export type DuplicateSingleReleaseInput = {
  /** Event where the duplicated release will be */
  eventId: Scalars['String'];
  /** The new slug for the release */
  newSlug?: Maybe<Scalars['String']>;
  /** Event where the duplicated release will be */
  releaseId: Scalars['String'];
  /** The type of the new release */
  releaseType?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  /** The accessibility info of the event */
  accessibility?: Maybe<Scalars['String']>;
  addons: Array<EventAddon>;
  backgroundImage?: Maybe<ExtendedFile>;
  backgroundImageFullPage?: Maybe<ExtendedFile>;
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** The fee the buyer pays for transferring a ticket. */
  buyerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** Whether changing seats is available. */
  canChangeSeats: Scalars['Boolean'];
  /** Whether naming ticket is available. */
  canNameTicket: Scalars['Boolean'];
  /** Whether renaming ticket is available. */
  canRenameTicket: Scalars['Boolean'];
  /** Whether transfering ticket is available. */
  canTransferTicket: Scalars['Boolean'];
  canUsePoints: Scalars['Boolean'];
  /** The fee for changing seats for the event ticket. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** Featured status. */
  checkForVaccinePass: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  /** Event dates. */
  dates: Array<EventDate>;
  /** Event description. */
  description: Scalars['String'];
  descriptionImage?: Maybe<ExtendedFile>;
  digitalInfo?: Maybe<DigitalInfo>;
  digitalPassCodesStatus?: Maybe<DigitalPassCodeStatusOutput>;
  /** Email address that gets sent a notifcation every time an order is completed */
  emailNotification?: Maybe<Scalars['String']>;
  /** Whether renaming ticket is available. */
  enableReleaseCodes: Scalars['Boolean'];
  /** Whether the waitlist is visible on the event information page */
  enableWaitlist: Scalars['Boolean'];
  /** Event end date. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The kind of event */
  eventKind: EventKind;
  gateways: Array<Integration>;
  /** The ID of the Google Wallet Event Class for this event */
  googleWalletEventClassId?: Maybe<Scalars['String']>;
  hardCopyTicketBanner?: Maybe<ExtendedFile>;
  /** Event endDate has now lapsed, therefore it has ended. */
  hasEventEnded: Scalars['Boolean'];
  hasPromoCodes: Scalars['Boolean'];
  hasPublicRelease: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Important notice section on the tickets */
  importantNotice?: Maybe<Scalars['String']>;
  /** Inside fees for the event */
  insideFeeDetails?: Maybe<Array<InsideFeeDetails>>;
  /** The invoice info of the event */
  invoiceInformation?: Maybe<Scalars['String']>;
  /** Event active status. */
  isActive: Scalars['Boolean'];
  /** Featured status. */
  isFeatured: Scalars['Boolean'];
  /** Whether event is R-18. */
  isR18: Scalars['Boolean'];
  isWaitlistAvailable: Scalars['Boolean'];
  lastTicketsViewableEmailsSentAt?: Maybe<Scalars['DateTime']>;
  listedRelease?: Maybe<Release>;
  logo?: Maybe<ExtendedFile>;
  merchantReferenceResult?: Maybe<Scalars['String']>;
  multiBuyPromotions: Array<EventMultiBuyPromotion>;
  /** Whether to allow a ticket to be scanned in to multiple areas within a venue. */
  multiScanningEnabled: Scalars['Boolean'];
  /** event level facebook pixel id */
  pixelId?: Maybe<Scalars['String']>;
  /** The presale info of the event */
  presaleInformation?: Maybe<Scalars['String']>;
  printBanner1?: Maybe<ExtendedFile>;
  printBanner2?: Maybe<ExtendedFile>;
  /** Once a customer has finished purchasing they can be redirected back to another URL to continuing their purchsing journey */
  redirectURL?: Maybe<Scalars['String']>;
  /** The referral campaign associated with this event */
  referralCampaignId?: Maybe<Scalars['String']>;
  releases: Array<Release>;
  /** The renaming fee for the event ticket. */
  renamingFee?: Maybe<Scalars['Float']>;
  /** Event season. */
  season?: Maybe<Season>;
  /** Seats.io event key. */
  seatsEventKey?: Maybe<Scalars['String']>;
  /** The fee the seller pays for transferring a ticket. */
  sellerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** Shipping options. */
  shippingOptions?: Maybe<ShippingOptions>;
  soldTicketsCount?: Maybe<Scalars['Float']>;
  soldTicketsCountToday?: Maybe<Scalars['Float']>;
  soldTicketsCountYesterday?: Maybe<Scalars['Float']>;
  /** Event start date. */
  startDate?: Maybe<Scalars['DateTime']>;
  tags: Array<Tag>;
  /** The terms and conditions of the event */
  termsAndConditions?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<ExtendedFile>;
  ticketCover?: Maybe<EventTicketCover>;
  /** Whether sell to other fans on the waitlist is enabled. */
  ticketResaleEnabled: Scalars['Boolean'];
  /** Whether to enable waitlist for tickets when sold out. */
  ticketResaleWaitlistEnabled: Scalars['Boolean'];
  ticketSoldTime?: Maybe<TicketSoldTime>;
  ticketTypes: Array<TicketType>;
  /** Date at which tickets are viewable/printable after. */
  ticketsViewableFromDate?: Maybe<Scalars['DateTime']>;
  /** Event title. */
  title: Scalars['String'];
  totalRevenue?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  venue: Venue;
  venueAddress: Venue;
  /** The date the presale registration will no longer be visible on the event information page. */
  waitlistEndDate?: Maybe<Scalars['DateTime']>;
  /** The date the presale registration will be visible on the event information page. */
  waitlistStartDate?: Maybe<Scalars['DateTime']>;
  /** Allocated gates to venue zones */
  zonesAllocatedGates?: Maybe<Array<ZoneAllocatedGate>>;
};


export type EventTicketTypesArgs = {
  ticketTypeFilter?: Maybe<TicketTypeFilter>;
};

export enum EventAccessStatus {
  Allowed = 'ALLOWED',
  AllowedAdminPreview = 'ALLOWED_ADMIN_PREVIEW',
  Denied = 'DENIED',
  EventEnded = 'EVENT_ENDED'
}

export type EventAddon = {
  __typename?: 'EventAddon';
  /** Image to be shown on the customer portal of the addon . Returns null if the not set. */
  addonImage?: Maybe<ExtendedFile>;
  createdAt: Scalars['DateTime'];
  /** The brief description of the addon. */
  description?: Maybe<Scalars['String']>;
  /** Indicates whether naming the addon is possible or not. */
  enableNaming: Scalars['Boolean'];
  /** Whether hide QR code on ticket. */
  hideQRCode: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Booking fee. */
  insideFee?: Maybe<Scalars['Float']>;
  /** The active status of the addon. */
  isActive: Scalars['Boolean'];
  /** The name of the addon. */
  name: Scalars['String'];
  namingFields: Array<FormField>;
  /** The price associated with the addon. */
  price: Scalars['Float'];
  purchaseRestrictions: Array<PurchaseRestriction>;
  purchaseRestrictionsHelpText: Array<Scalars['String']>;
  /** Maximum quantity to be sold for the addon. */
  quantity: Scalars['Float'];
  /** The restrictions applied to the addon. */
  restrictions?: Maybe<Scalars['String']>;
  scannableGateIds: Array<Scalars['String']>;
  totalSold?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type EventConnection = {
  __typename?: 'EventConnection';
  edges: Array<EventEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Represents a date in an event. */
export type EventDate = {
  __typename?: 'EventDate';
  endDate: Scalars['String'];
  id: Scalars['ID'];
  startDate: Scalars['String'];
};

export type EventEdge = {
  __typename?: 'EventEdge';
  cursor: Scalars['String'];
  node: Event;
};

export type EventForCompetitionReportingEntity = {
  __typename?: 'EventForCompetitionReportingEntity';
  /** The accessibility info of the event */
  accessibility?: Maybe<Scalars['String']>;
  addons: Array<EventAddon>;
  backgroundImage?: Maybe<ExtendedFile>;
  backgroundImageFullPage?: Maybe<ExtendedFile>;
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** The fee the buyer pays for transferring a ticket. */
  buyerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** Whether changing seats is available. */
  canChangeSeats: Scalars['Boolean'];
  /** Whether naming ticket is available. */
  canNameTicket: Scalars['Boolean'];
  /** Whether renaming ticket is available. */
  canRenameTicket: Scalars['Boolean'];
  /** Whether transfering ticket is available. */
  canTransferTicket: Scalars['Boolean'];
  canUsePoints: Scalars['Boolean'];
  /** The fee for changing seats for the event ticket. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** Featured status. */
  checkForVaccinePass: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  /** Event dates. */
  dates: Array<EventDate>;
  /** Event description. */
  description: Scalars['String'];
  descriptionImage?: Maybe<ExtendedFile>;
  digitalInfo?: Maybe<DigitalInfo>;
  digitalPassCodesStatus?: Maybe<DigitalPassCodeStatusOutput>;
  /** Email address that gets sent a notifcation every time an order is completed */
  emailNotification?: Maybe<Scalars['String']>;
  /** Whether renaming ticket is available. */
  enableReleaseCodes: Scalars['Boolean'];
  /** Whether the waitlist is visible on the event information page */
  enableWaitlist: Scalars['Boolean'];
  /** Event end date. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The kind of event */
  eventKind: EventKind;
  gateways: Array<Integration>;
  /** The ID of the Google Wallet Event Class for this event */
  googleWalletEventClassId?: Maybe<Scalars['String']>;
  /** Indicates if the event had or has a presale waitlist */
  hadOrHasPresaleWaitlist: Scalars['Boolean'];
  hardCopyTicketBanner?: Maybe<ExtendedFile>;
  /** Event endDate has now lapsed, therefore it has ended. */
  hasEventEnded: Scalars['Boolean'];
  hasPromoCodes: Scalars['Boolean'];
  /** Indicates if the event had a public sales release */
  hasPublicRelease: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Important notice section on the tickets */
  importantNotice?: Maybe<Scalars['String']>;
  /** Inside fees for the event */
  insideFeeDetails?: Maybe<Array<InsideFeeDetails>>;
  /** The invoice info of the event */
  invoiceInformation?: Maybe<Scalars['String']>;
  /** Event active status. */
  isActive: Scalars['Boolean'];
  /** Featured status. */
  isFeatured: Scalars['Boolean'];
  /** Whether event is R-18. */
  isR18: Scalars['Boolean'];
  isWaitlistAvailable: Scalars['Boolean'];
  lastTicketsViewableEmailsSentAt?: Maybe<Scalars['DateTime']>;
  listedRelease?: Maybe<Release>;
  logo?: Maybe<ExtendedFile>;
  merchantReferenceResult?: Maybe<Scalars['String']>;
  multiBuyPromotions: Array<EventMultiBuyPromotion>;
  /** Whether to allow a ticket to be scanned in to multiple areas within a venue. */
  multiScanningEnabled: Scalars['Boolean'];
  /** event level facebook pixel id */
  pixelId?: Maybe<Scalars['String']>;
  /** The presale info of the event */
  presaleInformation?: Maybe<Scalars['String']>;
  printBanner1?: Maybe<ExtendedFile>;
  printBanner2?: Maybe<ExtendedFile>;
  /** Once a customer has finished purchasing they can be redirected back to another URL to continuing their purchsing journey */
  redirectURL?: Maybe<Scalars['String']>;
  /** The referral campaign associated with this event */
  referralCampaignId?: Maybe<Scalars['String']>;
  releases: Array<Release>;
  /** The renaming fee for the event ticket. */
  renamingFee?: Maybe<Scalars['Float']>;
  /** Event season. */
  season?: Maybe<Season>;
  /** Seats.io event key. */
  seatsEventKey?: Maybe<Scalars['String']>;
  /** The fee the seller pays for transferring a ticket. */
  sellerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** Shipping options. */
  shippingOptions?: Maybe<ShippingOptions>;
  soldTicketsCount?: Maybe<Scalars['Float']>;
  soldTicketsCountToday?: Maybe<Scalars['Float']>;
  soldTicketsCountYesterday?: Maybe<Scalars['Float']>;
  /** Event start date. */
  startDate?: Maybe<Scalars['DateTime']>;
  tags: Array<Tag>;
  /** The terms and conditions of the event */
  termsAndConditions?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<ExtendedFile>;
  ticketCover?: Maybe<EventTicketCover>;
  /** Whether sell to other fans on the waitlist is enabled. */
  ticketResaleEnabled: Scalars['Boolean'];
  /** Whether to enable waitlist for tickets when sold out. */
  ticketResaleWaitlistEnabled: Scalars['Boolean'];
  ticketSoldTime?: Maybe<TicketSoldTime>;
  ticketTypes: Array<TicketType>;
  /** Date at which tickets are viewable/printable after. */
  ticketsViewableFromDate?: Maybe<Scalars['DateTime']>;
  /** Event title. */
  title: Scalars['String'];
  totalRevenue?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  venue: Venue;
  venueAddress: Venue;
  /** The date the presale registration will no longer be visible on the event information page. */
  waitlistEndDate?: Maybe<Scalars['DateTime']>;
  /** The date the presale registration will be visible on the event information page. */
  waitlistStartDate?: Maybe<Scalars['DateTime']>;
  /** Allocated gates to venue zones */
  zonesAllocatedGates?: Maybe<Array<ZoneAllocatedGate>>;
};


export type EventForCompetitionReportingEntityTicketTypesArgs = {
  ticketTypeFilter?: Maybe<TicketTypeFilter>;
};

export type EventForecastOutput = {
  __typename?: 'EventForecastOutput';
  eventId: Scalars['String'];
  id: Scalars['String'];
  /** The array of possible forecasting scenarios */
  scenarios?: Maybe<Array<ForecastScenario>>;
  /** The zones and forecasted seat count attributes */
  zones?: Maybe<Array<EventForecastZoneOutput>>;
};

/** Object to hold the zoneId and the seat allocation forecasts */
export type EventForecastZone = {
  __typename?: 'EventForecastZone';
  /** How many seats are forecast to be comps */
  comps: Scalars['Int'];
  /** How many seats are forecast to be contingency */
  contingency: Scalars['Int'];
  /** The zone id of for the seats forecast. */
  zoneId?: Maybe<Scalars['String']>;
};

/** Object to hold the zoneId and the seat allocation forecasts */
export type EventForecastZoneOutput = {
  __typename?: 'EventForecastZoneOutput';
  /** How many seats are forecast to be comps */
  comps: Scalars['Int'];
  /** How many seats are forecast to be contingency */
  contingency: Scalars['Int'];
  /** Sales statistics if available */
  sales?: Maybe<FinancialSales>;
  /** The zone id of for the seats forecast. */
  zoneId?: Maybe<Scalars['String']>;
};

/** Ticket kind for ticket type. */
export enum EventKind {
  Digital = 'DIGITAL',
  Standard = 'STANDARD'
}

export type EventMultiBuyPromotion = {
  __typename?: 'EventMultiBuyPromotion';
  buyQuantity: Scalars['Float'];
  buyTicketType: TicketType;
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  getQuantity: Scalars['Float'];
  getTicketType: TicketType;
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  used: Scalars['Float'];
};

export type EventOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
  endDate?: Maybe<OrderByDirection>;
  isFeatured?: Maybe<OrderByDirection>;
  startDate?: Maybe<OrderByDirection>;
  updatedAt?: Maybe<OrderByDirection>;
};

export type EventOrdersOnlineOutput = {
  __typename?: 'EventOrdersOnlineOutput';
  addons: Scalars['Float'];
  baseTickets: Scalars['Float'];
  bookingFees: Scalars['Float'];
  creditsUsed: Scalars['Float'];
  deliveryFees: Scalars['Float'];
  orderIdsOnline: Array<OrderIdsOnlineOutput>;
  refundedAmount: Scalars['Float'];
  ticketFees: Scalars['Float'];
  totalCount: Scalars['Float'];
  transactionFees: Scalars['Float'];
};

export type EventPromoCode = {
  __typename?: 'EventPromoCode';
  /** The unique code that represents the promotion. */
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The discount amount of the promotion. */
  discountAmount: Scalars['Float'];
  /** The discount type associated with the promotion. */
  discountType: DiscountType;
  enabled: Scalars['Boolean'];
  /** The end date condition of the promotion. Returns `null` when inactive. */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The maximum order amount condition of the promotion. Returns `null` when inactive. */
  maxOrderAmount?: Maybe<Scalars['Float']>;
  /** The maximum amount of use condition of the promotion. Returns `null` when inactive. */
  maxUsage?: Maybe<Scalars['Float']>;
  /** The minimum order amount condition of the promotion. Returns `null` when inactive. */
  minOrderAmount?: Maybe<Scalars['Float']>;
  /** The start date condition of the promotion. Returns `null` when inactive. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Ticket type ids for promo code. */
  ticketTypes?: Maybe<Array<Scalars['String']>>;
  /** The type of the promotion which determines on which items the promotion will be applied. */
  type: EventPromoCodeType;
  updatedAt: Scalars['DateTime'];
  /** The total amount of usage of the promotion. */
  used?: Maybe<Scalars['Int']>;
};

export enum EventPromoCodeType {
  Addons = 'ADDONS',
  Event = 'EVENT',
  EventAndAddons = 'EVENT_AND_ADDONS'
}

export type EventSocialPage = {
  __typename?: 'EventSocialPage';
  isEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type EventSocialPageInput = {
  isEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type EventTicketCover = {
  __typename?: 'EventTicketCover';
  createdAt: Scalars['DateTime'];
  disabledAt?: Maybe<Scalars['DateTime']>;
  enabledAt?: Maybe<Scalars['DateTime']>;
  /** Event that the ticket cover is configured for */
  event: Event;
  id: Scalars['ID'];
  /** Is ticket cover enabled */
  isEnabled: Scalars['Boolean'];
  /** Platform fee as percentage of ticket price */
  platformFeePercentage: Scalars['Float'];
  /** Promoter fee as percentage of ticket price */
  promoterFeePercentage: Scalars['Float'];
  /** Ticket cover purchase behaviour */
  purchaseBehaviour: TicketCoverPurchaseBehaviour;
  /** Total fee as percentage of ticket price */
  totalFeePercentage: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type EventWhereInput = {
  /** Event end date. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Event active status. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Event featured status. */
  isFeatured?: Maybe<Scalars['Boolean']>;
  /**
   * If this is set to true,
   *     then we will left join release and check whether this event contains a outlet release.
   */
  isOutlet?: Maybe<Scalars['Boolean']>;
  pos?: Maybe<Scalars['Boolean']>;
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
  /** Add it if the user only have limited access to events. */
  restrictedUserId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  seating?: Maybe<VenueSeating>;
  /** Event start date. */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EventsWithAccessControlInput = {
  eventId: Scalars['String'];
  releaseId?: Maybe<Scalars['String']>;
  releaseSlug?: Maybe<Scalars['String']>;
};

export type EventsWithAccessControlOutput = {
  __typename?: 'EventsWithAccessControlOutput';
  accessType?: Maybe<EventAccessStatus>;
  event: Event;
  isAdmin: Scalars['Boolean'];
  release?: Maybe<Release>;
};

export type ExtendedFile = {
  __typename?: 'ExtendedFile';
  directory: Scalars['String'];
  fileName: Scalars['String'];
  metaData?: Maybe<MetaData>;
  originalFileName?: Maybe<Scalars['String']>;
  storage: StorageType;
};

export type FacebookPageListsInput = {
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type FailedImportItem = {
  __typename?: 'FailedImportItem';
  index: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
};

export type FansWouldPay = {
  __typename?: 'FansWouldPay';
  mean: Scalars['Float'];
  median: Scalars['Float'];
};

/** The valid values for fee types. */
export enum FeeType {
  FlatRate = 'FlatRate',
  Percentage = 'Percentage'
}

export type FilterSelection = {
  __typename?: 'FilterSelection';
  eventIds?: Maybe<Array<Scalars['String']>>;
  eventTitlesForWaitlist?: Maybe<Array<Scalars['String']>>;
  eventTypes?: Maybe<Array<ScoutEventType>>;
  gender?: Maybe<Array<Gender>>;
  maxAge?: Maybe<Scalars['Float']>;
  membershipTypes?: Maybe<Array<Scalars['String']>>;
  minAge?: Maybe<Scalars['Float']>;
  regions?: Maybe<Array<Scalars['String']>>;
  releaseIds?: Maybe<Array<Scalars['String']>>;
  seasonIds?: Maybe<Array<Scalars['String']>>;
  ticketTypeKinds?: Maybe<Array<TicketTypeKind>>;
  totalEventsPurchasedTo?: Maybe<Scalars['Float']>;
  userTagIds?: Maybe<Array<Scalars['String']>>;
  zones?: Maybe<Array<Scalars['String']>>;
};

export type FilterSelectionInput = {
  eventIds?: Maybe<Array<Scalars['String']>>;
  eventTitlesForWaitlist?: Maybe<Array<Scalars['String']>>;
  eventTypes?: Maybe<Array<ScoutEventType>>;
  gender?: Maybe<Array<Gender>>;
  maxAge?: Maybe<Scalars['Float']>;
  membershipTypes?: Maybe<Array<Scalars['String']>>;
  minAge?: Maybe<Scalars['Float']>;
  regions?: Maybe<Array<Scalars['String']>>;
  releaseIds?: Maybe<Array<Scalars['String']>>;
  seasonIds?: Maybe<Array<Scalars['String']>>;
  ticketTypeKinds?: Maybe<Array<TicketTypeKind>>;
  totalEventsPurchasedTo?: Maybe<Scalars['Float']>;
  userTagIds?: Maybe<Array<Scalars['String']>>;
  zones?: Maybe<Array<Scalars['String']>>;
};

export type FinancialGatewayGraphOutput = {
  __typename?: 'FinancialGatewayGraphOutput';
  integrations: Array<FinancialGraphIntegration>;
  transactions: Scalars['Float'];
};

export type FinancialGraphIntegration = {
  __typename?: 'FinancialGraphIntegration';
  name: Scalars['String'];
  transactions: Scalars['Float'];
};

export type FinancialOverviewOutput = {
  __typename?: 'FinancialOverviewOutput';
  avgOrderAmount: Scalars['Float'];
  avgOrderItemsCount: Scalars['Float'];
  discounts: Scalars['Float'];
  grossSales: Scalars['Float'];
  netSales: Scalars['Float'];
  resaleRevenue: Scalars['Float'];
  shippingCosts: Scalars['Float'];
  ticketCoverCost: Scalars['Float'];
  ticketCoverMargin: Scalars['Float'];
  ticketCoverOrderTotal: Scalars['Float'];
  transactionFees: Scalars['Float'];
  transferFees: Scalars['Float'];
};

export type FinancialSales = {
  __typename?: 'FinancialSales';
  available: Scalars['Float'];
  capacity: Scalars['Float'];
  sellableCapacity: Scalars['Float'];
  soldPercentage: Scalars['Float'];
  totalHoldByMembershipCount: Scalars['Float'];
  totalMembershipTicketCount: Scalars['Float'];
  totalNotForSaleCount: Scalars['Float'];
  types: Array<FinancialSalesType>;
  zoneId: Scalars['String'];
  zoneName: Scalars['String'];
};

export type FinancialSalesType = {
  __typename?: 'FinancialSalesType';
  baseRevenue: Scalars['Float'];
  comps: Scalars['Float'];
  holds: Scalars['Float'];
  issued: Scalars['Float'];
  name: Scalars['String'];
  sold: Scalars['Float'];
  totalWithBookingFees: Scalars['Float'];
  transfers: Scalars['Float'];
  transfersPending: Scalars['Float'];
};

export type FindAutomationsInput = {
  automationType?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
};

export type FindScoutsInput = {
  isActive?: Maybe<Scalars['Boolean']>;
};

export type ForecastScenario = {
  __typename?: 'ForecastScenario';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  /** The zones and forecasted seat count attributes */
  zones?: Maybe<Array<ForecastScenarioZone>>;
};

/** Object to hold the zoneId and the seat allocation forecasts */
export type ForecastScenarioZone = {
  __typename?: 'ForecastScenarioZone';
  /** Array of ticket type ids and the forecast cost and spilt of ticket sales */
  ticketTypes: Array<ScenarioZoneTicketType>;
  /** The zone id of for the seats forecast. */
  zoneId?: Maybe<Scalars['String']>;
};

export type ForecastZoneInput = {
  /** How many seats are forecast to be comped. */
  comps: Scalars['Float'];
  /** How many seats are forecast to be comped. */
  contingency: Scalars['Float'];
  /** The zone related to this input */
  zoneId: Scalars['String'];
};

export type FormField = {
  __typename?: 'FormField';
  field: Scalars['String'];
  isCustom?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
};

export type FormFieldInput = {
  field: Scalars['String'];
  isCustom?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
};

export type Gate = {
  __typename?: 'Gate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** The gate belonging to a venue */
export type GateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** The valid values for gender. */
export enum Gender {
  Female = 'Female',
  Male = 'Male',
  Other = 'Other'
}

export type GenderBreakDownOutput = {
  __typename?: 'GenderBreakDownOutput';
  female?: Maybe<Scalars['Float']>;
  male?: Maybe<Scalars['Float']>;
  other?: Maybe<Scalars['Float']>;
};

export type GetHistoryByCustomerIdInput = {
  /** Id of order. Used when looking at a customers order */
  orderId?: Maybe<Scalars['String']>;
  /** An array of the reference name of the history item. */
  refName?: Maybe<Array<Scalars['String']>>;
  /** Id of customers. Not the id of admins. */
  userId?: Maybe<Scalars['String']>;
};

export type GetUserEntriesInput = {
  competitionId: Scalars['String'];
  userId: Scalars['String'];
};

/** Google Analytics report type */
export enum GoogleAnalyticsReportType {
  Device = 'DEVICE',
  TrafficSource = 'TRAFFIC_SOURCE'
}

export type GoogleAnalyticsReportingFilterInput = {
  eventId: Scalars['String'];
  type: GoogleAnalyticsReportType;
};

export type GrantCompetitionRewardInput = {
  actionType: Scalars['String'];
  competitionId: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  platform: Scalars['String'];
  userId: Scalars['String'];
};

export type GrantRewardOutput = {
  __typename?: 'GrantRewardOutput';
  actionType: Scalars['String'];
  platform: Scalars['String'];
  value: Scalars['Int'];
};

export type GraphDateItem = {
  __typename?: 'GraphDateItem';
  date: Scalars['DateTime'];
  items: Array<GraphItem>;
};

export enum GraphFilterType {
  AddOns = 'ADD_ONS',
  Memberships = 'MEMBERSHIPS',
  Overall = 'OVERALL',
  Package = 'PACKAGE',
  Tickets = 'TICKETS'
}

export type GraphItem = {
  __typename?: 'GraphItem';
  name: Scalars['String'];
  value: Scalars['Float'];
};

export type GraphOutput = {
  __typename?: 'GraphOutput';
  dates: Array<GraphDateItem>;
};

export type GraphRangeItem = {
  __typename?: 'GraphRangeItem';
  end: Scalars['String'];
  start: Scalars['String'];
  value: Scalars['Float'];
};

export type GraphTextItem = {
  __typename?: 'GraphTextItem';
  identifier: Scalars['String'];
  items: Array<GraphItem>;
};

/** Grey scale options. */
export enum GreyScale {
  Cool = 'Cool',
  Neutral = 'Neutral',
  Warm = 'Warm'
}

export type HandleInstagramCodeInput = {
  code: Scalars['String'];
  url: Scalars['String'];
};

export type HeardAboutUsBreakDownOutput = {
  __typename?: 'HeardAboutUsBreakDownOutput';
  count?: Maybe<Scalars['Float']>;
  heardAboutUs?: Maybe<Scalars['String']>;
};

/** Heard about us type */
export enum HeardAboutUsType {
  Billboard = 'Billboard',
  Email = 'Email',
  InternetSearch = 'InternetSearch',
  Other = 'Other',
  Radio = 'Radio',
  SocialMedia = 'SocialMedia',
  WordOfMouth = 'WordOfMouth'
}

export type HighestValueCustomersOutput = {
  __typename?: 'HighestValueCustomersOutput';
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Represents information about an history item. */
export type History = {
  __typename?: 'History';
  createdAt: Scalars['DateTime'];
  /** History item description. */
  description: Scalars['String'];
  id: Scalars['ID'];
  order?: Maybe<Order>;
  refName: Scalars['String'];
  scoutCommunication?: Maybe<Scout>;
  ticket?: Maybe<Ticket>;
  updatedAt: Scalars['DateTime'];
  /** The user who make this history, should get this value from @CurrentUser */
  user?: Maybe<User>;
};

export type HistoryConnection = {
  __typename?: 'HistoryConnection';
  edges: Array<HistoryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type HistoryEdge = {
  __typename?: 'HistoryEdge';
  cursor: Scalars['String'];
  node: History;
};

export type HistoryOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
};

/** The valid reference types for history items. */
export enum HistoryReferenceType {
  Competition = 'Competition',
  Order = 'Order',
  Scan = 'Scan',
  ScoutUser = 'ScoutUser',
  User = 'User'
}

export type HoldBestAvailableSeatsInput = {
  categories: Array<Scalars['Float']>;
  holdToken: Scalars['String'];
  number: Scalars['Float'];
  seatsEventId: Scalars['String'];
};

export type HoldBestAvailableSeatsOutput = {
  __typename?: 'HoldBestAvailableSeatsOutput';
  nextToEachOther: Scalars['Boolean'];
  objects: Array<Scalars['String']>;
};

export type ImportInput = {
  file: Scalars['Upload'];
};

export type ImportOutput = {
  __typename?: 'ImportOutput';
  /** Returns information about failed lines. */
  failed: Array<FailedImportItem>;
};

/** Import user item */
export type ImportUserItem = {
  __typename?: 'ImportUserItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  /** import user item status */
  status?: Maybe<ImportUserItemStatus>;
  updatedAt: Scalars['DateTime'];
};

export enum ImportUserItemStatus {
  Failed = 'Failed',
  Success = 'Success'
}

export type InitScout = {
  __typename?: 'InitScout';
  events: Array<Event>;
  membershipTypes: Array<MembershipType>;
  seasons: Array<Season>;
  userTags: Array<Tag>;
  zones: Array<Zone>;
};

/** Inside fee and range. */
export type InsideFeeDetails = {
  __typename?: 'InsideFeeDetails';
  insideFee: Scalars['Float'];
  priceRangeFrom: Scalars['Float'];
  priceRangeTo: Scalars['Float'];
};

/** Inside fee and range. */
export type InsideFeeDetailsInput = {
  insideFee: Scalars['Float'];
  priceRangeFrom: Scalars['Float'];
  priceRangeTo: Scalars['Float'];
};

export type InsightsOverviewOutput = {
  __typename?: 'InsightsOverviewOutput';
  addressableAudience?: Maybe<Scalars['Float']>;
  customerValue?: Maybe<Scalars['Float']>;
  customers?: Maybe<Scalars['Float']>;
  grossSales?: Maybe<Scalars['Float']>;
  newReferredCustomers?: Maybe<Scalars['Float']>;
  numberOfOrders?: Maybe<Scalars['Float']>;
  ordersPerUser?: Maybe<Scalars['Float']>;
  referralLinksCreated?: Maybe<Scalars['Float']>;
  returningCustomers?: Maybe<Scalars['Float']>;
  ticketsCount?: Maybe<Scalars['Float']>;
};

export type Integration = {
  __typename?: 'Integration';
  /** External secret key (should not be shared) */
  apiKey?: Maybe<Scalars['String']>;
  bnplEnabled?: Maybe<Scalars['Boolean']>;
  bnplTransactionFeePercent?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  facebookPageId?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Integration name */
  name: Scalars['String'];
  /** If payment gateways have different option types this field is used to specify which one to force */
  paymentMethodType?: Maybe<Scalars['String']>;
  /** External public key (can be used in customer frontend) */
  publicKey?: Maybe<Scalars['String']>;
  /** Internal API key (should not be shared) */
  secretKey?: Maybe<Scalars['String']>;
  /** Transaction fee. */
  transactionFee?: Maybe<Scalars['Float']>;
  /** Transaction fee(only for stripe). Users can input both fixed and percentage fee for stripe(0.3$+2%). */
  transactionFee2?: Maybe<Scalars['Float']>;
  /** Transaction fee type. */
  transactionFeeType?: Maybe<FeeType>;
  /** Transaction fee type(only for stripe). Users can input both fixed and percentage fee for stripe(0.3$+2%). */
  transactionFeeType2?: Maybe<FeeType>;
  /** Integration type */
  type: IntegrationType;
  updatedAt: Scalars['DateTime'];
  /** External public key for Windcave PxPost. */
  wcPxPostPublicKey?: Maybe<Scalars['String']>;
  /** External secret key for Windcave PxPost. */
  wcPxPostSecretKey?: Maybe<Scalars['String']>;
  /** Webhook URL to point integration to */
  webhookUrl?: Maybe<Scalars['String']>;
};

/** Integration group. */
export enum IntegrationGroup {
  Accounting = 'ACCOUNTING',
  Crm = 'CRM',
  Ecommerce = 'ECOMMERCE',
  Marketing = 'MARKETING',
  Payment = 'PAYMENT',
  Social = 'SOCIAL'
}

/** Integration type. */
export enum IntegrationType {
  AccountingXero = 'ACCOUNTING_XERO',
  CrmAdobe = 'CRM_ADOBE',
  CrmCustom = 'CRM_CUSTOM',
  CrmKore = 'CRM_KORE',
  CrmUssegment = 'CRM_USSEGMENT',
  EcommerceShopify = 'ECOMMERCE_SHOPIFY',
  MarketingDatorama = 'MARKETING_DATORAMA',
  MarketingMailchimp = 'MARKETING_MAILCHIMP',
  PaymentAfterpay = 'PAYMENT_AFTERPAY',
  PaymentLaybuy = 'PAYMENT_LAYBUY',
  PaymentMpaisa = 'PAYMENT_MPAISA',
  PaymentPin = 'PAYMENT_PIN',
  PaymentStripe = 'PAYMENT_STRIPE',
  PaymentWindcave = 'PAYMENT_WINDCAVE',
  ReportingShopify = 'REPORTING_SHOPIFY',
  SocialInstagram = 'SOCIAL_INSTAGRAM'
}

export type IntegrationWhereInput = {
  group?: Maybe<IntegrationGroup>;
};


export type JoinWaitlistInput = {
  email: Scalars['String'];
  eventId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  referralCode?: Maybe<Scalars['String']>;
  usableForMarketing: Scalars['Boolean'];
  userAcquisition?: Maybe<UserAcquisition>;
};

/** Landing page options. */
export enum LandingPage {
  MultiEvent = 'MultiEvent',
  SingleEvent = 'SingleEvent',
  Tours = 'Tours'
}

/** Order line items */
export type LineItem = {
  __typename?: 'LineItem';
  createdAt: Scalars['DateTime'];
  /** Event addon. Only available for addon line items. */
  eventAddon?: Maybe<EventAddon>;
  id: Scalars['ID'];
  isComp: Scalars['Boolean'];
  /** Membership addon. Only available for addon line items. */
  membershipAddon?: Maybe<MembershipAddon>;
  /** Membership type. Only available for membership type line items. */
  membershipType?: Maybe<MembershipType>;
  /** Item name. */
  name: Scalars['String'];
  /** Original price of the line item without any discounts applied. */
  originalPrice: Scalars['Float'];
  pointItem?: Maybe<PointItem>;
  /** Price of the line item with discounts applied. */
  price: Scalars['Float'];
  /** Item quantity. */
  quantity: Scalars['Float'];
  /** The release associated with the line item. Returns null if the line item is not bound to a release. */
  release?: Maybe<Release>;
  /** Seat label. Only available for ticket type line items. */
  seatLabel?: Maybe<Scalars['String']>;
  /** Seat section. Only available for ticket type line items. */
  seatSection?: Maybe<Scalars['String']>;
  seatSectionEntity?: Maybe<Section>;
  /** Seat tags. Only available for ticket type line items. */
  seatTags?: Maybe<Array<Scalars['String']>>;
  /** Seat type. Only available for seated events. */
  seatType?: Maybe<SeatType>;
  /** Seat zone. Only available for ticket type line items. */
  seatZone?: Maybe<Scalars['String']>;
  seatZoneEntity?: Maybe<Zone>;
  /** This is the stadium levy fee amount for the line item. */
  stadiumLevyFee?: Maybe<Scalars['Float']>;
  /** Subtotal: total - taxAmount */
  subtotal: Scalars['Float'];
  taxAmount: Scalars['Float'];
  /** Tax calculation method. either inclusive or exclusive. */
  taxCalculationMethod?: Maybe<TaxCalculationMethod>;
  /** Tax rate applied to the item. */
  taxRate: Scalars['Float'];
  /** Item ticket fee. */
  ticketFee?: Maybe<Scalars['Float']>;
  /** Calculated ticket fee amount (for 1 ticket) */
  ticketFeeAmount?: Maybe<Scalars['Float']>;
  /** Ticket fee type. */
  ticketFeeType?: Maybe<FeeType>;
  /** Ticket type. Only available for ticket type line items. */
  ticketType?: Maybe<TicketType>;
  /** Total amount: quantity * (price + ticketFee + levyFee) */
  total: Scalars['Float'];
  /** Total per unit amount: price + ticketFee + levyFee */
  totalPerUnit: Scalars['Float'];
  /** Item type. */
  type: LineItemType;
  updatedAt: Scalars['DateTime'];
};

/** Action to perform on line item */
export enum LineItemAction {
  Complete = 'COMPLETE',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

export type LineItemActionInput = {
  /** Action to perform on the line item. */
  action: LineItemAction;
  /** ID of the line item in the order. */
  id: Scalars['String'];
  /** New ticket or membership type ID (only relevant for `UPDATE`). */
  ticketOrMembershipTypeId?: Maybe<Scalars['String']>;
};

export type LineItemConnection = {
  __typename?: 'LineItemConnection';
  edges: Array<LineItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type LineItemEdge = {
  __typename?: 'LineItemEdge';
  cursor: Scalars['String'];
  node: LineItem;
};

export type LineItemInput = {
  /** Addon id. Only applicable for `addon` line items. */
  addonId?: Maybe<Scalars['String']>;
  /** Membership type. Only applicable for `membership` line items. */
  membershipTypeId?: Maybe<Scalars['String']>;
  pointItemId?: Maybe<Scalars['String']>;
  /** Previous ticket. Only applicable for seat changes and membership renewals */
  previousTicketId?: Maybe<Scalars['String']>;
  /** Price of the line item with discounts applied. Only used for ticket transfers. */
  price?: Maybe<Scalars['Float']>;
  /** Line item quantity. */
  quantity: Scalars['Float'];
  /** Seat label. Only applicable seated venues in combination with for `ticket` and `membership` line items. */
  seatLabel?: Maybe<Scalars['String']>;
  /** Seat section. Only available for ticket type line items. */
  seatSection?: Maybe<Scalars['String']>;
  /** Seat tags. Only available for ticket type line items. */
  seatTags?: Maybe<Array<Scalars['String']>>;
  seatType?: Maybe<Scalars['String']>;
  /** Seat zone. Only applicable to non-seated venues in combination for `ticket` and `membership` line items. */
  seatZone?: Maybe<Scalars['String']>;
  /** Ticket transfer id. Only applicable for `ticket` line items in a ticket transfer order. */
  ticketTransferId?: Maybe<Scalars['String']>;
  /** Ticket type. Only applicable for `ticket` line items. */
  ticketTypeId?: Maybe<Scalars['String']>;
  /** Item type. */
  type: LineItemType;
};

export type LineItemToCompleteInput = {
  /** ID of the line item in the order. */
  id: Scalars['String'];
  /** Quantity of tickets to keep on each line item. */
  quantity: Scalars['Int'];
};

export enum LineItemType {
  Addon = 'Addon',
  Membership = 'Membership',
  MembershipSingleEventTicket = 'MembershipSingleEventTicket',
  Point = 'Point',
  Ticket = 'Ticket'
}

export type LineItemsToCompleteInput = {
  /** Quantity of tickets to keep on each line item. */
  items: Array<LineItemToCompleteInput>;
  /** Selected payment method if order status is paid (allowed: cash, eftpos, invoice, other). */
  method: PaymentProvider;
  /** Will not send order confirmation email if set */
  skipOrderConfirmation: Scalars['Boolean'];
};

export type LinkedAccountRegisterInput = {
  email: Scalars['String'];
  newAccountPassword: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type LinkedAccountUser = {
  __typename?: 'LinkedAccountUser';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  hasVerifiedEmail: Scalars['Boolean'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  organizationSlugs: Array<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ListedReleaseOptionsInput = {
  askForDietaryRequirements?: Maybe<Scalars['Boolean']>;
  askHowDidYouHearAboutUs?: Maybe<Scalars['Boolean']>;
  endCondition?: Maybe<ReleaseEndCondition>;
  endConditionDate?: Maybe<Scalars['DateTime']>;
  endConditionQuantity?: Maybe<Scalars['Float']>;
  startCondition?: Maybe<ReleaseStartCondition>;
  startConditionDate?: Maybe<Scalars['DateTime']>;
};

export type LocationOutput = {
  __typename?: 'LocationOutput';
  amount?: Maybe<Scalars['Float']>;
  city: Scalars['String'];
  count: Scalars['String'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  posId?: Maybe<Scalars['String']>;
};

/** Organization marketing settings. */
export type Marketing = {
  __typename?: 'Marketing';
  /** Google Tag Manager ID */
  gtmId?: Maybe<Scalars['String']>;
  /** Facebook Pixel ID */
  pixelId?: Maybe<Scalars['String']>;
};

export type MarketingFiltersInput = {
  criteria?: Maybe<BroadcastFilterCriteria>;
  filterType: BroadcastFilterType;
  ids: Array<Scalars['String']>;
};

/** Organization marketing settings. */
export type MarketingInput = {
  /** Google Tag Manager ID */
  gtmId?: Maybe<Scalars['String']>;
  /** Facebook Pixel ID */
  pixelId?: Maybe<Scalars['String']>;
};

export type Membership = {
  __typename?: 'Membership';
  /** The accessibility info of the membership */
  accessibility?: Maybe<Scalars['String']>;
  addons: Array<MembershipAddon>;
  backgroundImage?: Maybe<ExtendedFile>;
  /** The banner associated with the membership. */
  banner?: Maybe<ExtendedFile>;
  /** The booking fee for the membership. */
  bookingFee: Scalars['Float'];
  /** The booking fee type of the membership. */
  bookingFeeType: FeeType;
  /** Whether naming a membership is available. */
  canChangeSeats: Scalars['Boolean'];
  /** Whether naming a membership is available. */
  canNameMembership: Scalars['Boolean'];
  /** Whether renaming a membership is available. */
  canRenameMembership: Scalars['Boolean'];
  canTransferTickets: Scalars['Boolean'];
  canUsePoints: Scalars['Boolean'];
  /** The fee for changing seats for the membership. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  /** The description of the membership. */
  description: Scalars['String'];
  descriptionImage?: Maybe<ExtendedFile>;
  digitalInfo?: Maybe<DigitalInfo>;
  digitalPassCodesStatus?: Maybe<DigitalPassCodeStatusOutput>;
  /** Email address that gets sent a notifcation every time an order is completed */
  emailNotification?: Maybe<Scalars['String']>;
  enableAutoRenew: Scalars['Boolean'];
  /** The end date of the membership. */
  endDate: Scalars['DateTime'];
  events?: Maybe<Array<Event>>;
  /** The payment gateways enabled for the event. */
  gateways?: Maybe<Array<Integration>>;
  hasPromoCodes: Scalars['Boolean'];
  /** Flag to determine if the membership is hidden from the public */
  hiddenFromPublic: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Important notice section on the tickets */
  importantNotice?: Maybe<Scalars['String']>;
  /** The accessibility info of the membership */
  invoiceInformation?: Maybe<Scalars['String']>;
  /** The active status of the membership. */
  isActive: Scalars['Boolean'];
  /** Flag to issue the value of the membership as credit on sale */
  issueCreditOnSale: Scalars['Boolean'];
  membershipHoldersCount?: Maybe<Scalars['Float']>;
  membershipKind: MembershipKind;
  membershipZones: Array<MembershipZone>;
  multiBuyPromotions: Array<MembershipMultiBuyPromotion>;
  /** Name of the membership. */
  name: Scalars['String'];
  previousMemberships: Array<Membership>;
  printBanner1?: Maybe<ExtendedFile>;
  printBanner2?: Maybe<ExtendedFile>;
  /** The referral campaign associated with this membership */
  referralCampaignId?: Maybe<Scalars['String']>;
  /** The renaming fee for the membership. */
  renamingFee?: Maybe<Scalars['Float']>;
  renewalMembership?: Maybe<Membership>;
  season?: Maybe<Season>;
  /** Shipping options of the membership. */
  shippingOptions?: Maybe<ShippingOptions>;
  /** Whether the schedule is shown on the customer portal */
  showSchedule?: Maybe<Scalars['Boolean']>;
  /** The start date of the membership. */
  startDate: Scalars['DateTime'];
  tags: Array<Tag>;
  /** The terms and conditions of the membership */
  termsAndConditions?: Maybe<Scalars['String']>;
  /** The thumbnail associated with the membership. */
  thumbnail?: Maybe<ExtendedFile>;
  ticketCover?: Maybe<MembershipTicketCover>;
  ticketSoldTime?: Maybe<TicketSoldTime>;
  types: Array<MembershipType>;
  updatedAt: Scalars['DateTime'];
  /** The venue associated with the membership. */
  venue?: Maybe<Venue>;
};

export type MembershipAddon = {
  __typename?: 'MembershipAddon';
  /** Image to be shown on the customer portal of the addon . Returns null if the not set. */
  addonImage?: Maybe<ExtendedFile>;
  createdAt: Scalars['DateTime'];
  /** The brief description of the addon. */
  description?: Maybe<Scalars['String']>;
  /** Indicates whether naming the addon is possible or not. */
  enableNaming: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The active status of the addon. */
  isActive: Scalars['Boolean'];
  /** The name of the addon. */
  name: Scalars['String'];
  namingFields: Array<FormField>;
  /** The price associated with the addon. */
  price: Scalars['Float'];
  /** Maximum quantity to be sold for the addon. */
  quantity: Scalars['Float'];
  /** The restrictions applied to the addon. */
  restrictions?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MembershipAutoRenewal = {
  __typename?: 'MembershipAutoRenewal';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  membership: Membership;
  membershipId: Scalars['String'];
  /** Mappings for previous memberships used by renewal process */
  membershipMappings: Array<MembershipRenewalMapping>;
  organizationId: Scalars['String'];
  reminderDate?: Maybe<Scalars['DateTime']>;
  renewalDate?: Maybe<Scalars['DateTime']>;
  /** Current step of the auto renewal process */
  renewalStep: MembershipAutoRenewalStep;
  updatedAt: Scalars['DateTime'];
};

export type MembershipAutoRenewalInput = {
  membershipId: Scalars['String'];
  membershipMappings: Array<MembershipRenewalMappingInput>;
  reminderDate?: Maybe<Scalars['DateTime']>;
  renewalDate: Scalars['DateTime'];
  /** Current step of the auto renewal process */
  renewalStep: MembershipAutoRenewalStep;
};

export type MembershipAutoRenewalOutput = {
  __typename?: 'MembershipAutoRenewalOutput';
  autoRenewal?: Maybe<MembershipAutoRenewal>;
};

export enum MembershipAutoRenewalStep {
  Reminded = 'Reminded',
  Renewed = 'Renewed',
  Scheduled = 'Scheduled'
}

export type MembershipConnection = {
  __typename?: 'MembershipConnection';
  edges: Array<MembershipEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type MembershipEdge = {
  __typename?: 'MembershipEdge';
  cursor: Scalars['String'];
  node: Membership;
};

export enum MembershipKind {
  Digital = 'Digital',
  Standard = 'Standard'
}

export type MembershipMultiBuyPromotion = {
  __typename?: 'MembershipMultiBuyPromotion';
  buyMembershipType?: Maybe<MembershipType>;
  buyQuantity: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  getMembershipType?: Maybe<MembershipType>;
  getQuantity: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  used: Scalars['Float'];
};

export type MembershipOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
  endDate?: Maybe<OrderByDirection>;
  startDate?: Maybe<OrderByDirection>;
  updatedAt?: Maybe<OrderByDirection>;
};

export type MembershipPromoCode = {
  __typename?: 'MembershipPromoCode';
  /** The unique code that represents the promotion. */
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The discount amount of the promotion. */
  discountAmount: Scalars['Float'];
  /** The discount type associated with the promotion. */
  discountType: DiscountType;
  enabled: Scalars['Boolean'];
  /** The end date condition of the promotion. Returns `null` when inactive. */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The maximum order amount condition of the promotion. Returns `null` when inactive. */
  maxOrderAmount?: Maybe<Scalars['Float']>;
  /** The maximum amount of use condition of the promotion. Returns `null` when inactive. */
  maxUsage?: Maybe<Scalars['Float']>;
  membershipTypes?: Maybe<Array<Scalars['String']>>;
  /** The minimum order amount condition of the promotion. Returns `null` when inactive. */
  minOrderAmount?: Maybe<Scalars['Float']>;
  /** The start date condition of the promotion. Returns `null` when inactive. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The type of the promotion which determines on which items the promotion will be applied. */
  type: MembershipPromoCodeType;
  updatedAt: Scalars['DateTime'];
  /** The total amount of usage of the promotion. */
  used?: Maybe<Scalars['Int']>;
};

export enum MembershipPromoCodeType {
  Addons = 'ADDONS',
  Membership = 'MEMBERSHIP',
  MembershipAndAddons = 'MEMBERSHIP_AND_ADDONS'
}

export type MembershipRenewalMapping = {
  __typename?: 'MembershipRenewalMapping';
  membershipId: Scalars['String'];
  membershipTypeMappings?: Maybe<Array<MembershipTypeMapping>>;
};

export type MembershipRenewalMappingInput = {
  membershipId: Scalars['String'];
  membershipTypeMappings?: Maybe<Array<MembershipTypeMappingInput>>;
};

/** Represents a membership type in a seating zone. */
export type MembershipSeatingZoneType = {
  __typename?: 'MembershipSeatingZoneType';
  /** The active status of the zone ticket. Defaults to `false`. */
  isActive: Scalars['Boolean'];
  /** The membership type id of the membership zone. */
  membershipTypeId: Scalars['String'];
  /** The price of the zone ticket. Returns null if `pricingLevel` of the parent is set to other than `zone`. */
  price?: Maybe<Scalars['Float']>;
  purchaseRestrictions: Array<PurchaseRestriction>;
  purchaseRestrictionsHelpText?: Maybe<Array<Scalars['String']>>;
  /** The sections of the zone ticket. Returns null if `pricingLevel` of the parent is set to other than `section`. */
  sections?: Maybe<Array<ZonePricingTypeSection>>;
};

export type MembershipSubscription = {
  __typename?: 'MembershipSubscription';
  /** Billing details of the subscription */
  billingDetails: MembershipSubscriptionBillingDetails;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Last order */
  lastOrder: Order;
  /** Last order id */
  lastOrderId: Scalars['String'];
  latestRenewalId?: Maybe<Scalars['String']>;
  /** Number of membership tickets */
  membershipTicketCount: Scalars['Float'];
  renewals: Array<MembershipSubscriptionRenewal>;
  stripeSubscriptionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** User id */
  userId: Scalars['String'];
};

export type MembershipSubscriptionBillingDetails = {
  __typename?: 'MembershipSubscriptionBillingDetails';
  cardBrand: Scalars['String'];
  cardExpiryMonth: Scalars['Float'];
  cardExpiryYear: Scalars['Float'];
  cardLast4: Scalars['String'];
  emailAddress: Scalars['String'];
};

export type MembershipSubscriptionPortalOutput = {
  __typename?: 'MembershipSubscriptionPortalOutput';
  url?: Maybe<Scalars['String']>;
};

export type MembershipSubscriptionRenewal = {
  __typename?: 'MembershipSubscriptionRenewal';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currentMembershipId: Scalars['String'];
  currentOrderId?: Maybe<Scalars['String']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failureReason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  membershipSubscriptionId: Scalars['String'];
  previousMembershipId: Scalars['String'];
  previousOrderId: Scalars['String'];
  remindedAt?: Maybe<Scalars['DateTime']>;
  /** Current state of the subscription */
  subscriptionState?: Maybe<MembershipSubscriptionState>;
  updatedAt: Scalars['DateTime'];
};

export enum MembershipSubscriptionState {
  Cancelled = 'Cancelled',
  Overdue = 'Overdue',
  Paid = 'Paid',
  Pending = 'Pending',
  Scheduled = 'Scheduled'
}

export type MembershipTicketCover = {
  __typename?: 'MembershipTicketCover';
  createdAt: Scalars['DateTime'];
  disabledAt?: Maybe<Scalars['DateTime']>;
  enabledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Is ticket cover enabled */
  isEnabled?: Maybe<Scalars['Boolean']>;
  /** Membership that the ticket cover is configured for */
  membership: Membership;
  /** Platform fee as percentage of ticket price */
  platformFeePercentage: Scalars['Float'];
  /** Promoter fee as percentage of ticket price */
  promoterFeePercentage: Scalars['Float'];
  /** Ticket cover purchase behaviour */
  purchaseBehaviour: TicketCoverPurchaseBehaviour;
  /** Total fee as percentage of ticket price */
  totalFeePercentage?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type MembershipTicketCoverInput = {
  /** Whether ticket cover is enabled */
  isEnabled: Scalars['Boolean'];
  /** Promoter fee as percentage of ticket price */
  promoterFeePercentage: Scalars['Float'];
  /** Ticket cover purchase behaviour */
  purchaseBehaviour: TicketCoverPurchaseBehaviour;
};

export type MembershipType = {
  __typename?: 'MembershipType';
  createdAt: Scalars['DateTime'];
  /** Restrictions for the membership type. */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isGuestCard: Scalars['Boolean'];
  /** The kind of the membership type. */
  kind: MembershipTypeKind;
  maxPurchaseQuantity?: Maybe<Scalars['Int']>;
  membership: Membership;
  minPurchaseQuantity?: Maybe<Scalars['Int']>;
  /** Name of the membership type. */
  name: Scalars['String'];
  namingFields: Array<FormField>;
  /** Restrictions for the membership type. */
  restrictions?: Maybe<Scalars['String']>;
  /** The ticket fee. */
  ticketFee: Scalars['Float'];
  /** The ticket fee type. */
  ticketFeeType: FeeType;
  updatedAt: Scalars['DateTime'];
};

export type MembershipTypeEventTransferPrice = {
  __typename?: 'MembershipTypeEventTransferPrice';
  createdAt: Scalars['DateTime'];
  event: Event;
  id: Scalars['ID'];
  maxSalePrice: Scalars['Float'];
  membership: Membership;
  membershipType: MembershipType;
  membershipZone: MembershipZone;
  updatedAt: Scalars['DateTime'];
};

/** Membership kind for membership type. */
export enum MembershipTypeKind {
  Addon = 'ADDON',
  ComplimentaryNamed = 'COMPLIMENTARY_NAMED',
  ComplimentaryUnnamed = 'COMPLIMENTARY_UNNAMED',
  Standard = 'STANDARD'
}

export type MembershipTypeMapping = {
  __typename?: 'MembershipTypeMapping';
  newMembershipTypeId: Scalars['String'];
  previousMembershipTypeId: Scalars['String'];
};

export type MembershipTypeMappingInput = {
  newMembershipTypeId: Scalars['String'];
  previousMembershipTypeId: Scalars['String'];
};

export type MembershipTypePurchaseTotal = {
  __typename?: 'MembershipTypePurchaseTotal';
  membershipType: MembershipType;
  total: Scalars['Int'];
};

export type MembershipTypeTransferPriceInput = {
  eventId: Scalars['String'];
  maxSalePrice: Scalars['Float'];
};

export type MembershipWhereInput = {
  /** Membership active status. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
};

export type MembershipZone = {
  __typename?: 'MembershipZone';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The active status of the zone. Defaults to `false`. */
  isActive: Scalars['Boolean'];
  /** The list of ticket types of the release zone. */
  membershipTypes: Array<MembershipSeatingZoneType>;
  /** The pricing level of the zone. Will be set to `zone` for non-seated events. */
  pricingLevel: ZonePricingLevel;
  updatedAt: Scalars['DateTime'];
  zone: Zone;
};

/** The zone belonging to a release zone */
export type MembershipZoneZoneInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Represents the overview of mentions. */
export type MentionOverview = {
  __typename?: 'MentionOverview';
  commentMentionCount: Scalars['Float'];
  mediaMentionCount: Scalars['Float'];
  storyMentionCount: Scalars['Float'];
};

export type MentionOverviewInput = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type MetaData = {
  __typename?: 'MetaData';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type MinTicketTypePricesForEventOutput = {
  __typename?: 'MinTicketTypePricesForEventOutput';
  price: Scalars['Float'];
  ticketTypeId: Scalars['String'];
};

export type MultiBuyPromotionUnion = EventMultiBuyPromotion | MembershipMultiBuyPromotion;

export type Mutation = {
  __typename?: 'Mutation';
  addForecastScenario: EventForecastOutput;
  addGatesToScannableGatesId: EventAddon;
  addGatesToZoneAndEvent: Event;
  addPosTransaction: Order;
  addReminderToScoutCommunicationUser: Scalars['Boolean'];
  addUnsubscribeReason: User;
  applyPromoCodeToOrder: Order;
  assignScoutCommunicationUsersToAgent: Scalars['Boolean'];
  assignTwoFactorAuthenticationSecret: TwoFactorAuthenticationSetupResult;
  authenticateWithTwoFactor: User;
  broadcastReportingLinkOpened: Scalars['Boolean'];
  broadcastReportingOrderCreated: Scalars['Boolean'];
  broadcastReportingSetAsSpamFromUser: Scalars['Boolean'];
  cancelOrder: Order;
  cancelPendingTicketTransfer: Scalars['Boolean'];
  claimTicketsForMembership: Array<ClaimTicketOutput>;
  clearReminderFromScoutCommunicationUser: Scalars['Boolean'];
  completePreprintTicketOrder: Order;
  completeShopifySetup: Scalars['Boolean'];
  createAutomation: Automation;
  createBulkOrders: Scalars['Boolean'];
  createCompetition: Competition;
  createEvent: Event;
  createEventAddon: EventAddon;
  createEventMultiBuyPromotion: EventMultiBuyPromotion;
  createEventPromoCode: EventPromoCode;
  createHistory: History;
  createIntegration: Integration;
  createMembership: Membership;
  createMembershipAddon: MembershipAddon;
  createMembershipMultiBuyPromotion: MembershipMultiBuyPromotion;
  createMembershipPromoCode: MembershipPromoCode;
  createMembershipType: MembershipType;
  createOrUpdateAccessCodeRelease: AccessCodeReleaseOutput;
  createOrUpdateMembershipAutoRenewal: MembershipAutoRenewal;
  createOrUpdatePresale: PresaleReleaseOutput;
  createOrder: OrderCreateOutput;
  createOrderToTrackingCodeAssociation: Scalars['Boolean'];
  createOrganization: CreateOrganizationOutput;
  createPayment: CreatePaymentOutput;
  createPinPaymentCharge: PinPaymentChargeResponse;
  createPoint: Point;
  createPointItem: PointItem;
  createPos: Pos;
  createPosUser: User;
  createPurchaseRestriction: PurchaseRestriction;
  createRedirectUrl: RedirectUrl;
  createReferralCampaign: ReferralCampaign;
  createReferralRewards: Array<ReferralReward>;
  createRefund: Refund;
  createRelease: Release;
  createReleaseZone: Array<Release>;
  createResaleTicketType: TicketType;
  createScout: Scout;
  createScoutCommunication: ScoutCommunication;
  createSeason: Season;
  createTag: Tag;
  createTemporaryPosUser: CreateTemporaryPosUserOutput;
  createTestEmail: Scalars['Boolean'];
  createTicketType: TicketType;
  createTrackingCode: Scalars['Boolean'];
  /** Creates a user. */
  createUser: User;
  createVenue: Venue;
  deleteAccessCode: AccessCodeReleaseOutput;
  deleteBroadcast: DeleteOneOutput;
  deleteCompetition: Scalars['Boolean'];
  deleteEventAddon: DeleteOneOutput;
  deleteEventMultiBuyPromotion: DeleteOneOutput;
  deleteEventPromoCode: DeleteOneOutput;
  deleteForecastScenario: EventForecastOutput;
  deleteMembershipAddon: DeleteOneOutput;
  deleteMembershipMultiBuyPromotion: DeleteOneOutput;
  deleteMembershipPromoCode: DeleteOneOutput;
  deleteMembershipType: DeleteOneOutput;
  deletePresale: Scalars['Boolean'];
  deletePurchaseRestriction: DeleteOneOutput;
  deleteRelease: DeleteOneOutput;
  deleteReleaseZone: Scalars['Boolean'];
  deleteResaleTicketType: Scalars['Boolean'];
  deleteScoutUsers: Scalars['Boolean'];
  /** Delete yourself. */
  deleteSelf: DeleteOneOutput;
  deleteTag: Scalars['Boolean'];
  deleteTicketType: DeleteOneOutput;
  deleteTrackingCode: Scalars['Boolean'];
  /** Deletes a user. */
  deleteUser: DeleteOneOutput;
  deleteVenue: DeleteOneOutput;
  duplicateEvent: Scalars['String'];
  duplicateMembership: Scalars['String'];
  duplicateSingleRelease: Release;
  enablePresale: Scalars['Boolean'];
  enableTwoFactorAuthentication: Scalars['Boolean'];
  enableXeroIntegration: Scalars['Boolean'];
  expireOrder: Order;
  finishPosTransaction: Order;
  getMagicLink: Scalars['String'];
  grantCompetitionReward?: Maybe<CompetitionReward>;
  handleInstagramCode: Scalars['String'];
  hideResaleTicketTypeFromPublic: Scalars['Boolean'];
  holdBestAvailableSeats: HoldBestAvailableSeatsOutput;
  holdCustomerOrder: Order;
  /** Sends invitations to existing users based on CSV file. */
  importAndInviteUsers: ImportOutput;
  importDigitalPassCodesForEvent: Scalars['Boolean'];
  importDigitalPassCodesForMembership: Scalars['Boolean'];
  importPurchasedMemberships: ImportOutput;
  importReleasePasswords: Scalars['Boolean'];
  importUsers: Scalars['Boolean'];
  increaseDownloadCount: Scalars['Boolean'];
  initiateLinkedAccountRegistration: Scalars['Boolean'];
  joinResaleWaitlist: Scalars['Boolean'];
  joinWaitlist: Waitlist;
  linkedAccountLogin: LinkedAccountUser;
  linkedAccountRegister: LinkedAccountUser;
  listTicketForResale: Scalars['Boolean'];
  login: User;
  logout: Scalars['Boolean'];
  onTrackingCodeVisit: Scalars['Boolean'];
  redeemTicket: ScanTicketOutput;
  register: Scalars['Boolean'];
  releaseClaimsForNextMembership: Array<Ticket>;
  releaseSeats: Order;
  removePosTransaction: Order;
  removePromoCodeFromOrder: Order;
  removeTwoFactorAuthenticationSecret: Scalars['Boolean'];
  reorderTicketTypes: ReleaseZone;
  reorderZones: Array<Zone>;
  requestDeleteAccount: Scalars['Boolean'];
  requestMagicLink: Scalars['Boolean'];
  requestResetPassword: Scalars['Boolean'];
  requestTicketTransfer: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  resubscribe: User;
  rollIntegrationApiKey: Integration;
  scanTicket: ScanTicketOutput;
  scanTicketBulk: Array<ScanTicketOutput>;
  scanVaccinePass: Scalars['Boolean'];
  sendBroadcast: Scalars['Boolean'];
  sendInternalOrderReceipt: SendOrderConfirmationOutput;
  sendMemberRenewalEmail: Scalars['Boolean'];
  sendOrderConfirmation: SendOrderConfirmationOutput;
  sendTwoFactorCodeViaSMSMessage: Scalars['Boolean'];
  /** Sends an invite to an existing user. */
  sendUserInvite: User;
  setPasswordForNewUser: Scalars['Boolean'];
  setPromoterSettings: PromoterSettingsOutput;
  setXeroConfiguration: XeroConfigurationOutput;
  socialAuth: User;
  splitGroupOrder: Order;
  splitHoldOrder: Order;
  syncTicketWithSeatsIo: Scalars['Boolean'];
  tagEntities: Array<Scalars['String']>;
  toggleMultiScanning: Scalars['Boolean'];
  unhideResaleTicketTypeFromPublic: Scalars['Boolean'];
  unlistTicketForResale: Scalars['Boolean'];
  unpublishCompetition: Competition;
  unsubscribeFromMarketing: User;
  updateAccessCode: AccessCodeOutput;
  updateAndPublishCompetition: Competition;
  updateAutomation: Automation;
  updateCompetition: Competition;
  updateCreditsForUser: User;
  updateEvent: Event;
  updateEventAddon: EventAddon;
  updateEventMultiBuyPromotion: EventMultiBuyPromotion;
  updateEventPromoCode: EventPromoCode;
  updateForecast: EventForecastOutput;
  updateHoldOrder: Order;
  updateIntegration: Integration;
  updateMembership: Membership;
  updateMembershipAddon: MembershipAddon;
  updateMembershipMultiBuyPromotion: MembershipMultiBuyPromotion;
  updateMembershipPromoCode: MembershipPromoCode;
  updateMembershipType: MembershipType;
  updateOrder: Order;
  updateOrderStatus: Order;
  updateOrganization: Organization;
  updateOrganizationById: Organization;
  updatePassword: Scalars['Boolean'];
  updatePoint: Point;
  updatePointItem: PointItem;
  updatePosUser: User;
  /** Updates the current user. */
  updateProfile: User;
  updatePurchaseRestriction: PurchaseRestriction;
  updateReferralCampaign: ReferralCampaign;
  updateRelease: Release;
  updateReleaseZone: ReleaseZone;
  updateResaleTicketType: TicketType;
  updateScout: Scout;
  updateScoutCommunication: ScoutCommunication;
  updateScoutCommunicationUser: ScoutCommunicationUser;
  updateScoutUsers: Scalars['Boolean'];
  updateSeason: Season;
  updateTag: Tag;
  updateTicketCoverForOrder: Order;
  updateTicketHolder: Ticket;
  updateTicketResaleConfig: Event;
  updateTicketType: TicketType;
  /** Updates a user. */
  updateUser: User;
  updateVenue: Venue;
  updateVenueCapacity: Scalars['Boolean'];
  /** Update zones based on seatsChartKey. */
  updateVenueZones: Venue;
  uploadImage: Scalars['JSON'];
  upsertAdobeIntegration: AdobeExperiencePlatformIntegration;
  upsertMembershipTypeEventTransferPrices: Array<MembershipTypeEventTransferPrice>;
  validateReleasePassword: ReleasePassword;
  validateReleasePasswordOrRelease: Release;
  verifyMagicLink: VerifyMagicLinkOutput;
  verifyPinPayment3DS: PinPaymentChargeResponse;
  verifyToken: VerifyMagicLinkOutput;
};


export type MutationAddForecastScenarioArgs = {
  id: Scalars['String'];
};


export type MutationAddGatesToScannableGatesIdArgs = {
  eventId: Scalars['String'];
  input: AddGatesToEventAddonInput;
};


export type MutationAddGatesToZoneAndEventArgs = {
  eventId: Scalars['String'];
  input: AddGatesToZoneInput;
};


export type MutationAddPosTransactionArgs = {
  id: Scalars['String'];
  input: AddPosTransactionInput;
};


export type MutationAddReminderToScoutCommunicationUserArgs = {
  id: Scalars['String'];
  input: AddReminderToScoutCommunicationUserInput;
};


export type MutationAddUnsubscribeReasonArgs = {
  input: AddUnsubscribeReasonInput;
};


export type MutationApplyPromoCodeToOrderArgs = {
  id: Scalars['String'];
  input: ApplyPromoCode;
};


export type MutationAssignScoutCommunicationUsersToAgentArgs = {
  input: AssignScoutCommunicationUsersToAgentInput;
};


export type MutationAuthenticateWithTwoFactorArgs = {
  input: TwoFactorAuthenticationCodeInput;
};


export type MutationBroadcastReportingLinkOpenedArgs = {
  input: BroadcastReportingOpenInput;
};


export type MutationBroadcastReportingOrderCreatedArgs = {
  input: BroadcastReportingOrderInput;
};


export type MutationBroadcastReportingSetAsSpamFromUserArgs = {
  input: BroadcastReportingSpamInput;
};


export type MutationCancelOrderArgs = {
  id: Scalars['String'];
};


export type MutationCancelPendingTicketTransferArgs = {
  ticketId: Scalars['String'];
};


export type MutationClaimTicketsForMembershipArgs = {
  membershipId: Scalars['String'];
};


export type MutationClearReminderFromScoutCommunicationUserArgs = {
  id: Scalars['String'];
};


export type MutationCompletePreprintTicketOrderArgs = {
  id: Scalars['String'];
  input: LineItemsToCompleteInput;
};


export type MutationCompleteShopifySetupArgs = {
  input: ShopifySetupInput;
};


export type MutationCreateAutomationArgs = {
  input: CreateAutomationInput;
};


export type MutationCreateBulkOrdersArgs = {
  createdById: Scalars['String'];
  input: ImportInput;
};


export type MutationCreateCompetitionArgs = {
  eventId: Scalars['String'];
  input: CreateCompetitionInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateEventAddonArgs = {
  input: CreateEventAddonInput;
};


export type MutationCreateEventMultiBuyPromotionArgs = {
  input: CreateEventMultiBuyPromotionInput;
};


export type MutationCreateEventPromoCodeArgs = {
  input: CreateEventPromoCodeInput;
};


export type MutationCreateHistoryArgs = {
  input: CreateHistoryInput;
};


export type MutationCreateIntegrationArgs = {
  input: CreateIntegrationInput;
};


export type MutationCreateMembershipArgs = {
  input: CreateMembershipInput;
};


export type MutationCreateMembershipAddonArgs = {
  input: CreateMembershipAddonInput;
};


export type MutationCreateMembershipMultiBuyPromotionArgs = {
  input: CreateMembershipMultiBuyPromotionInput;
};


export type MutationCreateMembershipPromoCodeArgs = {
  input: CreateMembershipPromoCodeInput;
};


export type MutationCreateMembershipTypeArgs = {
  input: CreateMembershipTypeInput;
};


export type MutationCreateOrUpdateAccessCodeReleaseArgs = {
  input: AccessCodeReleaseInput;
};


export type MutationCreateOrUpdateMembershipAutoRenewalArgs = {
  input: MembershipAutoRenewalInput;
};


export type MutationCreateOrUpdatePresaleArgs = {
  input: PresaleReleaseInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCreateOrderToTrackingCodeAssociationArgs = {
  input: TrackingCodeOrderInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreatePaymentArgs = {
  id: Scalars['String'];
  input: CreatePaymentInput;
};


export type MutationCreatePinPaymentChargeArgs = {
  input: CreatePinPaymentCharge;
};


export type MutationCreatePointArgs = {
  input: CreatePointInput;
};


export type MutationCreatePointItemArgs = {
  input: CreatePointItemInput;
};


export type MutationCreatePosArgs = {
  input: CreatePosInput;
};


export type MutationCreatePosUserArgs = {
  input: CreatePosUserInput;
};


export type MutationCreatePurchaseRestrictionArgs = {
  input: CreatePurchaseRestrictionInput;
};


export type MutationCreateRedirectUrlArgs = {
  input: CreateRedirectUrlInput;
};


export type MutationCreateReferralCampaignArgs = {
  campaign: CreateReferralCampaignDto;
};


export type MutationCreateReferralRewardsArgs = {
  rewards: CreateReferralRewardsDto;
};


export type MutationCreateRefundArgs = {
  input: CreateRefundInput;
};


export type MutationCreateReleaseArgs = {
  input: CreateReleaseInput;
};


export type MutationCreateReleaseZoneArgs = {
  input: CreateReleaseZoneInput;
};


export type MutationCreateResaleTicketTypeArgs = {
  input: CreateResaleTicketTypeInput;
};


export type MutationCreateScoutArgs = {
  input: CreateScoutInput;
};


export type MutationCreateScoutCommunicationArgs = {
  input: CreateScoutCommunicationInput;
};


export type MutationCreateSeasonArgs = {
  input: CreateSeasonInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateTemporaryPosUserArgs = {
  input: CreateTemporaryPosUserInput;
};


export type MutationCreateTestEmailArgs = {
  input: CreateTestEmailInput;
};


export type MutationCreateTicketTypeArgs = {
  input: CreateTicketTypeInput;
};


export type MutationCreateTrackingCodeArgs = {
  input: TrackingCodeCreateInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
  sendInvite: Scalars['Boolean'];
};


export type MutationCreateVenueArgs = {
  input: CreateVenueInput;
};


export type MutationDeleteAccessCodeArgs = {
  accessCode?: Maybe<Scalars['String']>;
  releaseId: Scalars['String'];
};


export type MutationDeleteBroadcastArgs = {
  id: Scalars['String'];
};


export type MutationDeleteCompetitionArgs = {
  competitionId: Scalars['String'];
};


export type MutationDeleteEventAddonArgs = {
  id: Scalars['String'];
};


export type MutationDeleteEventMultiBuyPromotionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteEventPromoCodeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteForecastScenarioArgs = {
  forecastId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDeleteMembershipAddonArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMembershipMultiBuyPromotionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMembershipPromoCodeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMembershipTypeArgs = {
  id: Scalars['String'];
};


export type MutationDeletePresaleArgs = {
  releaseId: Scalars['String'];
};


export type MutationDeletePurchaseRestrictionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteReleaseArgs = {
  id: Scalars['String'];
};


export type MutationDeleteReleaseZoneArgs = {
  id: Scalars['String'];
};


export type MutationDeleteResaleTicketTypeArgs = {
  resaleTicketTypeId: Scalars['String'];
};


export type MutationDeleteScoutUsersArgs = {
  input: DeleteScoutUserInput;
};


export type MutationDeleteTagArgs = {
  entityId: Scalars['String'];
  tagId: Scalars['String'];
};


export type MutationDeleteTicketTypeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTrackingCodeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationDeleteVenueArgs = {
  id: Scalars['String'];
};


export type MutationDuplicateEventArgs = {
  id: Scalars['String'];
};


export type MutationDuplicateMembershipArgs = {
  id: Scalars['String'];
};


export type MutationDuplicateSingleReleaseArgs = {
  input: DuplicateSingleReleaseInput;
};


export type MutationEnablePresaleArgs = {
  isActive: Scalars['Boolean'];
  releaseId: Scalars['String'];
};


export type MutationEnableTwoFactorAuthenticationArgs = {
  input: TwoFactorAuthenticationCodeInput;
};


export type MutationEnableXeroIntegrationArgs = {
  input: XeroCallbackInput;
};


export type MutationExpireOrderArgs = {
  id: Scalars['String'];
};


export type MutationFinishPosTransactionArgs = {
  autoScan?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationGetMagicLinkArgs = {
  input: RequestMagicLinkInput;
};


export type MutationGrantCompetitionRewardArgs = {
  input: GrantCompetitionRewardInput;
};


export type MutationHandleInstagramCodeArgs = {
  input: HandleInstagramCodeInput;
};


export type MutationHideResaleTicketTypeFromPublicArgs = {
  resaleTicketTypeId: Scalars['String'];
};


export type MutationHoldBestAvailableSeatsArgs = {
  input: HoldBestAvailableSeatsInput;
};


export type MutationHoldCustomerOrderArgs = {
  id: Scalars['String'];
};


export type MutationImportAndInviteUsersArgs = {
  input: ImportInput;
};


export type MutationImportDigitalPassCodesForEventArgs = {
  eventId: Scalars['String'];
  input: ImportInput;
};


export type MutationImportDigitalPassCodesForMembershipArgs = {
  input: ImportInput;
  membershipId: Scalars['String'];
};


export type MutationImportPurchasedMembershipsArgs = {
  input: ImportInput;
};


export type MutationImportReleasePasswordsArgs = {
  id: Scalars['String'];
  input: ImportInput;
};


export type MutationImportUsersArgs = {
  input: ImportInput;
};


export type MutationIncreaseDownloadCountArgs = {
  id: Scalars['String'];
};


export type MutationInitiateLinkedAccountRegistrationArgs = {
  input: LoginInput;
};


export type MutationJoinResaleWaitlistArgs = {
  input: ResaleWaitlistInput;
};


export type MutationJoinWaitlistArgs = {
  input: JoinWaitlistInput;
};


export type MutationLinkedAccountLoginArgs = {
  input: LoginInput;
};


export type MutationLinkedAccountRegisterArgs = {
  input: LinkedAccountRegisterInput;
};


export type MutationListTicketForResaleArgs = {
  ticketId: Scalars['String'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationOnTrackingCodeVisitArgs = {
  input: TrackingCodeVisitInput;
};


export type MutationRedeemTicketArgs = {
  input: RedeemTicketInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationReleaseClaimsForNextMembershipArgs = {
  ids: Scalars['String'];
};


export type MutationReleaseSeatsArgs = {
  input: ReleaseSeatsInput;
};


export type MutationRemovePosTransactionArgs = {
  id: Scalars['String'];
  posTransactionId: Scalars['String'];
};


export type MutationRemovePromoCodeFromOrderArgs = {
  id: Scalars['String'];
};


export type MutationReorderTicketTypesArgs = {
  input: ReorderTicketTypeInput;
};


export type MutationReorderZonesArgs = {
  input: ReorderZoneInput;
};


export type MutationRequestDeleteAccountArgs = {
  input: RequestMagicLinkInput;
};


export type MutationRequestMagicLinkArgs = {
  input: RequestMagicLinkInput;
};


export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordInput;
};


export type MutationRequestTicketTransferArgs = {
  input: RequestTicketTransferInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResubscribeArgs = {
  userId: Scalars['String'];
};


export type MutationRollIntegrationApiKeyArgs = {
  id: Scalars['String'];
};


export type MutationScanTicketArgs = {
  eventId: Scalars['String'];
  input: ScanTicketInput;
};


export type MutationScanTicketBulkArgs = {
  input: ScanTicketBulkInput;
};


export type MutationScanVaccinePassArgs = {
  payload: Scalars['String'];
  ticketId: Scalars['String'];
};


export type MutationSendBroadcastArgs = {
  input: SendBroadcastInput;
};


export type MutationSendInternalOrderReceiptArgs = {
  id: Scalars['String'];
};


export type MutationSendMemberRenewalEmailArgs = {
  id: Scalars['String'];
};


export type MutationSendOrderConfirmationArgs = {
  id: Scalars['String'];
  input: SendOrderConfirmationInput;
};


export type MutationSendUserInviteArgs = {
  id: Scalars['String'];
};


export type MutationSetPasswordForNewUserArgs = {
  input: SetPasswordForNewUserInput;
};


export type MutationSetPromoterSettingsArgs = {
  input: PromoterSettingsInput;
};


export type MutationSetXeroConfigurationArgs = {
  input: XeroConfigurationInput;
};


export type MutationSocialAuthArgs = {
  input: SocialAuthInput;
};


export type MutationSplitGroupOrderArgs = {
  id: Scalars['String'];
  input: SplitOrderInput;
};


export type MutationSplitHoldOrderArgs = {
  id: Scalars['String'];
  input: SplitOrderInput;
};


export type MutationSyncTicketWithSeatsIoArgs = {
  ticketId: Scalars['String'];
};


export type MutationTagEntitiesArgs = {
  input: TagEntitiesInput;
};


export type MutationToggleMultiScanningArgs = {
  eventId: Scalars['String'];
  multiScanningEnabled: Scalars['Boolean'];
};


export type MutationUnhideResaleTicketTypeFromPublicArgs = {
  resaleTicketTypeId: Scalars['String'];
};


export type MutationUnlistTicketForResaleArgs = {
  ticketId: Scalars['String'];
};


export type MutationUnpublishCompetitionArgs = {
  competitionId: Scalars['String'];
};


export type MutationUnsubscribeFromMarketingArgs = {
  userId: Scalars['String'];
};


export type MutationUpdateAccessCodeArgs = {
  input: AccessCodeInput;
  releaseId: Scalars['String'];
};


export type MutationUpdateAndPublishCompetitionArgs = {
  eventId: Scalars['String'];
  input: PublishCompetitionInput;
};


export type MutationUpdateAutomationArgs = {
  id: Scalars['String'];
  input: UpdateAutomationInput;
};


export type MutationUpdateCompetitionArgs = {
  competitionId: Scalars['String'];
  input: CreateCompetitionInput;
};


export type MutationUpdateCreditsForUserArgs = {
  input: UpdateCreditsInput;
  userId: Scalars['String'];
};


export type MutationUpdateEventArgs = {
  id: Scalars['String'];
  input: UpdateEventInput;
  sendTicketViewableEmails?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateEventAddonArgs = {
  id: Scalars['String'];
  input: UpdateEventAddonInput;
};


export type MutationUpdateEventMultiBuyPromotionArgs = {
  id: Scalars['String'];
  input: UpdateEventMultiBuyPromotionInput;
};


export type MutationUpdateEventPromoCodeArgs = {
  id: Scalars['String'];
  input: UpdateEventPromoCodeInput;
};


export type MutationUpdateForecastArgs = {
  id: Scalars['String'];
  input: UpdateForecastInput;
};


export type MutationUpdateHoldOrderArgs = {
  id: Scalars['String'];
  input: UpdateHoldOrderInput;
};


export type MutationUpdateIntegrationArgs = {
  id: Scalars['String'];
  input: UpdateIntegrationInput;
};


export type MutationUpdateMembershipArgs = {
  id: Scalars['String'];
  input: UpdateMembershipInput;
};


export type MutationUpdateMembershipAddonArgs = {
  id: Scalars['String'];
  input: UpdateMembershipAddonInput;
};


export type MutationUpdateMembershipMultiBuyPromotionArgs = {
  id: Scalars['String'];
  input: UpdateMembershipMultiBuyPromotionInput;
};


export type MutationUpdateMembershipPromoCodeArgs = {
  id: Scalars['String'];
  input: UpdateMembershipPromoCodeInput;
};


export type MutationUpdateMembershipTypeArgs = {
  id: Scalars['String'];
  input: UpdateMembershipTypeInput;
};


export type MutationUpdateOrderArgs = {
  id: Scalars['String'];
  input: UpdateOrderInput;
};


export type MutationUpdateOrderStatusArgs = {
  id: Scalars['String'];
  input: UpdateOrderStatusInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdateOrganizationByIdArgs = {
  id: Scalars['String'];
  input: UpdateOrganizationInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationUpdatePointArgs = {
  input: UpdatePointInput;
};


export type MutationUpdatePointItemArgs = {
  id: Scalars['String'];
  input: UpdatePointItemInput;
};


export type MutationUpdatePosUserArgs = {
  id: Scalars['String'];
  input: UpdatePosUserInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdatePurchaseRestrictionArgs = {
  id: Scalars['String'];
  input: CreatePurchaseRestrictionInput;
};


export type MutationUpdateReferralCampaignArgs = {
  campaign: UpdateReferralCampaignDto;
};


export type MutationUpdateReleaseArgs = {
  id: Scalars['String'];
  input: UpdateReleaseInput;
};


export type MutationUpdateReleaseZoneArgs = {
  id: Scalars['String'];
  input: UpdateReleaseZoneInput;
};


export type MutationUpdateResaleTicketTypeArgs = {
  input: CreateResaleTicketTypeInput;
  resaleTicketTypeId: Scalars['String'];
};


export type MutationUpdateScoutArgs = {
  id: Scalars['String'];
  input: UpdateScoutInput;
};


export type MutationUpdateScoutCommunicationArgs = {
  id: Scalars['String'];
  input: UpdateScoutCommunicationInput;
};


export type MutationUpdateScoutCommunicationUserArgs = {
  id: Scalars['String'];
  input: UpdateScoutCommunicationUserInput;
};


export type MutationUpdateScoutUsersArgs = {
  id: Scalars['String'];
};


export type MutationUpdateSeasonArgs = {
  id: Scalars['String'];
  input: UpdateSeasonInput;
};


export type MutationUpdateTagArgs = {
  id: Scalars['String'];
  input: UpdateTagInput;
};


export type MutationUpdateTicketCoverForOrderArgs = {
  id: Scalars['String'];
  input: UpdateOrderTicketCoverInput;
};


export type MutationUpdateTicketHolderArgs = {
  id: Scalars['String'];
  input: UpdateTicketHolderInput;
};


export type MutationUpdateTicketResaleConfigArgs = {
  input: UpdateResaleConfigInput;
};


export type MutationUpdateTicketTypeArgs = {
  id: Scalars['String'];
  input: UpdateTicketTypeInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String'];
  input: UpdateUserInput;
};


export type MutationUpdateVenueArgs = {
  id: Scalars['String'];
  input: UpdateVenueInput;
};


export type MutationUpdateVenueCapacityArgs = {
  capacity?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
};


export type MutationUpdateVenueZonesArgs = {
  id: Scalars['String'];
};


export type MutationUploadImageArgs = {
  file: ImportInput;
};


export type MutationUpsertAdobeIntegrationArgs = {
  input: AdobeExperiencePlatformIntegrationInput;
};


export type MutationUpsertMembershipTypeEventTransferPricesArgs = {
  id: Scalars['String'];
  input: Array<UpdateMembershipTypeEventTransferPriceInput>;
};


export type MutationValidateReleasePasswordArgs = {
  id: Scalars['String'];
  password: Scalars['String'];
};


export type MutationValidateReleasePasswordOrReleaseArgs = {
  eventId: Scalars['String'];
  password: Scalars['String'];
};


export type MutationVerifyMagicLinkArgs = {
  input: VerifyEmailInput;
};


export type MutationVerifyPinPayment3DsArgs = {
  input: VerifyPinPayment3Ds;
};


export type MutationVerifyTokenArgs = {
  input: VerifyEmailInput;
};

export type MyEventsOrderByInput = {
  startDate?: Maybe<OrderByDirection>;
};

export type MyEventsWhereInput = {
  /** Event end date. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Filter on tickets that are (not) related to a membership ticket. */
  hasParentTicket?: Maybe<Scalars['Boolean']>;
  /** Event start date. */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type MyLoggedInOrganizationsOutput = {
  __typename?: 'MyLoggedInOrganizationsOutput';
  id: Scalars['String'];
  isLinked: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

/** Order type */
export type Order = {
  __typename?: 'Order';
  amountDue: Scalars['Float'];
  /** Billing address provided by the customer. */
  billingAddress?: Maybe<Address>;
  /** Booking fee (amount or percentage based on type). */
  bookingFee: Scalars['Float'];
  /** Calculated booking fee. */
  bookingFeeAmount: Scalars['Float'];
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** Buyer details. */
  buyerInformation?: Maybe<BuyerInformation>;
  /** Amount of credits detucted from the total for changing the seats */
  changingSeatsCredits?: Maybe<Scalars['Float']>;
  /** Fee for changing seats. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** Channel associated with the order. */
  channel: OrderChannel;
  /** The ip address of the client that is associated with this order. */
  clientIp: Scalars['String'];
  compReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Order is created by */
  createdBy?: Maybe<User>;
  /** The order number. */
  currency: Scalars['String'];
  /** Delivery fee (based on delivery method). */
  deliveryFee?: Maybe<Scalars['Float']>;
  /** The delivery method chosen by the customer. */
  deliveryMethod?: Maybe<ShippingOption>;
  /** How the customer heard about the items they are purchasing */
  dietaryRequirements?: Maybe<Scalars['String']>;
  /** The event associated with the order. Returns null if the order is not bound to an event. */
  event?: Maybe<Event>;
  /** Time after which the order will be automatically expired */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The payment provider which processed the last transaction. */
  gateway?: Maybe<PaymentIntegrationOutput>;
  /** Whether the hardcopy tickets have been printed for an order */
  hardCopyTicketsPrinted: Scalars['Boolean'];
  /** How the customer heard about the items they are purchasing */
  heardAboutUs?: Maybe<HeardAboutUsType>;
  /** Heard about us details when heardAboutUs is other */
  heardAboutUsDetails?: Maybe<Scalars['String']>;
  history: HistoryConnection;
  id: Scalars['ID'];
  /** Indicates if the order is a full comp order (all tickets are comps). */
  isComp: Scalars['Boolean'];
  /** If the order is created from order abandoned email. */
  isCreatedFromRecoveredCart: Scalars['Boolean'];
  /** Indicates if the order has been handled on success (e.g. ticket creation). */
  isHandled: Scalars['Boolean'];
  /** List of the order's line items. */
  lineItems: LineItemConnection;
  /** The membership associated with the order. Returns null if the order is not bound to a membership. */
  membership?: Maybe<Membership>;
  membershipSubscriptionId?: Maybe<Scalars['String']>;
  multiBuyPromotion?: Maybe<MultiBuyPromotionUnion>;
  optInForMarketingPromotions?: Maybe<Scalars['Boolean']>;
  /** Timestamp when order confirmation has been sent. */
  orderConfirmationSentAt?: Maybe<Scalars['DateTime']>;
  /** The order number. */
  orderNumber: Scalars['String'];
  orderTicketCover?: Maybe<OrderTicketCover>;
  /** Order type. */
  orderType: OrderType;
  /** Indicated whether the order has been paid with a subscription. */
  paidWithSubscription: Scalars['Boolean'];
  /** Last used payment gateway. */
  paymentGateway?: Maybe<PaymentProvider>;
  point?: Maybe<Point>;
  pos?: Maybe<Pos>;
  posTransactions: PosTransactionConnection;
  promoCode?: Maybe<PromoCodeUnion>;
  /** Used to link an order to the referral code that was used to create the order. Currently only used for competitions but will eventually be used for all referrals. */
  referralCode?: Maybe<ReferralCode>;
  /** Amount of referral discount applied to the order. Used when there is a flat amount applied to the order */
  referralDiscountAmount?: Maybe<Scalars['Float']>;
  /** Used to link an order to the referrer user that refered the order creator */
  referralId?: Maybe<Scalars['String']>;
  /** Total amount of refunds. */
  refundedAmount: Scalars['Float'];
  refunds: Array<Refund>;
  /** The release associated with the order. Returns null if the order is not bound to a release. */
  release?: Maybe<Release>;
  /** The release password associated with the order. Returns null if the order is not bound to a release password. */
  releasePassword?: Maybe<ReleasePassword>;
  /** Shipping address provided by the customer. */
  shippingAddress?: Maybe<Address>;
  /** Whether the shipping address matches the billing address. */
  shippingAddressMatchesBillingAddress: Scalars['Boolean'];
  /** Current status of the order. */
  status: OrderStatus;
  /** Subtotal: total - taxAmount */
  subtotal: Scalars['Float'];
  /** Tax calculation method. either inclusive or exclusive. */
  taxCalculationMethod?: Maybe<TaxCalculationMethod>;
  taxRate?: Maybe<Scalars['Float']>;
  /** The created tickets for the order. Only returns the membership tickets (including add-on tickets) for memberships. */
  tickets: Array<Ticket>;
  /** Order total (including fees and taxes). */
  total: Scalars['Float'];
  /** Tax rate: taxRate * total */
  totalTax: Scalars['Float'];
  /** Transaction fee. */
  transactionFee?: Maybe<Scalars['Float']>;
  /** Transaction fee. */
  transactionFee2?: Maybe<Scalars['Float']>;
  /** Calculated transaction fee. */
  transactionFeeAmount?: Maybe<Scalars['Float']>;
  /** Transaction fee type. */
  transactionFeeType?: Maybe<FeeType>;
  /** Transaction fee type. */
  transactionFeeType2?: Maybe<FeeType>;
  /** The status of the latest transaction status. */
  transactionStatus?: Maybe<OrderTransactionStatus>;
  transactions: OrderTransactionConnection;
  /** Fee charged for ticket transfer. */
  transferFee?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  /** Amount of credits used in the order */
  usedCredits?: Maybe<Scalars['Float']>;
  usedPoints: Scalars['Float'];
  /** Order owner. Not all orders have users. */
  user?: Maybe<User>;
  /** Xero invoice ID if Xero integration is configured and an invoice was created for this order */
  xeroInvoiceId?: Maybe<Scalars['String']>;
};


/** Order type */
export type OrderHistoryArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<HistoryOrderByInput>;
};


/** Order type */
export type OrderLineItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Order type */
export type OrderPosTransactionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PosTransactionOrderByInput>;
};


/** Order type */
export type OrderTransactionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TransactionOrderByInput>;
};

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Order status */
export enum OrderChannel {
  BackOffice = 'BackOffice',
  Online = 'Online',
  Pos = 'POS'
}

export type OrderConnection = {
  __typename?: 'OrderConnection';
  edges: Array<OrderEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type OrderCreateOutput = {
  __typename?: 'OrderCreateOutput';
  jobId: Scalars['String'];
  order?: Maybe<Order>;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor: Scalars['String'];
  node: Order;
};

export type OrderIdsOnlineOutput = {
  __typename?: 'OrderIdsOnlineOutput';
  orderId: Scalars['String'];
};

export type OrderOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
  updatedAt?: Maybe<OrderByDirection>;
};

export type OrderRefundDetailsOutput = {
  __typename?: 'OrderRefundDetailsOutput';
  addons: Scalars['Float'];
  baseTickets: Scalars['Float'];
  bookingFees: Scalars['Float'];
  creditsUsed: Scalars['Float'];
  deliveryFees: Scalars['Float'];
  orderId: Scalars['String'];
  refundedAmount: Scalars['Float'];
  ticketFees: Scalars['Float'];
  transactionFees: Scalars['Float'];
};

/** Order status */
export enum OrderStatus {
  Canceled = 'Canceled',
  Completed = 'Completed',
  Expired = 'Expired',
  Hold = 'Hold',
  Paid = 'Paid',
  Pending = 'Pending'
}

export type OrderTicketCover = {
  __typename?: 'OrderTicketCover';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  issuedAt?: Maybe<Scalars['DateTime']>;
  /** Order that the ticket cover is purchased for */
  order: Order;
  platformFeeAmount: Scalars['Float'];
  promoterFeeAmount: Scalars['Float'];
  /** Ticket cover purchase behaviour */
  purchaseBehaviour?: Maybe<TicketCoverPurchaseBehaviour>;
  purchasedAt?: Maybe<Scalars['DateTime']>;
  retryCount: Scalars['Float'];
  ticketTicketCovers?: Maybe<Array<TicketTicketCover>>;
  totalFeeAmount?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

/** A transaction represents an exchange of money as part of an order. */
export type OrderTransaction = {
  __typename?: 'OrderTransaction';
  /** The amount of the transaction. */
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The kind of the transaction. */
  kind: OrderTransactionKind;
  /** The amount of the transaction that is held for Flicket at the point of sale as a platform commission. */
  platformAdjustment: Scalars['Float'];
  /** The status of the transaction. */
  status: OrderTransactionStatus;
  updatedAt: Scalars['DateTime'];
};

export type OrderTransactionConnection = {
  __typename?: 'OrderTransactionConnection';
  edges: Array<OrderTransactionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type OrderTransactionEdge = {
  __typename?: 'OrderTransactionEdge';
  cursor: Scalars['String'];
  node: OrderTransaction;
};

/** The different kinds of order transactions. */
export enum OrderTransactionKind {
  Refund = 'Refund',
  Sale = 'Sale'
}

/** Payment statuses */
export enum OrderTransactionStatus {
  Cancelled = 'Cancelled',
  Error = 'Error',
  Failure = 'Failure',
  Pending = 'Pending',
  Success = 'Success',
  Unknown = 'Unknown',
  Waiting = 'Waiting'
}

/** Order type */
export enum OrderType {
  ChangeSeats = 'CHANGE_SEATS',
  Default = 'DEFAULT',
  MembershipTicketTransfer = 'MEMBERSHIP_TICKET_TRANSFER',
  PointPurchase = 'POINT_PURCHASE',
  RenameTickets = 'RENAME_TICKETS',
  RenewMemberships = 'RENEW_MEMBERSHIPS',
  ResaleTicketTransfer = 'RESALE_TICKET_TRANSFER',
  TicketTransfer = 'TICKET_TRANSFER'
}

export type OrderWhereInput = {
  channel?: Maybe<OrderChannel>;
  createdById?: Maybe<Scalars['String']>;
  dates?: Maybe<Array<Scalars['DateTime']>>;
  endDate?: Maybe<Scalars['DateTime']>;
  gateway?: Maybe<PaymentProvider>;
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
  release?: Maybe<Scalars['String']>;
  releaseId?: Maybe<Scalars['String']>;
  source?: Maybe<PointReportingFilterSource>;
  sourceId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<OrderStatus>>;
};

export type Organization = {
  __typename?: 'Organization';
  _taxRate?: Maybe<Scalars['Float']>;
  /** Address details. */
  address?: Maybe<Address>;
  /** Branding settings. */
  branding: BrandingOutputBase;
  createdAt: Scalars['DateTime'];
  /** Currency */
  currency?: Maybe<Scalars['String']>;
  /** Contact e-mail address. */
  email: Scalars['String'];
  /** Beta features. */
  features?: Maybe<Array<Scalars['String']>>;
  helpLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Landing page settings. */
  landingPage: LandingPage;
  /** Marketing settings. */
  marketing?: Maybe<Marketing>;
  /** Organization name. */
  name: Scalars['String'];
  /** Contact phone. */
  phone: Scalars['String'];
  point: Point;
  /** The privacy policy of the organistion */
  privacyPolicy?: Maybe<Scalars['String']>;
  /** Shipping labels. */
  shippingLabels?: Maybe<ShippingLabels>;
  /** Show memberships renewal banner. */
  showMembershipRenewal?: Maybe<Scalars['Boolean']>;
  /** Show memberships in client navigation. */
  showMemberships: Scalars['Boolean'];
  /** Organization slug. */
  slug: Scalars['String'];
  /** The support information of the organistion */
  supportInfo?: Maybe<Scalars['String']>;
  /** Tax calculation method. either inclusive or exclusive. */
  taxCalculationMethod: TaxCalculationMethod;
  /** Tax number */
  taxNumber?: Maybe<Scalars['String']>;
  taxRate: Scalars['Float'];
  /** The terms and conditions of the organistion */
  termsAndConditions?: Maybe<Scalars['String']>;
  ticketCoverPlatformFeePercentage?: Maybe<Scalars['Float']>;
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  edges: Array<OrganizationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  cursor: Scalars['String'];
  node: Organization;
};

export type OrganizationOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
  updatedAt?: Maybe<OrderByDirection>;
};

export type OrganizationOutputBranding = {
  __typename?: 'OrganizationOutputBranding';
  N100: Scalars['String'];
  N200: Scalars['String'];
  N300: Scalars['String'];
  N400: Scalars['String'];
  N500: Scalars['String'];
  N600: Scalars['String'];
  N700: Scalars['String'];
  N800: Scalars['String'];
  P100: Scalars['String'];
  P200: Scalars['String'];
  P300: Scalars['String'];
  S100: Scalars['String'];
  S200: Scalars['String'];
  S300: Scalars['String'];
  /** Organization background image. */
  background?: Maybe<ExtendedFile>;
  /** Organization favicon. */
  favicon?: Maybe<ExtendedFile>;
  /** Organization full background image. */
  fullBackground?: Maybe<ExtendedFile>;
  /** Grey scale option. */
  greyScale?: Maybe<GreyScale>;
  /** Organization logo. */
  logo?: Maybe<ExtendedFile>;
  /** Organization mobile logo. */
  mobileLogo?: Maybe<ExtendedFile>;
  /** Primary color. */
  primaryColor: Scalars['String'];
  /** Secondary color. */
  secondaryColor: Scalars['String'];
};

export type OrganizationWhereInput = {
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

/** Facebook page. */
export type PageListsOutput = {
  __typename?: 'PageListsOutput';
  access_token: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

/** Address details. */
export type PartialAddress = {
  __typename?: 'PartialAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
};

/** Address details. */
export type PartialAddressInput = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
};

export type PaymentError = {
  __typename?: 'PaymentError';
  code?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Represents the order creation result. */
export type PaymentIntegrationOutput = {
  __typename?: 'PaymentIntegrationOutput';
  bnplEnabled?: Maybe<Scalars['Boolean']>;
  bnplTransactionFeePercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  facebookPageId?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** Integration name */
  name?: Maybe<Scalars['String']>;
  /** If payment gateways have different option types this field is used to specify which one to force */
  paymentMethodType?: Maybe<Scalars['String']>;
  /** External public key (can be used in customer frontend) */
  publicKey?: Maybe<Scalars['String']>;
  /** Transaction fee. */
  transactionFee?: Maybe<Scalars['Float']>;
  /** Transaction fee(only for stripe). Users can input both fixed and percentage fee for stripe(0.3$+2%). */
  transactionFee2?: Maybe<Scalars['Float']>;
  /** Transaction fee type. */
  transactionFeeType?: Maybe<FeeType>;
  /** Transaction fee type(only for stripe). Users can input both fixed and percentage fee for stripe(0.3$+2%). */
  transactionFeeType2?: Maybe<FeeType>;
  /** Integration type */
  type?: Maybe<IntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Webhook URL to point integration to */
  webhookUrl?: Maybe<Scalars['String']>;
};

/** Payment providers and methods */
export enum PaymentProvider {
  Afterpay = 'Afterpay',
  Cash = 'Cash',
  CreditCard = 'CreditCard',
  Credits = 'Credits',
  Eftpos = 'Eftpos',
  Invoice = 'Invoice',
  Laybuy = 'Laybuy',
  MpAiSa = 'MPAiSA',
  Other = 'Other',
  Pin = 'Pin',
  Points = 'Points',
  Pos = 'Pos',
  Stripe = 'Stripe',
  Windcave = 'Windcave'
}

export type PaymentResult = PaymentError | PaymentSuccess;

export type PaymentSuccess = {
  __typename?: 'PaymentSuccess';
  /** The payment gateway. */
  gateway: PaymentProvider;
  /** The payment url provided by the payment gateway. */
  redirectTo?: Maybe<Scalars['String']>;
};

/** Permission for a specific action */
export enum Permission {
  AccountCreate = 'ACCOUNT_CREATE',
  AccountDelete = 'ACCOUNT_DELETE',
  AccountRead = 'ACCOUNT_READ',
  AccountUpdate = 'ACCOUNT_UPDATE',
  AutomationCreate = 'AUTOMATION_CREATE',
  AutomationRead = 'AUTOMATION_READ',
  AutomationUpdate = 'AUTOMATION_UPDATE',
  BroadcastCreate = 'BROADCAST_CREATE',
  BroadcastRead = 'BROADCAST_READ',
  CompetitionEntriesRead = 'COMPETITION_ENTRIES_READ',
  CustomerCreate = 'CUSTOMER_CREATE',
  CustomerCredits = 'CUSTOMER_CREDITS',
  CustomerDelete = 'CUSTOMER_DELETE',
  CustomerExport = 'CUSTOMER_EXPORT',
  CustomerImport = 'CUSTOMER_IMPORT',
  CustomerRead = 'CUSTOMER_READ',
  CustomerUpdate = 'CUSTOMER_UPDATE',
  EventCreate = 'EVENT_CREATE',
  EventDelete = 'EVENT_DELETE',
  EventRead = 'EVENT_READ',
  EventUpdate = 'EVENT_UPDATE',
  MembershipCreate = 'MEMBERSHIP_CREATE',
  MembershipDelete = 'MEMBERSHIP_DELETE',
  MembershipRead = 'MEMBERSHIP_READ',
  MembershipUpdate = 'MEMBERSHIP_UPDATE',
  OrderCancel = 'ORDER_CANCEL',
  OrderComp = 'ORDER_COMP',
  OrderCreate = 'ORDER_CREATE',
  OrderDelete = 'ORDER_DELETE',
  OrderExport = 'ORDER_EXPORT',
  OrderRead = 'ORDER_READ',
  OrderRefund = 'ORDER_REFUND',
  OrderUpdate = 'ORDER_UPDATE',
  OrganizationBranding = 'ORGANIZATION_BRANDING',
  OrganizationCreate = 'ORGANIZATION_CREATE',
  OrganizationDelete = 'ORGANIZATION_DELETE',
  OrganizationIntegrations = 'ORGANIZATION_INTEGRATIONS',
  OrganizationLegal = 'ORGANIZATION_LEGAL',
  OrganizationSettings = 'ORGANIZATION_SETTINGS',
  OrganizationVenues = 'ORGANIZATION_VENUES',
  PointsCreate = 'POINTS_CREATE',
  PointsRead = 'POINTS_READ',
  PointsUpdate = 'POINTS_UPDATE',
  PosCreate = 'POS_CREATE',
  PosOperatorCreate = 'POS_OPERATOR_CREATE',
  PosRead = 'POS_READ',
  ReportingFinancial = 'REPORTING_FINANCIAL',
  ReportingPos = 'REPORTING_POS',
  ReportingSales = 'REPORTING_SALES',
  ReportingScans = 'REPORTING_SCANS',
  ScoutDelete = 'SCOUT_DELETE',
  ScoutRead = 'SCOUT_READ',
  SeasonCreate = 'SEASON_CREATE',
  SeasonDelete = 'SEASON_DELETE',
  SeasonRead = 'SEASON_READ',
  SeasonUpdate = 'SEASON_UPDATE',
  SocialCreate = 'SOCIAL_CREATE',
  SocialRead = 'SOCIAL_READ',
  TicketExport = 'TICKET_EXPORT',
  TicketImport = 'TICKET_IMPORT',
  TicketPrint = 'TICKET_PRINT',
  TicketRead = 'TICKET_READ',
  TicketRelease = 'TICKET_RELEASE',
  WaitlistExport = 'WAITLIST_EXPORT',
  WaitlistRead = 'WAITLIST_READ'
}

export type PinPaymentChargeResponse = {
  __typename?: 'PinPaymentChargeResponse';
  redirectUrl: Scalars['String'];
};

export type PinPaymentContext = {
  __typename?: 'PinPaymentContext';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  isSandbox: Scalars['Boolean'];
  publicKey?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type Point = {
  __typename?: 'Point';
  createdAt: Scalars['DateTime'];
  gateways: Array<Integration>;
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  organization: Organization;
  pointItems: Array<PointItem>;
  termsAndConditions?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PointItem = {
  __typename?: 'PointItem';
  amount: Scalars['Float'];
  backgroundImage?: Maybe<ExtendedFile>;
  banner?: Maybe<ExtendedFile>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  descriptionImage?: Maybe<ExtendedFile>;
  id: Scalars['ID'];
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
  organization: Organization;
  point: Point;
  price: Scalars['Float'];
  thumbnail?: Maybe<ExtendedFile>;
  updatedAt: Scalars['DateTime'];
};

export enum PointReportingFilterSource {
  Event = 'EVENT',
  Membership = 'MEMBERSHIP',
  Overall = 'OVERALL',
  Package = 'PACKAGE'
}

/** Represents a POS terminal. */
export type Pos = {
  __typename?: 'Pos';
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** The beginning float of change for the POS terminal. */
  cashAmount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  /** Available delivery options for the POS terminal. */
  deliveryOptions: Array<ShippingOption>;
  /** Whether receipts are turned on. */
  enableReceipts: Scalars['Boolean'];
  event?: Maybe<Event>;
  id: Scalars['ID'];
  /** Friendly name of the POS terminal. */
  name: Scalars['String'];
  /** Available payment option incl. fees. */
  paymentOptions: PosPaymentOptions;
  release?: Maybe<Release>;
  updatedAt: Scalars['DateTime'];
  users: Array<PosUser>;
};

export type PosPaymentFee = {
  __typename?: 'PosPaymentFee';
  /** Fee amount */
  feeAmount: Scalars['Float'];
  /** Fee type. */
  feeType: FeeType;
};

export type PosPaymentFeeInput = {
  /** Fee amount */
  feeAmount: Scalars['Float'];
  /** Fee type. */
  feeType: FeeType;
};

/** Represents POS options incl. fees. */
export type PosPaymentOptions = {
  __typename?: 'PosPaymentOptions';
  /** MPAiSA fee. */
  MPAiSA?: Maybe<PosPaymentFee>;
  /** Cash fee (normally $0). */
  cash?: Maybe<PosPaymentFee>;
  /** Credit card fee. */
  cc?: Maybe<PosPaymentFee>;
  /** Eftpos fee. */
  eftpos?: Maybe<PosPaymentFee>;
};

export type PosPaymentOptionsInput = {
  /** MPAiSA fee. */
  MPAiSA?: Maybe<PosPaymentFeeInput>;
  /** Cash fee (normally $0). */
  cash?: Maybe<PosPaymentFeeInput>;
  /** Credit card fee. */
  cc?: Maybe<PosPaymentFeeInput>;
  /** Eftpos fee. */
  eftpos?: Maybe<PosPaymentFeeInput>;
};

export type PosSalesOverviewOutput = {
  __typename?: 'PosSalesOverviewOutput';
  average: Scalars['Float'];
  orders: Scalars['Float'];
  perHour: Scalars['Float'];
  scannedPercentage: Scalars['Float'];
  soldAtTheDoor: Scalars['Float'];
  soldOnline: Scalars['Float'];
};

export type PosSession = {
  __typename?: 'PosSession';
  createdAt: Scalars['DateTime'];
  endCash?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  operator: User;
  pos: Pos;
  startCash?: Maybe<Scalars['Float']>;
  startedAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

/** Represents a POS transaction. */
export type PosTransaction = {
  __typename?: 'PosTransaction';
  /** Transaction amount. */
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  /** Fee amount. */
  fee: Scalars['Float'];
  id: Scalars['ID'];
  /** Used payment gateway. */
  paymentGateway: PaymentProvider;
  /** Return amount (only relevant for cash). */
  returned: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type PosTransactionConnection = {
  __typename?: 'PosTransactionConnection';
  edges: Array<PosTransactionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PosTransactionEdge = {
  __typename?: 'PosTransactionEdge';
  cursor: Scalars['String'];
  node: PosTransaction;
};

export type PosTransactionOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
};

export type PosUser = {
  __typename?: 'PosUser';
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type PostmarkEmailStatsOverviewOutput = {
  __typename?: 'PostmarkEmailStatsOverviewOutput';
  bounceRate: Scalars['Float'];
  bounced: Scalars['Float'];
  opens: Scalars['Float'];
  sent: Scalars['Float'];
  smtpApiErrors: Scalars['Float'];
  spamComplaints: Scalars['Float'];
  spamComplaintsRate: Scalars['Float'];
  totalClicks: Scalars['Float'];
  totalTrackedLinksSent: Scalars['Float'];
  tracked: Scalars['Float'];
  uniqueLinksClicked: Scalars['Float'];
  uniqueOpens: Scalars['Float'];
  withClientRecorded: Scalars['Float'];
  withLinkTracking: Scalars['Float'];
  withOpenTracking: Scalars['Float'];
  withPlatformRecorded: Scalars['Float'];
};

/** Create or update a presale. */
export type PresaleReleaseInput = {
  accessCodes: Array<AccessCodeInput>;
  accessScreenImage?: Maybe<Scalars['Upload']>;
  accessScreenMessage?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['String'];
  name: Scalars['String'];
  releaseId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tickets: Array<AccessCodeTicketInput>;
};

/** Portable representation of presale release. */
export type PresaleReleaseOutput = {
  __typename?: 'PresaleReleaseOutput';
  /** The access codes that can be used with this presale */
  accessCodes: Array<AccessCodeOutput>;
  /** Image to be shown on the customer portal of the ticket . Returns null if the not set. */
  accessScreenImage?: Maybe<ExtendedFile>;
  accessScreenMessage?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  releaseId: Scalars['String'];
  /** The presale URL slug */
  slug: Scalars['String'];
  startDate: Scalars['DateTime'];
  status?: Maybe<PresaleStatus>;
  /** The tickets that can be purchased with this presale */
  tickets: Array<PresaleTicketOutput>;
};

export enum PresaleStatus {
  Completed = 'COMPLETED',
  Disabled = 'DISABLED',
  OnSale = 'ON_SALE',
  PendingOnSale = 'PENDING_ON_SALE',
  PendingScheduled = 'PENDING_SCHEDULED',
  Scheduled = 'SCHEDULED'
}

/** Presale ticket output */
export type PresaleTicketOutput = {
  __typename?: 'PresaleTicketOutput';
  /** The ticket id */
  id: Scalars['String'];
  /** The type of ticket */
  type: TicketTypeKind;
};

export type PromoCodeUnion = EventPromoCode | MembershipPromoCode;

export type PromoterOnsaleOutput = {
  __typename?: 'PromoterOnsaleOutput';
  newCustomers: Scalars['Float'];
  orders: Scalars['Float'];
  referralEntriesAwarded: Scalars['Float'];
  revenue: Scalars['Float'];
};

export type PromoterRegistrationOutput = {
  __typename?: 'PromoterRegistrationOutput';
  newCustomers: Scalars['Float'];
  referralEntriesAwarded: Scalars['Float'];
  registrationsViaReferral: Scalars['Float'];
};

export type PromoterSettingsInput = {
  config?: Maybe<Array<PromoterShareActionInput>>;
  eventId: Scalars['String'];
  rewardSettings?: Maybe<RewardSettingsDto>;
  socialPages?: Maybe<Array<EventSocialPageInput>>;
};

export type PromoterSettingsOutput = {
  __typename?: 'PromoterSettingsOutput';
  configurations?: Maybe<Array<PromoterShareAction>>;
  /** The referral campaign associated with this event */
  referralCampaign?: Maybe<ReferralCampaign>;
  socialPages?: Maybe<Array<EventSocialPage>>;
};


export type PromoterSettingsOutputReferralCampaignArgs = {
  eventId: Scalars['String'];
};

export type PromoterShareAction = {
  __typename?: 'PromoterShareAction';
  actionType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  platform: Scalars['String'];
  socialLinkType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PromoterShareActionInput = {
  actionType: Scalars['String'];
  platform: Scalars['String'];
};

export type PublicReleaseTicketInput = {
  /** The ticket id */
  id: Scalars['String'];
  /** If ticket is hidden by the release */
  isHidden: Scalars['Boolean'];
  /** The ticket name */
  name: Scalars['String'];
  /** The ticket price */
  price?: Maybe<Scalars['Float']>;
  /** The ticket quantity */
  quantity?: Maybe<Scalars['Float']>;
  /** The type of ticket */
  type: TicketTypeKind;
  /** The ticket zone name */
  zoneName?: Maybe<Scalars['String']>;
};

/** Listed release ticket (ticketType or addon). */
export type PublicReleaseTicketOutput = {
  __typename?: 'PublicReleaseTicketOutput';
  /** The ticket id */
  id: Scalars['String'];
  /** If ticket is hidden by the release */
  isHidden: Scalars['Boolean'];
  /** The ticket name */
  name: Scalars['String'];
  /** The ticket price */
  price?: Maybe<Scalars['Float']>;
  /** The ticket quantity */
  quantity?: Maybe<Scalars['Float']>;
  /** The type of ticket */
  type: TicketTypeKind;
  /** The ticket zone name */
  zoneName?: Maybe<Scalars['String']>;
};

export type PublishCompetitionInput = {
  bannerImage?: Maybe<Scalars['Upload']>;
  competitionId?: Maybe<Scalars['String']>;
  competitionType: CompetitionType;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  prizes?: Maybe<Array<CompetitionPrizeInput>>;
  promoter?: Maybe<Array<CompetitionPromoterInput>>;
  referralRewardValue?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PurchaseRestriction = {
  __typename?: 'PurchaseRestriction';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isAlwaysVisible: Scalars['Boolean'];
  isExclusive: Scalars['Boolean'];
  quantity: Scalars['Int'];
  requiredEntities: Array<PurchaseRestrictionRequiredEntity>;
  restrictedId: Scalars['ID'];
  restrictedType: LineItemType;
  updatedAt: Scalars['DateTime'];
};

export type PurchaseRestrictionInput = {
  isAlwaysVisible?: Maybe<Scalars['Boolean']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  quantity: Scalars['Int'];
  requiredEntities: Array<PurchaseRestrictionRequiredEntityInput>;
  restrictedId: Scalars['ID'];
  restrictedType: LineItemType;
};

export type PurchaseRestrictionRequiredEntity = {
  __typename?: 'PurchaseRestrictionRequiredEntity';
  requiredId: Scalars['ID'];
  requiredType: LineItemType;
};

export type PurchaseRestrictionRequiredEntityInput = {
  requiredId: Scalars['ID'];
  requiredType: LineItemType;
};

export type Query = {
  __typename?: 'Query';
  activeCompetitionByType?: Maybe<Competition>;
  activeCompetitions?: Maybe<Array<Competition>>;
  activities: ActivityConnection;
  adobeIntegration?: Maybe<AdobeExperiencePlatformIntegration>;
  audienceCount: Scalars['Float'];
  automation: Automation;
  automations: Array<Automation>;
  broadcast: Broadcast;
  broadcastBounces: Array<User>;
  broadcastReportStats: BroadcastReportingOutput;
  broadcasts: BroadcastConnection;
  /** Gets ticket cover amount for order */
  calculateTicketCoverAmounts: TicketCoverAmount;
  capacityOverview: Array<CapacityOverview>;
  capacitySeatingMap: Scalars['String'];
  capacitySeatingMapTooltips: Scalars['String'];
  competition: Competition;
  competitions: Array<Competition>;
  currentOrganization: CurrentOrganizationOutput;
  customerLocations: Array<LocationOutput>;
  customerOrders: Array<ShopifyCustomerOrder>;
  deleteAdobeIntegration: Scalars['Boolean'];
  disableShopifyIntegration: Scalars['Boolean'];
  disableXeroIntegration: Scalars['Boolean'];
  event: Event;
  eventAddons: Array<EventAddon>;
  eventForecast: EventForecastOutput;
  eventMultiBuyPromotions: Array<EventMultiBuyPromotion>;
  eventOrMembershipSeasons: Array<Season>;
  /** Fetches online orders for an event */
  eventOrdersOnline?: Maybe<EventOrdersOnlineOutput>;
  eventPromoCodes: Array<EventPromoCode>;
  events: EventConnection;
  facebookPageLists: Array<PageListsOutput>;
  financialGateways: FinancialGatewayGraphOutput;
  financialGraph: GraphOutput;
  financialOverview: FinancialOverviewOutput;
  financialSalesBreakdown: Array<FinancialSales>;
  findOrderIdForTicket: FindOrderIdForTicketOutput;
  /** Fetches a users referral ID. */
  findUserReferralId: User;
  getAccessCode: AccessCodeReleaseOutput;
  getAccessCodes: Array<AccessCodeReleaseOutput>;
  getCompetitionReferralReportForCustomer: Array<CompetitionCustomerReporting>;
  getConsentUrl: Scalars['String'];
  getEventAndReleaseForCustomer: EventsWithAccessControlOutput;
  getEventForCompetitionReporting: EventForCompetitionReportingEntity;
  getEventPurchaseRestrictions: Array<PurchaseRestriction>;
  getHistoryByCustomerId: Array<History>;
  getMembershipAutoRenewal: MembershipAutoRenewalOutput;
  getMembershipPurchaseRestrictions: Array<PurchaseRestriction>;
  getMembershipSubscription: MembershipSubscription;
  getMembershipSubscriptionsForPreviousMemberships: Array<MembershipSubscription>;
  getMinTicketTypePricesForEvent: Array<MinTicketTypePricesForEventOutput>;
  getOnsaleCompetitionReportStats: CompetitionReportingOnsaleOutput;
  getOrderJobStatus: OrderCreateOutput;
  getPromoterSettings?: Maybe<PromoterSettingsOutput>;
  getPublicReleaseTickets?: Maybe<Array<PublicReleaseTicketOutput>>;
  getRedirectUrlByCode: RedirectUrl;
  getReferralCampaignByEventId?: Maybe<ReferralCampaign>;
  getReferralCampaignByOrderId?: Maybe<ReferralCampaign>;
  getReferralCampaignByReferralCode?: Maybe<ReferralCampaign>;
  getReferralCampaignStats: ReferralCampaignOverviewOutput;
  getReferralCampaigns: Array<ReferralCampaign>;
  getReferralCampaignsByEventId?: Maybe<Array<ReferralCampaign>>;
  getReferralReportForCustomer: Array<ReferralCustomerReporting>;
  getReferralRewardByOrderId?: Maybe<ReferralReward>;
  getRegistrationCompetitionReportStats: CompetitionReportingRegistrationOutput;
  getRegistrationReferralReportStats: ReferralRegistrationPromotionReportingOutput;
  getReleaseBySlug?: Maybe<Release>;
  getReleaseUrlForResaleTicketType: Scalars['String'];
  getReleasesForPrivateLinks: Array<Release>;
  getSubscriptionPortalUrlForOrder: MembershipSubscriptionPortalOutput;
  getSubscriptionPortalUrlForTicket: MembershipSubscriptionPortalOutput;
  getTicketsByIds: Array<Ticket>;
  getTrackingCodesByEventId: Array<TrackingCodeOutput>;
  getXeroAccounts: XeroAllAccountsOutput;
  getXeroConfiguration?: Maybe<XeroConfigurationOutput>;
  getZonesByEventId: Array<Zone>;
  googleAnalyticsReport: Array<BarchartOutput>;
  hasSeatedEvent: Scalars['Boolean'];
  highestValueCustomers: Array<HighestValueCustomersOutput>;
  initScout: InitScout;
  insightsAgeBreakDown: AgeBreakDownOutput;
  insightsGenderBreakDown: Array<GenderBreakDownOutput>;
  insightsHeardAboutUsBreakDown: Array<HeardAboutUsBreakDownOutput>;
  insightsOverview: InsightsOverviewOutput;
  integration: Integration;
  integrations: Array<Integration>;
  isLoggedIn: Scalars['Boolean'];
  linkedAccountUser: LinkedAccountUser;
  listPresales: Array<PresaleReleaseOutput>;
  mailchimpDomains: Scalars['JSON'];
  mailchimpLists: Scalars['JSON'];
  mailchimpTemplates: Scalars['JSON'];
  marketingUserCount: Scalars['Float'];
  me: User;
  membership?: Maybe<Membership>;
  membershipAddons: Array<MembershipAddon>;
  membershipMultiBuyPromotions: Array<MembershipMultiBuyPromotion>;
  membershipPromoCodes: Array<MembershipPromoCode>;
  membershipTypeEventTransferPrices?: Maybe<Array<MembershipTypeEventTransferPrice>>;
  membershipTypes: Array<MembershipType>;
  memberships: MembershipConnection;
  membershipsSoldForUser: Array<MembershipTypePurchaseTotal>;
  mentionOverview: MentionOverview;
  myEventTickets: Array<Ticket>;
  myEvents: Array<Event>;
  myLoggedInOrganizations?: Maybe<Array<MyLoggedInOrganizationsOutput>>;
  myMembershipAddons: Array<Ticket>;
  myMembershipTickets: Array<Ticket>;
  /** Fetches orders of a user (paginated). */
  myOrders: OrderConnection;
  myScannedTickets: Array<Ticket>;
  myScannedTicketsCount: Scalars['Float'];
  /** Fetches an order. */
  order?: Maybe<Order>;
  /** Fetches online orders ids for an event */
  orderIdsOnline?: Maybe<Array<OrderIdsOnlineOutput>>;
  /** Returns an orders details required to do refunds */
  orderRefundDetails?: Maybe<OrderRefundDetailsOutput>;
  /** Fetches orders (paginated). */
  orders: OrderConnection;
  /** Gets updated orders */
  ordersUpdatedSince: CustomSaleOutput;
  /** Gets updated orders */
  ordersUpdatedSinceForEvent: CustomSaleOutput;
  /** [super-admin] | Fetches an organization by id */
  organization: Organization;
  /** [app] | Fetches organization-names and Ids (paginated) */
  organizationNames: OrganizationConnection;
  /** [super-admin] | Fetches organizations (paginated) */
  organizations: OrganizationConnection;
  pinPaymentContext: PinPaymentContext;
  point?: Maybe<Point>;
  pointItem: PointItem;
  pointItems: Array<PointItem>;
  pos: Pos;
  posFinancialOverview: Array<GraphItem>;
  posFloatOfChange: Array<GraphItem>;
  posSalesOverview: PosSalesOverviewOutput;
  posSessions: Array<PosSession>;
  posUsers: Array<User>;
  poses: Array<Pos>;
  previousMembershipCount?: Maybe<Scalars['Float']>;
  purchasingDaysBreakdown: Array<BarchartOutput>;
  referralPromotionReportStats: ReferralPromotionReportingOutput;
  referralUrlByOrderId?: Maybe<Scalars['String']>;
  referralUrlByWaitlistUserId?: Maybe<Scalars['String']>;
  release: Release;
  releases: Array<Release>;
  resaleTicket: TicketType;
  resaleTicketTypesForWaitlist: Array<TicketType>;
  resaleTickets: Array<TicketType>;
  resaleTicketsMetrics: Array<ResaleTicketMetrics>;
  salesChannelGraph: SalesChannelGraphOutput;
  salesGraph: GraphOutput;
  salesOverview: SalesOverviewOutput;
  scanTicketTypes?: Maybe<Array<GraphTextItem>>;
  scanVisitors?: Maybe<Array<GraphTextItem>>;
  scanVisitorsOverTime?: Maybe<Array<GraphRangeItem>>;
  scout: Scout;
  scoutCampaignCustomerLocations: Array<LocationOutput>;
  scoutCampaignGenderBreakdown: Array<GenderBreakDownOutput>;
  scoutCampaignHighestValueCustomers: Array<HighestValueCustomersOutput>;
  scoutCampaignOverview: ScoutCampaignOverviewOutput;
  scoutCampaignTopTags: Array<TopTagsOutput>;
  scoutCommunication: ScoutCommunication;
  scoutCommunicationUser: ScoutCommunicationUser;
  scoutCommunicationUsers: ScoutCommunicationUserConnection;
  scoutCommunications: Array<ScoutCommunication>;
  scoutUserReminders: Array<ScoutCommunicationUser>;
  /** Fetches users (paginated). */
  scoutUsers: ScoutUserConnection;
  scouts: Array<Scout>;
  /** DEPRECATED: should use searchTicketsByEventApp to fetch for scanner app */
  searchTickets: TicketConnection;
  searchTicketsByEventApp: SearchEventTicketsAppOutput;
  season: Season;
  seasons: Array<Season>;
  socialMentions: SocialConnection;
  summaryOverview: SummaryOverviewOutput;
  tags: Array<Tag>;
  ticket?: Maybe<Ticket>;
  /** Returns stringyfied mapping of details per seat label. */
  ticketDetailsForBackOfficeOrders: Scalars['String'];
  ticketTypes: Array<TicketType>;
  ticketsSoldForUser: Array<TicketTypePurchaseTotal>;
  timeOfDayBreakdown: Array<BarchartOutput>;
  topReferrers: Array<TopReferrersOutput>;
  topRepeatPurchasers: Array<TopRepeatPurchasers>;
  topTags: Array<TopTagsOutput>;
  transferredMembershipTicketsForOrder?: Maybe<Array<Ticket>>;
  /** Fetches a user. */
  user: User;
  userEntries?: Maybe<Array<GrantRewardOutput>>;
  /** Fetches users (paginated). */
  users: UserConnection;
  usersEventTickets: Array<Ticket>;
  venue: Venue;
  venueSeatsThumbnail?: Maybe<Scalars['String']>;
  venues: Array<Venue>;
  waitlistOverview: WaitlistOverviewOutput;
  windcavePaymentContext: WindcavePaymentContext;
  windcavePaymentResult: WindcavePaymentResultOutput;
};


export type QueryActiveCompetitionByTypeArgs = {
  input: ActiveCompetitionByTypeInput;
};


export type QueryActiveCompetitionsArgs = {
  eventId: Scalars['String'];
};


export type QueryActivitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ActivityWhereInput>;
};


export type QueryAudienceCountArgs = {
  input: AudienceCountInput;
};


export type QueryAutomationArgs = {
  id: Scalars['String'];
};


export type QueryAutomationsArgs = {
  input: FindAutomationsInput;
};


export type QueryBroadcastArgs = {
  id: Scalars['String'];
};


export type QueryBroadcastBouncesArgs = {
  count: Scalars['Float'];
  id: Scalars['String'];
  start: Scalars['Float'];
};


export type QueryBroadcastReportStatsArgs = {
  input: BroadcastReportingStatsInput;
};


export type QueryBroadcastsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<BroadcastWhereInput>;
};


export type QueryCalculateTicketCoverAmountsArgs = {
  id: Scalars['String'];
};


export type QueryCapacityOverviewArgs = {
  eventId: Scalars['String'];
};


export type QueryCapacitySeatingMapArgs = {
  eventId: Scalars['String'];
};


export type QueryCapacitySeatingMapTooltipsArgs = {
  eventId: Scalars['String'];
};


export type QueryCompetitionArgs = {
  id: Scalars['String'];
};


export type QueryCompetitionsArgs = {
  eventId: Scalars['String'];
};


export type QueryCustomerLocationsArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: PointReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryCustomerOrdersArgs = {
  customerId: Scalars['String'];
};


export type QueryEventArgs = {
  id: Scalars['String'];
};


export type QueryEventAddonsArgs = {
  eventId: Scalars['String'];
};


export type QueryEventForecastArgs = {
  eventId: Scalars['String'];
};


export type QueryEventMultiBuyPromotionsArgs = {
  eventId: Scalars['String'];
};


export type QueryEventOrdersOnlineArgs = {
  id: Scalars['String'];
};


export type QueryEventPromoCodesArgs = {
  eventId: Scalars['String'];
};


export type QueryEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<EventOrderByInput>;
  where?: Maybe<EventWhereInput>;
};


export type QueryFacebookPageListsArgs = {
  input: FacebookPageListsInput;
};


export type QueryFinancialGatewaysArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: PointReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryFinancialGraphArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryFinancialOverviewArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: PointReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryFinancialSalesBreakdownArgs = {
  channel?: Maybe<OrderChannel>;
  endDate?: Maybe<Scalars['DateTime']>;
  release?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFinancialSalesBreakdownSource;
  sourceId: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryFindOrderIdForTicketArgs = {
  ticketId: Scalars['String'];
};


export type QueryFindUserReferralIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAccessCodeArgs = {
  releaseId: Scalars['String'];
};


export type QueryGetAccessCodesArgs = {
  eventId: Scalars['String'];
};


export type QueryGetCompetitionReferralReportForCustomerArgs = {
  input: CustomerCompetitionReportingInput;
};


export type QueryGetEventAndReleaseForCustomerArgs = {
  input: EventsWithAccessControlInput;
};


export type QueryGetEventForCompetitionReportingArgs = {
  id: Scalars['String'];
};


export type QueryGetEventPurchaseRestrictionsArgs = {
  eventId: Scalars['String'];
};


export type QueryGetHistoryByCustomerIdArgs = {
  input: GetHistoryByCustomerIdInput;
};


export type QueryGetMembershipAutoRenewalArgs = {
  membershipId: Scalars['String'];
};


export type QueryGetMembershipPurchaseRestrictionsArgs = {
  membershipId: Scalars['String'];
};


export type QueryGetMembershipSubscriptionArgs = {
  membershipSubscriptionId: Scalars['String'];
};


export type QueryGetMembershipSubscriptionsForPreviousMembershipsArgs = {
  previousMembershipIds: Array<Scalars['String']>;
};


export type QueryGetMinTicketTypePricesForEventArgs = {
  eventId: Scalars['String'];
};


export type QueryGetOnsaleCompetitionReportStatsArgs = {
  input: CompetitionReportingInput;
};


export type QueryGetOrderJobStatusArgs = {
  jobId: Scalars['String'];
};


export type QueryGetPromoterSettingsArgs = {
  eventId: Scalars['String'];
};


export type QueryGetPublicReleaseTicketsArgs = {
  eventId: Scalars['String'];
};


export type QueryGetRedirectUrlByCodeArgs = {
  input: RedirectCodeInput;
};


export type QueryGetReferralCampaignByEventIdArgs = {
  id: Scalars['String'];
};


export type QueryGetReferralCampaignByOrderIdArgs = {
  id: Scalars['String'];
};


export type QueryGetReferralCampaignByReferralCodeArgs = {
  referralCode: Scalars['String'];
};


export type QueryGetReferralCampaignStatsArgs = {
  eventId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryGetReferralCampaignsByEventIdArgs = {
  id: Scalars['String'];
};


export type QueryGetReferralReportForCustomerArgs = {
  input: CustomerReferralReportingInput;
};


export type QueryGetReferralRewardByOrderIdArgs = {
  id: Scalars['String'];
};


export type QueryGetRegistrationCompetitionReportStatsArgs = {
  input: CompetitionReportingInput;
};


export type QueryGetRegistrationReferralReportStatsArgs = {
  input: ReferralPromotionReportingInput;
};


export type QueryGetReleaseBySlugArgs = {
  eventId: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};


export type QueryGetReleaseUrlForResaleTicketTypeArgs = {
  eventId: Scalars['String'];
};


export type QueryGetReleasesForPrivateLinksArgs = {
  eventId: Scalars['String'];
};


export type QueryGetSubscriptionPortalUrlForOrderArgs = {
  orderId: Scalars['String'];
};


export type QueryGetSubscriptionPortalUrlForTicketArgs = {
  ticketId: Scalars['String'];
};


export type QueryGetTicketsByIdsArgs = {
  ids: Scalars['String'];
};


export type QueryGetTrackingCodesByEventIdArgs = {
  eventId: Scalars['String'];
};


export type QueryGetZonesByEventIdArgs = {
  eventId: Scalars['String'];
};


export type QueryGoogleAnalyticsReportArgs = {
  input: GoogleAnalyticsReportingFilterInput;
};


export type QueryHighestValueCustomersArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryInsightsAgeBreakDownArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryInsightsGenderBreakDownArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryInsightsHeardAboutUsBreakDownArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryInsightsOverviewArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryIntegrationArgs = {
  id: Scalars['String'];
};


export type QueryIntegrationsArgs = {
  input?: Maybe<IntegrationWhereInput>;
};


export type QueryLinkedAccountUserArgs = {
  id: Scalars['String'];
};


export type QueryListPresalesArgs = {
  eventId: Scalars['String'];
};


export type QueryMarketingUserCountArgs = {
  input: BroadcastFilterCountInput;
};


export type QueryMembershipArgs = {
  id: Scalars['String'];
};


export type QueryMembershipAddonsArgs = {
  membershipId: Scalars['String'];
};


export type QueryMembershipMultiBuyPromotionsArgs = {
  membershipId: Scalars['String'];
};


export type QueryMembershipPromoCodesArgs = {
  membershipId: Scalars['String'];
};


export type QueryMembershipTypeEventTransferPricesArgs = {
  id: Scalars['String'];
};


export type QueryMembershipTypesArgs = {
  membership: Scalars['String'];
};


export type QueryMembershipsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MembershipOrderByInput>;
  where?: Maybe<MembershipWhereInput>;
};


export type QueryMembershipsSoldForUserArgs = {
  membershipId: Scalars['String'];
};


export type QueryMentionOverviewArgs = {
  input: MentionOverviewInput;
};


export type QueryMyEventTicketsArgs = {
  eventId: Scalars['String'];
};


export type QueryMyEventsArgs = {
  orderBy?: Maybe<MyEventsOrderByInput>;
  where?: Maybe<MyEventsWhereInput>;
};


export type QueryMyMembershipAddonsArgs = {
  membershipId: Scalars['String'];
};


export type QueryMyOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderOrderByInput>;
  where?: Maybe<OrderWhereInput>;
};


export type QueryMyScannedTicketsArgs = {
  eventId: Scalars['String'];
};


export type QueryMyScannedTicketsCountArgs = {
  eventId: Scalars['String'];
};


export type QueryOrderArgs = {
  id: Scalars['String'];
};


export type QueryOrderIdsOnlineArgs = {
  id: Scalars['String'];
};


export type QueryOrderRefundDetailsArgs = {
  eventId: Scalars['String'];
  orderId: Scalars['String'];
};


export type QueryOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderOrderByInput>;
  where?: Maybe<OrderWhereInput>;
};


export type QueryOrdersUpdatedSinceArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  eventId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryOrdersUpdatedSinceForEventArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  eventId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryOrganizationNamesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrganizationOrderByInput>;
  where?: Maybe<OrganizationWhereInput>;
};


export type QueryOrganizationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrganizationOrderByInput>;
  where?: Maybe<OrganizationWhereInput>;
};


export type QueryPinPaymentContextArgs = {
  id: Scalars['String'];
};


export type QueryPointItemArgs = {
  id: Scalars['String'];
};


export type QueryPosArgs = {
  id: Scalars['String'];
};


export type QueryPosFinancialOverviewArgs = {
  eventId: Scalars['String'];
  posId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
};


export type QueryPosFloatOfChangeArgs = {
  eventId: Scalars['String'];
  posId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
};


export type QueryPosSalesOverviewArgs = {
  eventId: Scalars['String'];
  posId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
};


export type QueryPosSessionsArgs = {
  posId: Scalars['String'];
};


export type QueryPosUsersArgs = {
  eventId: Scalars['String'];
};


export type QueryPosesArgs = {
  eventId: Scalars['String'];
};


export type QueryPreviousMembershipCountArgs = {
  id: Scalars['String'];
};


export type QueryPurchasingDaysBreakdownArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryReferralPromotionReportStatsArgs = {
  input: ReferralPromotionReportingInput;
};


export type QueryReferralUrlByOrderIdArgs = {
  orderId: Scalars['String'];
};


export type QueryReferralUrlByWaitlistUserIdArgs = {
  eventId: Scalars['String'];
  waitlistUserId: Scalars['String'];
};


export type QueryReleaseArgs = {
  id: Scalars['String'];
};


export type QueryReleasesArgs = {
  event: Scalars['String'];
};


export type QueryResaleTicketArgs = {
  id: Scalars['String'];
};


export type QueryResaleTicketTypesForWaitlistArgs = {
  eventId: Scalars['String'];
  releaseId?: Maybe<Scalars['String']>;
};


export type QueryResaleTicketsArgs = {
  eventId: Scalars['String'];
};


export type QueryResaleTicketsMetricsArgs = {
  eventId: Scalars['String'];
};


export type QuerySalesChannelGraphArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: PointReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QuerySalesGraphArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: PointReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
  type?: Maybe<GraphFilterType>;
};


export type QuerySalesOverviewArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: PointReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryScanTicketTypesArgs = {
  eventId: Scalars['String'];
};


export type QueryScanVisitorsArgs = {
  eventId: Scalars['String'];
};


export type QueryScanVisitorsOverTimeArgs = {
  eventId: Scalars['String'];
};


export type QueryScoutArgs = {
  id: Scalars['String'];
};


export type QueryScoutCampaignCustomerLocationsArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryScoutCampaignGenderBreakdownArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryScoutCampaignHighestValueCustomersArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryScoutCampaignOverviewArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryScoutCampaignTopTagsArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryScoutCommunicationArgs = {
  id: Scalars['String'];
};


export type QueryScoutCommunicationUserArgs = {
  id: Scalars['String'];
};


export type QueryScoutCommunicationUsersArgs = {
  after?: Maybe<Scalars['String']>;
  agentUserId?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  hasPhoneNumber?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  last?: Maybe<Scalars['Int']>;
  stage?: Maybe<Array<ScoutStage>>;
  where?: Maybe<UserWhereInput>;
};


export type QueryScoutCommunicationsArgs = {
  input: ScoutCommunicationsInput;
};


export type QueryScoutUsersArgs = {
  after?: Maybe<Scalars['String']>;
  agentUserId?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  hasPhoneNumber?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  last?: Maybe<Scalars['Int']>;
  stage?: Maybe<Array<ScoutStage>>;
  where?: Maybe<UserWhereInput>;
};


export type QueryScoutsArgs = {
  input?: Maybe<FindScoutsInput>;
};


export type QuerySearchTicketsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  eventId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TicketWhereInput>;
  where?: Maybe<TicketWhereInput>;
};


export type QuerySearchTicketsByEventAppArgs = {
  eventId: Scalars['String'];
  q?: Maybe<Scalars['String']>;
};


export type QuerySeasonArgs = {
  id: Scalars['String'];
};


export type QuerySocialMentionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<SocialWhereInput>;
};


export type QuerySummaryOverviewArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryTicketArgs = {
  id: Scalars['String'];
};


export type QueryTicketDetailsForBackOfficeOrdersArgs = {
  eventId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
};


export type QueryTicketTypesArgs = {
  eventId: Scalars['String'];
};


export type QueryTicketsSoldForUserArgs = {
  eventId: Scalars['String'];
};


export type QueryTimeOfDayBreakdownArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryTopReferrersArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryTopRepeatPurchasersArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryTopTagsArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryTransferredMembershipTicketsForOrderArgs = {
  orderId: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserEntriesArgs = {
  input: GetUserEntriesInput;
};


export type QueryUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<UserOrderByInput>;
  where?: Maybe<UserWhereInput>;
};


export type QueryVenueArgs = {
  id: Scalars['String'];
};


export type QueryVenueSeatsThumbnailArgs = {
  id: Scalars['String'];
};


export type QueryWaitlistOverviewArgs = {
  channel?: Maybe<OrderChannel>;
  endDate: Scalars['DateTime'];
  release?: Maybe<Scalars['String']>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tagId?: Maybe<Scalars['String']>;
};


export type QueryWindcavePaymentContextArgs = {
  id: Scalars['String'];
};


export type QueryWindcavePaymentResultArgs = {
  externalId: Scalars['String'];
  result: Scalars['String'];
};

export type RedeemTicketInput = {
  /** Event ID */
  eventId: Scalars['String'];
  /** Ticket number */
  ticketNumber: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export type RedirectCodeInput = {
  /** Full URL path. */
  redirectCode: Scalars['String'];
};

/** Mapping of a shortened URL to its full path */
export type RedirectUrl = {
  __typename?: 'RedirectUrl';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Full URL path. */
  redirectURL: Scalars['String'];
  /** Reference entity ID. */
  refId: Scalars['String'];
  /** Type of entity this redirect is for. */
  refName: Scalars['String'];
  /** Shortned URL slug. */
  shortenedSlug: Scalars['String'];
  /** Number of times the shortened URL has redirected to the full path. */
  timesUsed: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

/** Referral Campaign */
export type ReferralCampaign = {
  __typename?: 'ReferralCampaign';
  createdAt: Scalars['DateTime'];
  /** The eventId that the referral campaign is associated with */
  event?: Maybe<Event>;
  id: Scalars['ID'];
  /** Whether the referral campaign is active */
  isActive: Scalars['Boolean'];
  /** The memebrshipId that the referral campaign is associated with */
  membership?: Maybe<Membership>;
  /** The quantity of the reward for the owner of the referral */
  referralOwnerRewardQuantity: Scalars['Float'];
  /** The reward quantity type. */
  referralOwnerRewardQuantityType: ReferralRewardQuantityType;
  /** The type of reward for the owner of the referral */
  referralOwnerRewardType: ReferralRewardType;
  /** The unique identifier for the referral campaign */
  referralRelease?: Maybe<Release>;
  /** The quantity of the reward for the user of the referral */
  referralUserRewardQuantity: Scalars['Float'];
  /** The reward quantity type. */
  referralUserRewardQuantityType: ReferralRewardQuantityType;
  /** The type of reward for the user of the referral */
  referralUserRewardType: ReferralRewardType;
  updatedAt: Scalars['DateTime'];
};

export type ReferralCampaignOverviewOutput = {
  __typename?: 'ReferralCampaignOverviewOutput';
  customersAccquired: Scalars['Float'];
  numberOfOrders: Scalars['Float'];
  ownerRewards: Scalars['Float'];
  referralLinksClicked: Scalars['Float'];
  referralLinksCreated: Scalars['Float'];
  revenueGenerated: Scalars['Float'];
  tickets: Scalars['Float'];
  userRewards: Scalars['Float'];
};

/** A referral code for a user and event */
export type ReferralCode = {
  __typename?: 'ReferralCode';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type ReferralCustomerReporting = {
  __typename?: 'ReferralCustomerReporting';
  eventLogo?: Maybe<ExtendedFile>;
  eventName: Scalars['String'];
  orderDate: Scalars['DateTime'];
  orderNumber: Scalars['String'];
  referralLink?: Maybe<Scalars['String']>;
  referralsMade: Scalars['Int'];
};

export type ReferralPromotionReportingInput = {
  eventId: Scalars['String'];
};

export type ReferralPromotionReportingOutput = {
  __typename?: 'ReferralPromotionReportingOutput';
  actionEngagement?: Maybe<Array<ActionEngagementRecordOutput>>;
  discountValue: Scalars['Float'];
  newReferredCustomers: Scalars['Float'];
  orders: Scalars['Float'];
  referrerPointsAwarded: Scalars['Float'];
  revenue: Scalars['Float'];
  roi: Scalars['Float'];
};

export type ReferralRegistrationPromotionReportingOutput = {
  __typename?: 'ReferralRegistrationPromotionReportingOutput';
  actionEngagement?: Maybe<Array<ActionEngagementRecordOutput>>;
  newReferredCustomers: Scalars['Float'];
  registrations: Scalars['Float'];
};

/** Referral Reward */
export type ReferralReward = {
  __typename?: 'ReferralReward';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  order: Order;
  /** Organization associated with the referral campaign */
  organization: Organization;
  /** The referral campaign the rewards are from */
  referralCampaign: ReferralCampaign;
  /** The role of the user played when receiving these rewards */
  rewardForRole: ReferralRole;
  /** The quantity of the reward for the owner of the referral */
  rewardQuantity: Scalars['Float'];
  /** The reward quantity type. */
  rewardQuantityType: ReferralRewardQuantityType;
  /** The type of reward for the referral */
  rewardType: ReferralRewardType;
  updatedAt: Scalars['DateTime'];
};

/** The valid values for quantities of referral rewards. */
export enum ReferralRewardQuantityType {
  FlatRate = 'flatRate',
  Percentage = 'percentage'
}

/** The valid values for referral rewards. Some are only applicable to certain roles in a referral transaction. */
export enum ReferralRewardType {
  Credits = 'Credits',
  Discount = 'Discount',
  Points = 'Points'
}

/** The role the user played when in a referral exchange. */
export enum ReferralRole {
  Owner = 'Owner',
  User = 'User'
}

/** Represents a refund of items in an order. */
export type Refund = {
  __typename?: 'Refund';
  /** The amount of the refund. */
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  gateway?: Maybe<RefundGateway>;
  id: Scalars['ID'];
  /** Whether the refund is a manual one. */
  isManual: Scalars['Boolean'];
  kind: RefundKind;
  /** Notes why the refund has been initiated. */
  notes: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Refund gateways. */
export enum RefundGateway {
  Cash = 'Cash',
  CreditCard = 'CreditCard',
  Eftpos = 'Eftpos',
  Invoice = 'Invoice',
  Other = 'Other'
}

/** Refund kinds. */
export enum RefundKind {
  Default = 'Default',
  TicketTransfer = 'TicketTransfer'
}

export type RegisterInput = {
  email: Scalars['String'];
  enableTwoFactorAuthentication: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  posId?: Maybe<Scalars['String']>;
  userAcquisition?: Maybe<UserAcquisition>;
};

/** Represents a release in an event. */
export type Release = {
  __typename?: 'Release';
  allowAccount2Account?: Maybe<Scalars['Boolean']>;
  /** Toggle whether customers can create hold orders for this release */
  allowHoldOrders: Scalars['Boolean'];
  allowPublicComp: Scalars['Boolean'];
  /** Toggle whether to ask for dietary requirements with their order */
  askForDietaryRequirements?: Maybe<Scalars['Boolean']>;
  /** Toggle whether to ask customers how they heard about their purcahse */
  askHowDidYouHearAboutUs?: Maybe<Scalars['Boolean']>;
  canBeHardDeleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  /** The end condition of the release. */
  endCondition: ReleaseEndCondition;
  /** The end date of the release. Returs null if the endCondition is set to other than "date". */
  endConditionDate?: Maybe<Scalars['DateTime']>;
  /** The quantity limit of the release. Returns null if the endCondition is set to other than "quantity". */
  endConditionQuantity?: Maybe<Scalars['Float']>;
  event: Event;
  id: Scalars['ID'];
  /** The status of the release. */
  isActive: Scalars['Boolean'];
  isResaleRelease: Scalars['Boolean'];
  maxPurchaseQuantity: Scalars['Int'];
  minPurchaseQuantity: Scalars['Int'];
  /** Release name. */
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  /** The header image of the unlisted release. Returns null if the `type` is set to other than `unlisted`. */
  presaleHeader?: Maybe<ExtendedFile>;
  /** The information of the unlisted release. Returns null if the `type` is set to other than `unlisted`. */
  presaleInformation?: Maybe<Scalars['String']>;
  /** The logo of the unlisted release. Returns null if the `type` is set to other than `unlisted`. */
  presaleLogo?: Maybe<ExtendedFile>;
  /** The title of the unlisted release. Returns null if the `type` is set to other than `unlisted`. */
  presaleTitle?: Maybe<Scalars['String']>;
  releaseEventAddons: Array<ReleaseEventAddon>;
  /** The information that will be displayed on the ticketing page for a specific release. */
  releaseNotes?: Maybe<Scalars['String']>;
  releaseZones: Array<ReleaseZone>;
  requiresAuth: Scalars['Boolean'];
  /** The slug for the unlisted release. Returns null if the release type is set to other than `unlisted`. */
  slug?: Maybe<Scalars['String']>;
  /** The start condition of the release. */
  startCondition: ReleaseStartCondition;
  /** The start date of the release. Returns null if the startCondition is set to other than "date". */
  startConditionDate?: Maybe<Scalars['DateTime']>;
  status: ReleaseStatus;
  /** Release type. */
  type: ReleaseType;
  updatedAt: Scalars['DateTime'];
};

export enum ReleaseEndCondition {
  Date = 'DATE',
  Manual = 'MANUAL',
  Quantity = 'QUANTITY'
}

export type ReleaseEventAddon = {
  __typename?: 'ReleaseEventAddon';
  createdAt: Scalars['DateTime'];
  eventAddon: EventAddon;
  eventAddonId: Scalars['String'];
  id: Scalars['ID'];
  releaseId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Represents a unique password code in a release. */
export type ReleasePassword = {
  __typename?: 'ReleasePassword';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The usage limit of the release password for complimentary tickets. */
  quantityComp: Scalars['Int'];
  /** The usage limit of the release password. Returns null if password has no usage limit. */
  quantityPaid: Scalars['Int'];
  totalUsedComp: Scalars['Int'];
  totalUsedPaid: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** Creates a refund. */
export type ReleaseSeatsInput = {
  /** Notes why the seats are released. */
  notes: Scalars['String'];
  /** Order id for which the seats will be released. */
  orderId: Scalars['String'];
  /** Tickets to realease (will also release child tickets). */
  ticketIds: Array<Scalars['String']>;
};

export enum ReleaseStartCondition {
  Date = 'DATE',
  Manual = 'MANUAL'
}

export type ReleaseStatus = {
  __typename?: 'ReleaseStatus';
  message?: Maybe<Scalars['String']>;
  value: ReleaseStatusValue;
};

export enum ReleaseStatusValue {
  ReleaseHasEndedDueToDate = 'RELEASE_HAS_ENDED_DUE_TO_DATE',
  ReleaseIsPrivate = 'RELEASE_IS_PRIVATE',
  ReleaseIsPrivateWithFutureStartDate = 'RELEASE_IS_PRIVATE_WITH_FUTURE_START_DATE',
  ReleaseIsPublic = 'RELEASE_IS_PUBLIC'
}

export enum ReleaseType {
  Listed = 'LISTED',
  Outlet = 'OUTLET',
  Pos = 'POS',
  Presale = 'PRESALE',
  Referral = 'REFERRAL',
  Resale = 'RESALE',
  Unlisted = 'UNLISTED'
}

export type ReleaseZone = {
  __typename?: 'ReleaseZone';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The active status of the zone. Defaults to `false`. */
  isActive: Scalars['Boolean'];
  /** The pricing level of the zone. Will be set to `zone` for non-seated events. */
  pricingLevel: ZonePricingLevel;
  /** The list of ticket types of the release zone. */
  ticketTypes: Array<ReleaseZoneTicketType>;
  updatedAt: Scalars['DateTime'];
  zone: Zone;
};

export type ReleaseZoneInput = {
  id?: Maybe<Scalars['String']>;
  /** The active status of the zone seating. Defaults to `false`. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The pricing level of the release zone. Will be set to `zone` for non-seated events. */
  pricingLevel: ZonePricingLevel;
  /** The list of ticket types of the release zone. */
  ticketTypes: Array<ReleaseZoneTicketTypeInput>;
  zone: ReleaseZoneZoneInput;
};

/** Represents a ticket type in a release zone. */
export type ReleaseZoneTicketType = {
  __typename?: 'ReleaseZoneTicketType';
  /** The active status of the zone ticket. Defaults to `false`. */
  isActive: Scalars['Boolean'];
  isSoldOut: Scalars['Boolean'];
  maxPurchaseQuantity: Scalars['Int'];
  minPurchaseQuantity: Scalars['Int'];
  /** The price of the zone ticket. Returns null if `pricingLevel` of the parent is set to other than `zone`. */
  price?: Maybe<Scalars['Float']>;
  purchaseRestrictions: Array<PurchaseRestriction>;
  purchaseRestrictionsHelpText?: Maybe<Array<Scalars['String']>>;
  /** The sections of the zone ticket. Returns null if `pricingLevel` of the parent is set to other than `section`. */
  sections?: Maybe<Array<ZonePricingTypeSection>>;
  ticketAvailabilityStatus: TicketAvailabilityStatus;
  /** The ticket type id of the zone ticket. */
  ticketTypeId?: Maybe<Scalars['String']>;
};

export type ReleaseZoneTicketTypeInput = {
  /** The active status of the zone ticket type. Defaults to `false`. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The price of the zone ticket. Pass null if `ReleaseZoneInput.pricingLevel` is set to other than `zone`. */
  price?: Maybe<Scalars['Float']>;
  /** The sections of the zone ticket. Pass null if `ReleaseZoneInput.pricingLevel` is set to other than `section`. */
  sections?: Maybe<Array<ReleaseZoneTicketTypeSectionInput>>;
  /** The ticket type id of the zone ticket. */
  ticketTypeId: Scalars['String'];
};

export type ReleaseZoneTicketTypeSectionInput = {
  /** The section name. Should be equal to one of the sections defined in the event venue. */
  name: Scalars['String'];
  /** The price of the section ticket. */
  price?: Maybe<Scalars['Float']>;
  /** The section id. Should be equal to one of the sections defined in the event venue. */
  sectionId: Scalars['String'];
};

/** The zone belonging to a release zone */
export type ReleaseZoneZoneInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ReorderTicketTypeInput = {
  releaseZoneId: Scalars['String'];
  ticketTypeIds: Array<Scalars['String']>;
};

export type ReorderZoneInput = {
  venueId: Scalars['String'];
  zoneIds: Array<Scalars['String']>;
};

export enum ReportingFilterSource {
  Event = 'EVENT',
  Membership = 'MEMBERSHIP',
  Overall = 'OVERALL'
}

export enum ReportingFinancialSalesBreakdownSource {
  Event = 'EVENT',
  Membership = 'MEMBERSHIP'
}

export type RequestMagicLinkInput = {
  email: Scalars['String'];
  entry?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type RequestResetPasswordInput = {
  email: Scalars['String'];
};

export type RequestTicketTransferInput = {
  email: Scalars['String'];
  ticketId: Scalars['String'];
  /**
   * The price set by the seller for the ticket transfer. Must be between the seller transfer fee
   *     and the original line item price. If not supplied, the original line item price is used.
   */
  ticketSalePrice?: Maybe<Scalars['Float']>;
};

export type ResaleTicketMetrics = {
  __typename?: 'ResaleTicketMetrics';
  /** The mean and median prices that customers have said they would pay. Will be null if no data is present. */
  fansWouldPay?: Maybe<FansWouldPay>;
  numCurrentlyOnSale: Scalars['Float'];
  numInterestedFans: Scalars['Float'];
  numSold: Scalars['Float'];
  ticketTypeId: Scalars['String'];
};

/** input for the resale waitlist */
export type ResaleWaitlistInput = {
  email: Scalars['String'];
  eventId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  referralCode?: Maybe<Scalars['String']>;
  /** The resale ticket types */
  resaleTicketTypes?: Maybe<Array<ResaleWaitlistInputTicketTypes>>;
  usableForMarketing: Scalars['Boolean'];
  userAcquisition?: Maybe<UserAcquisition>;
};

export type ResaleWaitlistInputTicketTypes = {
  id: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type RewardSettingsDto = {
  referralOwnerRewardQuantity?: Maybe<Scalars['Float']>;
  referralOwnerRewardQuantityType: ReferralRewardQuantityType;
  referralUserRewardQuantity?: Maybe<Scalars['Float']>;
  referralUserRewardQuantityType: ReferralRewardQuantityType;
};

export enum Role {
  Admin = 'Admin',
  BackOfficeStaff = 'BackOfficeStaff',
  EventManager = 'EventManager',
  EventReporting = 'EventReporting',
  PosAdmin = 'PosAdmin',
  PosOperator = 'PosOperator',
  Sales = 'Sales',
  SalesOutlet = 'SalesOutlet',
  Scanner = 'Scanner',
  Superadmin = 'Superadmin',
  User = 'User',
  VenueAdmin = 'VenueAdmin'
}

export type SalesChannelGraphOutput = {
  __typename?: 'SalesChannelGraphOutput';
  backOffice: Scalars['Float'];
  online: Scalars['Float'];
  pos: Scalars['Float'];
  transactions: Scalars['Float'];
};

export type SalesOverviewOutput = {
  __typename?: 'SalesOverviewOutput';
  addonsSold: Scalars['Float'];
  cartAbandonment: Scalars['Float'];
  cartRecovered: Scalars['Float'];
  membershipsRenewed: Scalars['Float'];
  membershipsSold: Scalars['Float'];
  orders: Scalars['Float'];
  packagesSold: Scalars['Float'];
  pointsDistributed: Scalars['Float'];
  returningCustomers: Scalars['Float'];
  ticketCoverMembershipsSold: Scalars['Float'];
  ticketCoverOrdersSold: Scalars['Float'];
  ticketCoverTicketsSold: Scalars['Float'];
  ticketsComp: Scalars['Float'];
  ticketsPaid: Scalars['Float'];
  ticketsSold: Scalars['Float'];
  ticketsTransferred: Scalars['Float'];
};

export type Scan = {
  __typename?: 'Scan';
  createdAt: Scalars['DateTime'];
  direction: TicketScanDirection;
  event: Event;
  gate: Gate;
  hasScannedParentTicket: Scalars['Boolean'];
  id: Scalars['ID'];
  scannedAt: Scalars['DateTime'];
  scanner: User;
  type: TicketScanType;
  updatedAt: Scalars['DateTime'];
};

/** Scan status. */
export enum ScanStatus {
  Error = 'ERROR',
  Ok = 'OK',
  Warning = 'WARNING'
}

export type ScanTicketBulk = {
  eventId: Scalars['String'];
  /** Scanner gate */
  gateId: Scalars['String'];
  /** Scanner direction */
  scanDirection: TicketScanDirection;
  /** Ticket scan type */
  scanType?: Maybe<TicketScanType>;
  scannedAt: Scalars['DateTime'];
  /** Ticket number */
  ticketNumber: Scalars['String'];
};

export type ScanTicketBulkInput = {
  /** Scan data. */
  scans: Array<ScanTicketBulk>;
};

export type ScanTicketInput = {
  /** Scanner gate */
  gateId: Scalars['String'];
  /** Scanner direction */
  scanDirection: TicketScanDirection;
  /** Ticket scan type */
  scanType?: Maybe<TicketScanType>;
  /** Ticket number */
  ticketNumber: Scalars['String'];
};

export type ScanTicketOutput = {
  __typename?: 'ScanTicketOutput';
  message?: Maybe<Scalars['String']>;
  status: ScanStatus;
  ticket?: Maybe<Ticket>;
};

export type ScenarioInput = {
  scenarioId: Scalars['String'];
  /** The zones and Scenario inputs. */
  zones: Array<ScenarioZoneInput>;
};

export type ScenarioZoneInput = {
  /** The ticket types for the zone and Scenario inputs. */
  ticketTypes: Array<ScenarioZoneTicketTypeInput>;
  /** The zone related to this input */
  zoneId: Scalars['String'];
};

/** An object with ticket type id and forecast cost and split of sales */
export type ScenarioZoneTicketType = {
  __typename?: 'ScenarioZoneTicketType';
  /** The forecast price of a ticket */
  price: Scalars['Float'];
  /** The forecast split of sales for this ticket type as a percentage */
  splitPercentage: Scalars['Float'];
  /** Ticket type id */
  ticketTypeId: Scalars['String'];
};

export type ScenarioZoneTicketTypeInput = {
  /** The price of a seat in this zone for the ticket type. */
  price: Scalars['Float'];
  /** What percentage of the seats will be this ticket type */
  splitPercentage: Scalars['Float'];
  /** The ticket type of this input. */
  ticketTypeId: Scalars['String'];
};

export type Scout = {
  __typename?: 'Scout';
  createdAt: Scalars['DateTime'];
  exclude: Array<FilterSelection>;
  id: Scalars['ID'];
  includeOptedOutOfMarketing?: Maybe<Scalars['Boolean']>;
  /** Scout active status. */
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  promoteTo: Array<FilterSelection>;
  purpose?: Maybe<ScoutPurpose>;
  total: Scalars['Float'];
  /** Release Id of the source that we are tracking */
  trackReleaseId?: Maybe<Scalars['String']>;
  /** Used to show what we are tracking */
  trackSource?: Maybe<Scalars['String']>;
  /** Id of source */
  trackSourceId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ScoutCampaignOverviewOutput = {
  __typename?: 'ScoutCampaignOverviewOutput';
  addonsSold: Scalars['Float'];
  campaignSize: Scalars['Float'];
  cartAbandonment: Scalars['Float'];
  contactRate: Scalars['Float'];
  conversionRate: Scalars['Float'];
  emailClickRate: Scalars['Float'];
  emailOpenRate: Scalars['Float'];
  membershipsSold: Scalars['Float'];
  orders: Scalars['Float'];
  returningCustomers: Scalars['Float'];
  ticketsComp: Scalars['Float'];
  ticketsPaid: Scalars['Float'];
  ticketsSold: Scalars['Float'];
  todayRevenue: Scalars['Float'];
  totalRevenue: Scalars['Float'];
};

export type ScoutCommunication = {
  __typename?: 'ScoutCommunication';
  /** If a campaign is created from another campaign, then it should have a campaign Id. */
  campaign?: Maybe<ScoutCommunication>;
  conversions: Scalars['Float'];
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  id: Scalars['ID'];
  includeOptedOutOfMarketing?: Maybe<Scalars['Boolean']>;
  isActive: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  listId?: Maybe<Scalars['String']>;
  /** Required when type is email communication */
  mailchimpCampaignId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  processedAt?: Maybe<Scalars['DateTime']>;
  purpose: ScoutPurpose;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  /** If a campaign is created from a segment, then it should have a scout Id. */
  scout?: Maybe<Scout>;
  shouldUpdate?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Float']>;
  /** Release Id of the source that we are tracking */
  trackReleaseId?: Maybe<Scalars['String']>;
  /** Used to show what we are tracking */
  trackSource?: Maybe<ReportingFilterSource>;
  /** Id of source */
  trackSourceId?: Maybe<Scalars['String']>;
  /** email, sms or call */
  type: ScoutCommunicationType;
  updatedAt: Scalars['DateTime'];
};

/** The communication types for a scout campaign. */
export enum ScoutCommunicationType {
  Call = 'Call',
  Email = 'Email',
  Sms = 'SMS'
}

export type ScoutCommunicationUser = {
  __typename?: 'ScoutCommunicationUser';
  agentUser?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  history: HistoryConnection;
  id: Scalars['ID'];
  processedAt?: Maybe<Scalars['DateTime']>;
  remindAt?: Maybe<Scalars['DateTime']>;
  response?: Maybe<Scalars['JSON']>;
  scoutCommunication: ScoutCommunication;
  scoutUser?: Maybe<ScoutUser>;
  stage: ScoutStage;
  updatedAt: Scalars['DateTime'];
  user: User;
};


export type ScoutCommunicationUserHistoryArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<HistoryOrderByInput>;
};

export type ScoutCommunicationUserConnection = {
  __typename?: 'ScoutCommunicationUserConnection';
  edges: Array<ScoutCommunicationUserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ScoutCommunicationUserEdge = {
  __typename?: 'ScoutCommunicationUserEdge';
  cursor: Scalars['String'];
  node: ScoutCommunicationUser;
};

export type ScoutCommunicationsInput = {
  isActive?: Maybe<Scalars['Boolean']>;
};

export enum ScoutEventType {
  Event = 'Event',
  Membership = 'Membership'
}

/** The valid purposes for a scout. */
export enum ScoutPurpose {
  Communication = 'Communication',
  SellMembership = 'SellMembership',
  SellMerchandise = 'SellMerchandise',
  SellTickets = 'SellTickets'
}

/** The valid stages for a scout user. */
export enum ScoutStage {
  Completed = 'Completed',
  InProgress = 'InProgress',
  NoAnswer = 'NoAnswer',
  NoContact = 'NoContact'
}

export type ScoutUser = {
  __typename?: 'ScoutUser';
  agentUser?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  remindAt?: Maybe<Scalars['DateTime']>;
  scout: Scout;
  stage: ScoutStage;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type ScoutUserConnection = {
  __typename?: 'ScoutUserConnection';
  edges: Array<ScoutUserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ScoutUserEdge = {
  __typename?: 'ScoutUserEdge';
  cursor: Scalars['String'];
  node: ScoutUser;
};

export type SearchEventTicketsAppOutput = {
  __typename?: 'SearchEventTicketsAppOutput';
  tickets: Array<AppTicket>;
  totalCount: Scalars['Float'];
};

export type Season = {
  __typename?: 'Season';
  backgroundImageFullPage?: Maybe<ExtendedFile>;
  createdAt: Scalars['DateTime'];
  /** Description of the season */
  description?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  /** Events in the season */
  events: Array<Event>;
  id: Scalars['ID'];
  /** Season active status */
  isActive: Scalars['Boolean'];
  /** Season name */
  name: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  thumbnail?: Maybe<ExtendedFile>;
  ticketSoldTime?: Maybe<TicketSoldTime>;
  updatedAt: Scalars['DateTime'];
};


export type SeasonTicketSoldTimeArgs = {
  input: TicketSoldTimeInput;
};

/** The valid values for seat type (based on seats.io). */
export enum SeatType {
  GeneralAdmission = 'GENERAL_ADMISSION',
  Seat = 'SEAT'
}

export type Section = {
  __typename?: 'Section';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  /** The key field from Seats.io */
  seatsIoKey: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type SendBroadcastInput = {
  addonId?: Maybe<Array<Scalars['String']>>;
  audience?: Maybe<BroadcastAudience>;
  broadcastId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  fromName: Scalars['String'];
  marketingFilters?: Maybe<Array<MarketingFiltersInput>>;
  marketingType?: Maybe<BroadcastMarketingType>;
  membershipId?: Maybe<Scalars['String']>;
  releaseId?: Maybe<Array<Scalars['String']>>;
  richTextString: Scalars['String'];
  /** If the input contains send time, then this is a scheduled email */
  sendTime?: Maybe<Scalars['DateTime']>;
  status: BroadcastStatus;
  subject: Scalars['String'];
  /** If the input contains test email, then send test email */
  testEmail?: Maybe<Scalars['String']>;
  ticketTypeId?: Maybe<Array<Scalars['String']>>;
  transactionalType?: Maybe<BroadcastTransactionalType>;
  type: BroadcastType;
  zoneId?: Maybe<Array<Scalars['String']>>;
};

export type SendOrderConfirmationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type SendOrderConfirmationOutput = {
  __typename?: 'SendOrderConfirmationOutput';
  email: Scalars['String'];
};

export type SetPasswordForNewUserInput = {
  password: Scalars['String'];
};

/** Represents shipping labels. */
export type ShippingLabels = {
  __typename?: 'ShippingLabels';
  /** Shipping label for e-tickets. */
  eTicket?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets at venue. */
  printedTicketAtVenue?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets by courier. */
  printedTicketByCourier?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets by courier - rural. */
  printedTicketByCourierRural?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets by mail. */
  printedTicketByMail?: Maybe<Scalars['String']>;
};

/** Specifies the input fields for shipping labels. */
export type ShippingLabelsInput = {
  /** Shipping label for e-tickets. */
  eTicket?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets at venue. */
  printedTicketAtVenue?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets by courier. */
  printedTicketByCourier?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets by courier - rural. */
  printedTicketByCourierRural?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets by mail. */
  printedTicketByMail?: Maybe<Scalars['String']>;
};

export enum ShippingOption {
  Eticket = 'ETICKET',
  PrintedTicketAtVenue = 'PRINTED_TICKET_AT_VENUE',
  PrintedTicketByCourier = 'PRINTED_TICKET_BY_COURIER',
  PrintedTicketByCourierRural = 'PRINTED_TICKET_BY_COURIER_RURAL',
  PrintedTicketByMail = 'PRINTED_TICKET_BY_MAIL'
}

/** Represents shipping options. */
export type ShippingOptions = {
  __typename?: 'ShippingOptions';
  /** Shipping fee for e-tickets. */
  eTicket?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets at venue. */
  printedTicketAtVenue?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets by courier. */
  printedTicketByCourier?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets by courier - rural. */
  printedTicketByCourierRural?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets by mail. */
  printedTicketByMail?: Maybe<Scalars['Float']>;
};

/** Specifies the input fields for shipping options. Set to `null` for unavailability per item. */
export type ShippingOptionsInput = {
  /** Shipping fee for e-tickets. */
  eTicket?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets at venue. */
  printedTicketAtVenue?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets by courier. */
  printedTicketByCourier?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets by courier - rural. */
  printedTicketByCourierRural?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets by mail. */
  printedTicketByMail?: Maybe<Scalars['Float']>;
};

/** Represents a Shopify customer */
export type ShopifyCustomerOrder = {
  __typename?: 'ShopifyCustomerOrder';
  currencyCode: Scalars['String'];
  id: Scalars['String'];
  lineItems: Array<ShopifyLineItem>;
  total: Scalars['String'];
};

export type ShopifyIntegration = {
  __typename?: 'ShopifyIntegration';
  shopDomain: Scalars['String'];
  storefrontAccessToken: Scalars['String'];
};

export type ShopifyLineItem = {
  __typename?: 'ShopifyLineItem';
  id: Scalars['String'];
  quantity: Scalars['Float'];
  title: Scalars['String'];
};

export type ShopifySetupInput = {
  token: Scalars['String'];
};

export type ShopifyUser = {
  __typename?: 'ShopifyUser';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Shopify user ID */
  shopifyUserId: Scalars['String'];
  /** Total amount spent on Shopify store with currency code */
  totalSpent?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SocialAuthInput = {
  accessToken: Scalars['String'];
  socialAuthType: SocialAuthType;
};

/** Social authentication type */
export enum SocialAuthType {
  Facebook = 'Facebook',
  Google = 'Google'
}

export type SocialConnection = {
  __typename?: 'SocialConnection';
  edges: Array<SocialMentionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SocialMention = {
  __typename?: 'SocialMention';
  /** id of comment */
  commentId?: Maybe<Scalars['String']>;
  /** content of comment mention and media mention */
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** id of media */
  mediaId?: Maybe<Scalars['String']>;
  /** igid of organisation */
  recipientId: Scalars['String'];
  /** igsid of customer, exist for story mentions */
  senderId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** url of mentions */
  url: Scalars['String'];
  user?: Maybe<User>;
};

export type SocialMentionEdge = {
  __typename?: 'SocialMentionEdge';
  cursor: Scalars['String'];
  node: SocialMention;
};

export type SocialWhereInput = {
  endDate?: Maybe<Scalars['DateTime']>;
  mentionType?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type SplitOrderInput = {
  /** Multi buy promotion id for current order. */
  curMultiBuyId?: Maybe<Scalars['String']>;
  /** Delivery method for the new order */
  deliveryMethod: ShippingOption;
  /** List of the order's line items ids that need to be transfered. */
  lineItemIds: Array<Scalars['String']>;
  /** Multi buy promotion id for new order. */
  newMultiBuyId?: Maybe<Scalars['String']>;
  newTicketTypes?: Maybe<Array<SplitOrderTicketTypes>>;
  /** User id can be provided for new order */
  userId?: Maybe<Scalars['String']>;
};

export type SplitOrderTicketTypes = {
  lineItemId: Scalars['String'];
  ticketTypeId: Scalars['String'];
};

/** File storage type. */
export enum StorageType {
  Google = 'Google',
  Local = 'Local'
}

export type SummaryOverviewOutput = {
  __typename?: 'SummaryOverviewOutput';
  addonsSold: Scalars['Float'];
  cartAbandonment: Scalars['Float'];
  membershipsRenewed: Scalars['Float'];
  membershipsSold: Scalars['Float'];
  orders: Scalars['Float'];
  returningCustomers: Scalars['Float'];
  ticketsComp: Scalars['Float'];
  ticketsPaid: Scalars['Float'];
  ticketsSold: Scalars['Float'];
  ticketsSoldToday: Scalars['Float'];
  ticketsSoldYesterday: Scalars['Float'];
  todayRevenue: Scalars['Float'];
  totalRevenue: Scalars['Float'];
  yesterdayRevenue: Scalars['Float'];
};

export type Tag = {
  __typename?: 'Tag';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TagEntitiesInput = {
  entityIds?: Maybe<Array<Scalars['String']>>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
  /** tag ids */
  tags: Array<Scalars['String']>;
};

/** The tax calculation method, either inclusive or exclusive */
export enum TaxCalculationMethod {
  Exclusive = 'EXCLUSIVE',
  Inclusive = 'INCLUSIVE'
}

export type Ticket = {
  __typename?: 'Ticket';
  addon?: Maybe<AddonUnion>;
  birthDate?: Maybe<Scalars['DateTime']>;
  childTickets?: Maybe<Array<Ticket>>;
  comments?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Array<CustomField>>;
  dietaryRequirements?: Maybe<Scalars['String']>;
  digitalEventUrl?: Maybe<Scalars['String']>;
  digitalPassCode?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  downloadCount: Scalars['Float'];
  dunedinResidentialAddress?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<Scalars['String']>;
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  emergencyName?: Maybe<Scalars['String']>;
  /** Ticket event. Only available for single tickets. */
  event?: Maybe<Event>;
  firstName?: Maybe<Scalars['String']>;
  firstScanIn?: Maybe<Scan>;
  /**
   * Only populated if at least the relations ['event', 'event.venue', 'event.venue.gates'] are loaded,
   *       Also load ['event.eventAddon'] if used for getting scannable gates from event addon
   */
  gates?: Maybe<Array<Gate>>;
  gender?: Maybe<Gender>;
  /** Indicates if the claim for the seat has been released for the next membership. */
  hasReleasedNextMembershipClaim: Scalars['Boolean'];
  hasRenewedMembership: Scalars['Boolean'];
  id: Scalars['ID'];
  isHold: Scalars['Boolean'];
  /** Indicates if all required ticket holder naming fields have been filled in. */
  isNamed: Scalars['Boolean'];
  isViewable: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  lastScanOut?: Maybe<Scan>;
  lengthOfStay?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  lineItem?: Maybe<LineItem>;
  maxTransferPrice?: Maybe<Scalars['Float']>;
  memberSince?: Maybe<Scalars['DateTime']>;
  membership?: Maybe<Membership>;
  membershipNumber?: Maybe<Scalars['String']>;
  membershipSubscriptionId?: Maybe<Scalars['String']>;
  /** The membership type associated with the ticket. */
  membershipType?: Maybe<MembershipType>;
  optInToMarketing?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Order>;
  /** Ticket creation origin */
  origin: TicketOrigin;
  /** Parent ticket. Only available for single tickets that are child tickets of a membership. */
  parentTicket?: Maybe<Ticket>;
  parentTicketNumber?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** Timestamp when ticket was most recently preprinted. */
  preprint?: Maybe<Scalars['DateTime']>;
  purchaseRestrictionText?: Maybe<Array<Scalars['String']>>;
  qrCode: Scalars['String'];
  /** Ticket release. Only available for single tickets. */
  release?: Maybe<Release>;
  resellabilityState: TicketResellabilityState;
  scans?: Maybe<Array<Scan>>;
  /** Ticket seat label. Only available for seated events. */
  seatLabel?: Maybe<Scalars['String']>;
  /** Ticket seat section. Only available for seated events. */
  seatSection?: Maybe<Scalars['String']>;
  seatSectionEntity?: Maybe<Section>;
  /** Ticket seat tags. Only available for seated events. */
  seatTags?: Maybe<Array<Scalars['String']>>;
  /** Ticket seat type. Only available for seated events. */
  seatType?: Maybe<SeatType>;
  /** Ticket seat zone. Only available for seated events. */
  seatZone?: Maybe<Scalars['String']>;
  seatZoneEntity?: Maybe<Zone>;
  shouldBeNamed: Scalars['Boolean'];
  /** Ticket status */
  status: TicketStatus;
  studentId?: Maybe<Scalars['String']>;
  ticketCover?: Maybe<TicketTicketCover>;
  /** Ticket number */
  ticketNumber: Scalars['String'];
  ticketTransfer?: Maybe<TicketTransfer>;
  /** The ticket type associated with the ticket. */
  ticketType?: Maybe<TicketType>;
  /** Returns the transfer fee paid for the ticket. If the ticket was sold, it will include the seller fee. If the ticket was bought through a transfer, it will include the buyer fee. */
  transferFee?: Maybe<TicketTransferFee>;
  transferStatus?: Maybe<TicketTransferStatus>;
  updatedAt: Scalars['DateTime'];
  /** Ticket owner. Not all tickets have users. */
  user?: Maybe<User>;
  vaccinePass?: Maybe<VaccinePass>;
  walletTickets?: Maybe<WalletTicketOutput>;
};

export enum TicketAvailabilityStatus {
  AllocationExhausted = 'ALLOCATION_EXHAUSTED',
  Available = 'AVAILABLE',
  Soldout = 'SOLDOUT'
}

export type TicketConnection = {
  __typename?: 'TicketConnection';
  edges: Array<TicketEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TicketCoverAmount = {
  __typename?: 'TicketCoverAmount';
  totalFeeAmount: Scalars['Float'];
};

export type TicketCoverInput = {
  /** Whether ticket cover is enabled */
  isEnabled: Scalars['Boolean'];
  /** Promoter fee as percentage of ticket price */
  promoterFeePercentage: Scalars['Float'];
  /** Ticket cover purchase behaviour */
  purchaseBehaviour: TicketCoverPurchaseBehaviour;
};

export enum TicketCoverPurchaseBehaviour {
  Additional = 'Additional',
  Included = 'Included',
  Optional = 'Optional'
}

export type TicketEdge = {
  __typename?: 'TicketEdge';
  cursor: Scalars['String'];
  node: Ticket;
};

/** Ticket creation origin. */
export enum TicketOrigin {
  Import = 'IMPORT',
  Order = 'ORDER'
}

export type TicketResellabilityState = {
  __typename?: 'TicketResellabilityState';
  ticketResaleAllowed: Scalars['Boolean'];
  ticketTransferAllowed: Scalars['Boolean'];
};

/** Scanner direction. */
export enum TicketScanDirection {
  ScanIn = 'SCAN_IN',
  ScanOut = 'SCAN_OUT'
}

/** Ticket scan type. */
export enum TicketScanType {
  Digital = 'DIGITAL',
  Eticket = 'ETICKET',
  Hardcopy = 'HARDCOPY',
  Pos = 'POS'
}

export type TicketSoldTime = {
  __typename?: 'TicketSoldTime';
  /** The time last ticket was sold */
  endTime?: Maybe<Scalars['DateTime']>;
  /**
   * The source id.
   *     It is used by a useEffect hook on the front end.
   *     Only used to checked whether the new request has been made.
   *     Since for events or memberships with no tickets, the starttime and endtime will always be null.
   *     And if we only compare these two fields we don't know whether the source has been changed or not.
   */
  sourceId: Scalars['String'];
  /** The time first ticket was sold */
  startTime?: Maybe<Scalars['DateTime']>;
};

export type TicketSoldTimeInput = {
  source: ReportingFilterSource;
};

/** Ticket status. */
export enum TicketStatus {
  Active = 'ACTIVE',
  MarkedForResale = 'MARKED_FOR_RESALE',
  OrderCanceled = 'ORDER_CANCELED',
  OrderChanged = 'ORDER_CHANGED',
  Refunded = 'REFUNDED',
  Released = 'RELEASED',
  SeatChanged = 'SEAT_CHANGED',
  Transferred = 'TRANSFERRED',
  TransferPending = 'TRANSFER_PENDING'
}

export type TicketTicketCover = {
  __typename?: 'TicketTicketCover';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  orderTicketCover: OrderTicketCover;
  perTicketAmount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type TicketTransfer = {
  __typename?: 'TicketTransfer';
  buyerTransferFee: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  newLineItemPrice: Scalars['Float'];
  newOwnerEmail?: Maybe<Scalars['String']>;
  originalLineItemPrice: Scalars['Float'];
  sellerTransferFee: Scalars['Float'];
  transferGroupId?: Maybe<Scalars['String']>;
  transferStatus: TicketTransferStatus;
  updatedAt: Scalars['DateTime'];
};

export type TicketTransferFee = {
  __typename?: 'TicketTransferFee';
  fee: Scalars['Float'];
  type: TransferFeeType;
};

/** The status of the ticket transfer. */
export enum TicketTransferStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

/** Ticket type. */
export type TicketType = {
  __typename?: 'TicketType';
  /** Ticket fee. */
  bookingFee: Scalars['Float'];
  /** Ticket fee type. */
  bookingFeeType: FeeType;
  createdAt: Scalars['DateTime'];
  /** Ticket type allowed dates. */
  dates: Array<TicketTypeDate>;
  /** Restrictions for the ticket group. */
  description?: Maybe<Scalars['String']>;
  /** The amount of people in a group. Returns null if the ticket type is not grouped. */
  groupAmount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Mark a ticket type as high demand */
  inHighDemand: Scalars['Boolean'];
  /** Whether the ticket type is grouped. */
  isGrouped: Scalars['Boolean'];
  isResaleTicket: Scalars['Boolean'];
  /** The kind of the ticket type. */
  kind: TicketTypeKind;
  maxPurchaseQuantity?: Maybe<Scalars['Int']>;
  minPurchaseQuantity?: Maybe<Scalars['Int']>;
  /** Ticket group name. */
  name: Scalars['String'];
  namingFields: Array<FormField>;
  price?: Maybe<Scalars['Float']>;
  /** Print at home information for the ticket group. */
  printAtHomeInformation?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  resaleHiddenFromPublic: Scalars['Boolean'];
  /** The resale ticket type that this ticket will be transformed into when resold. */
  resaleTicketTypeId?: Maybe<Scalars['String']>;
  /** If this is a resale ticket, this is the array of ticketTypes that is grouped under this resale ticket */
  resaleTicketTypes: Array<TicketType>;
  /** Restrictions for the ticket group. */
  restrictions?: Maybe<Scalars['String']>;
  /** Mark a ticket type as sold out */
  soldOut: Scalars['Boolean'];
  /** Per ticket stadium levy ticket fee. */
  stadiumLevyFee?: Maybe<Scalars['Float']>;
  tags: Array<Tag>;
  /** Image for the bottom of the print at home ticket . Returns null if the not set. */
  ticketBanner?: Maybe<ExtendedFile>;
  /** Image to be shown on the customer portal of the ticket . Returns null if the not set. */
  ticketImage?: Maybe<ExtendedFile>;
  totalSold?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  /** Number of fans on waitlist */
  waitlistFanCount: Scalars['Int'];
};

/** Allowed date for the ticket type. */
export type TicketTypeDate = {
  __typename?: 'TicketTypeDate';
  /** Alternate entry time for the date. */
  alternateEntryTime?: Maybe<Scalars['String']>;
  eventDateId: Scalars['ID'];
};

/** The subset of ticket types the caller would like returned as part of the event */
export enum TicketTypeFilter {
  All = 'ALL',
  ResaleOnly = 'RESALE_ONLY'
}

/** Ticket kind for ticket type. */
export enum TicketTypeKind {
  Addon = 'ADDON',
  ComplimentaryNamed = 'COMPLIMENTARY_NAMED',
  ComplimentaryUnnamed = 'COMPLIMENTARY_UNNAMED',
  Standard = 'STANDARD'
}

export type TicketTypePurchaseTotal = {
  __typename?: 'TicketTypePurchaseTotal';
  ticketType: TicketType;
  total: Scalars['Int'];
};

export type TicketWhereInput = {
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
};

export type TokenSet = {
  __typename?: 'TokenSet';
  accessToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Float']>;
  idToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  tokenType?: Maybe<Scalars['String']>;
};

export type TopReferrersOutput = {
  __typename?: 'TopReferrersOutput';
  count?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type TopRepeatPurchasers = {
  __typename?: 'TopRepeatPurchasers';
  count?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type TopTagsOutput = {
  __typename?: 'TopTagsOutput';
  count?: Maybe<Scalars['Float']>;
  tag?: Maybe<Scalars['String']>;
};

export type TrackingCodeCreateInput = {
  eventId: Scalars['String'];
  title: Scalars['String'];
};

export type TrackingCodeOrderInput = {
  eventId: Scalars['String'];
  orderId: Scalars['String'];
  trackingCodeTitle: Scalars['String'];
};

export type TrackingCodeOutput = {
  __typename?: 'TrackingCodeOutput';
  createdAt: Scalars['DateTime'];
  eventId: Scalars['String'];
  id: Scalars['ID'];
  sales: Scalars['Float'];
  ticketsSold: Scalars['Float'];
  title: Scalars['String'];
  url: Scalars['String'];
  visitorCount: Scalars['Float'];
};

export type TrackingCodeVisitInput = {
  eventId: Scalars['String'];
  trackingCodeTitle: Scalars['String'];
};

export type TransactionOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
};

/** Transfer fee type. */
export enum TransferFeeType {
  Buyer = 'BUYER',
  Seller = 'SELLER'
}

export type TwoFactorAuthenticationCodeInput = {
  twoFactorAuthenticationCode: Scalars['String'];
};

export type TwoFactorAuthenticationSetupResult = {
  __typename?: 'TwoFactorAuthenticationSetupResult';
  dataURL: Scalars['String'];
  secret: Scalars['String'];
};

/** UnsubscribeReason reason. */
export enum UnsubscribeReason {
  NeverSubscribed = 'NeverSubscribed',
  NoLongerInterested = 'NoLongerInterested',
  Other = 'OTHER',
  TooFrequent = 'TooFrequent'
}

export type UpdateAutomationInput = {
  automationType?: Maybe<AutomationType>;
  isActive: Scalars['Boolean'];
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  targetReleaseId?: Maybe<Scalars['String']>;
  targetSource?: Maybe<ReportingFilterSource>;
  targetSourceId?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Float']>;
  templateName?: Maybe<Scalars['String']>;
  timeOfDay?: Maybe<Scalars['DateTime']>;
};

export type UpdateCreditsInput = {
  credits: Scalars['Float'];
};

/** Updates the info for a digital event/membership. */
export type UpdateDigitalInfoInput = {
  additionalEmailInfo?: Maybe<AdditionalEmailInfoInput>;
  /** The Postmark email template that is used to send digital pass codes. */
  emailTemplateId?: Maybe<Scalars['String']>;
  /** The url that digital pass codes are appended to in order to access the digital event/membership. */
  url?: Maybe<Scalars['String']>;
};

export type UpdateEventAddonInput = {
  /** Image banner specific for a ticket type on the print at home ticket */
  addonImage?: Maybe<Scalars['Upload']>;
  /** The brief description of the addon. */
  description?: Maybe<Scalars['String']>;
  /** Indicates whether naming the addon is possible or not. */
  enableNaming?: Maybe<Scalars['Boolean']>;
  /** Whether hide QR code on ticket. */
  hideQRCode?: Maybe<Scalars['Boolean']>;
  insideFee?: Maybe<Scalars['Float']>;
  /** The active status of the addon. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The name of the addon. */
  name?: Maybe<Scalars['String']>;
  namingFields?: Maybe<Array<FormFieldInput>>;
  /** The price associated with the addon. */
  price?: Maybe<Scalars['Float']>;
  /** Maximum quantity to be sold for the addon. */
  quantity?: Maybe<Scalars['Float']>;
  /** The restrictions applied to the addon. */
  restrictions?: Maybe<Scalars['String']>;
};

/** Updates an event. */
export type UpdateEventInput = {
  accessibility?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['Upload']>;
  backgroundImageFullPage?: Maybe<Scalars['Upload']>;
  /** Booking fee. */
  bookingFee?: Maybe<Scalars['Float']>;
  /** Booking fee type. */
  bookingFeeType?: Maybe<FeeType>;
  /** The fee the buyer pays for transferring a ticket. */
  buyerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** Whether changing seats is available. */
  canChangeSeats?: Maybe<Scalars['Boolean']>;
  /** Whether naming ticket is available. */
  canNameTicket?: Maybe<Scalars['Boolean']>;
  /** Whether renaming ticket is available. */
  canRenameTicket?: Maybe<Scalars['Boolean']>;
  /** Whether transfering ticket is available. */
  canTransferTicket?: Maybe<Scalars['Boolean']>;
  canUsePoints?: Maybe<Scalars['Boolean']>;
  /** The fee for changing seats for the event ticket. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** Whether event is checking for vaccine passports on entry */
  checkForVaccinePass?: Maybe<Scalars['Boolean']>;
  /** Event dates. */
  dates?: Maybe<Array<CreateEventDate>>;
  /** Event description. */
  description?: Maybe<Scalars['String']>;
  descriptionImage?: Maybe<Scalars['Upload']>;
  /** Represents additional info required to host a digital event. */
  digitalInfo?: Maybe<UpdateDigitalInfoInput>;
  emailNotification?: Maybe<Scalars['String']>;
  /** Whether release codes are visible on the event information page */
  enableReleaseCodes?: Maybe<Scalars['Boolean']>;
  /** Whether the waitlist is visible on the event information page */
  enableWaitlist?: Maybe<Scalars['Boolean']>;
  /** The kind of event. */
  eventKind?: Maybe<EventKind>;
  gaVenue?: Maybe<CreateGaEventVenueInput>;
  /** The payment gateways enabled for the event. */
  gateways?: Maybe<Array<Scalars['String']>>;
  hardCopyTicketBanner?: Maybe<Scalars['Upload']>;
  importantNotice?: Maybe<Scalars['String']>;
  /** Inside fees for the event */
  insideFeeDetails?: Maybe<Array<InsideFeeDetailsInput>>;
  invoiceInformation?: Maybe<Scalars['String']>;
  /** Event active status. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Event featured status. */
  isFeatured?: Maybe<Scalars['Boolean']>;
  /** Whether event is R-18. */
  isR18?: Maybe<Scalars['Boolean']>;
  listedReleaseOptions?: Maybe<ListedReleaseOptionsInput>;
  logo?: Maybe<Scalars['Upload']>;
  merchantReference?: Maybe<Scalars['String']>;
  pixelId?: Maybe<Scalars['String']>;
  presaleInformation?: Maybe<Scalars['String']>;
  printBanner1?: Maybe<Scalars['Upload']>;
  printBanner2?: Maybe<Scalars['Upload']>;
  redirectURL?: Maybe<Scalars['String']>;
  /** The renaming fee for the event ticket. */
  renamingFee?: Maybe<Scalars['Float']>;
  /** Event season. */
  season?: Maybe<Scalars['String']>;
  /** The fee the seller pays for transferring a ticket. */
  sellerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** Shipping options. */
  shippingOptions?: Maybe<ShippingOptionsInput>;
  tags?: Maybe<Array<Scalars['String']>>;
  termsAndConditions?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  ticketCover?: Maybe<TicketCoverInput>;
  /** Date at which tickets are viewable/printable from. */
  ticketsViewableFromDate?: Maybe<Scalars['String']>;
  /** Event title. */
  title?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  /** The end date of the presale registration. */
  waitlistEndDate?: Maybe<Scalars['DateTime']>;
  /** The start date of the presale registration. */
  waitlistStartDate?: Maybe<Scalars['DateTime']>;
  zonesAllocatedGates?: Maybe<Array<ZoneAllocatedGateInput>>;
};

export type UpdateEventMultiBuyPromotionInput = {
  buyQuantity?: Maybe<Scalars['Float']>;
  buyTicketTypeId?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  getQuantity?: Maybe<Scalars['Float']>;
  getTicketTypeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type UpdateEventPromoCodeInput = {
  /** The unique code that represents the promotion. */
  code?: Maybe<Scalars['String']>;
  /** The discount amount of the promotion. */
  discountAmount?: Maybe<Scalars['Float']>;
  /** The discount type associated with the promotion. */
  discountType?: Maybe<DiscountType>;
  enabled?: Maybe<Scalars['Boolean']>;
  /** The end date condition of the promotion. Returns `null` when inactive. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The maximum order amount condition of the promotion. Returns `null` when inactive. */
  maxOrderAmount?: Maybe<Scalars['Float']>;
  /** The maximum amount of use condition of the promotion. Returns `null` when inactive. */
  maxUsage?: Maybe<Scalars['Float']>;
  /** The minimum order amount condition of the promotion. Returns `null` when inactive. */
  minOrderAmount?: Maybe<Scalars['Float']>;
  /** The start date condition of the promotion. Returns `null` when inactive. */
  startDate?: Maybe<Scalars['DateTime']>;
  ticketTypes?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<EventPromoCodeType>;
};

/** Updates an EventForecst */
export type UpdateForecastInput = {
  eventId: Scalars['String'];
  /** The zones and forecast inputs. */
  scenarios: Array<ScenarioInput>;
  /** The zones and forecast inputs. */
  zones: Array<ForecastZoneInput>;
};

export type UpdateHoldOrderInput = {
  /** List of actions to perform on line items. */
  lineItemActions: Array<LineItemActionInput>;
  /** Multi buy promotion id. */
  multiBuyId?: Maybe<Scalars['String']>;
};

export type UpdateIntegrationInput = {
  bnplEnabled?: Maybe<Scalars['Boolean']>;
  bnplTransactionFeePercent?: Maybe<Scalars['Float']>;
  facebookPageId?: Maybe<Scalars['String']>;
  facebookTempToken?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentMethodType?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  secretKey?: Maybe<Scalars['String']>;
  transactionFee?: Maybe<Scalars['Float']>;
  transactionFee2?: Maybe<Scalars['Float']>;
  transactionFeeType?: Maybe<FeeType>;
  transactionFeeType2?: Maybe<FeeType>;
  type: IntegrationType;
  wcPxPostPublicKey?: Maybe<Scalars['String']>;
  wcPxPostSecretKey?: Maybe<Scalars['String']>;
  webhookUrl?: Maybe<Scalars['String']>;
};

export type UpdateMembershipAddonInput = {
  /** Image banner specific for a ticket type on the print at home ticket */
  addonImage?: Maybe<Scalars['Upload']>;
  /** The brief description of the addon. */
  description?: Maybe<Scalars['String']>;
  /** Indicates whether naming the addon is possible or not. */
  enableNaming?: Maybe<Scalars['Boolean']>;
  /** The active status of the addon. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The name of the addon. */
  name?: Maybe<Scalars['String']>;
  namingFields?: Maybe<Array<FormFieldInput>>;
  /** The price associated with the addon. */
  price?: Maybe<Scalars['Float']>;
  /** Maximum quantity to be sold for the addon. */
  quantity?: Maybe<Scalars['Float']>;
  /** The restrictions applied to the addon. */
  restrictions?: Maybe<Scalars['String']>;
};

export type UpdateMembershipInput = {
  accessibility?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['Upload']>;
  banner?: Maybe<Scalars['Upload']>;
  /** The booking fee for the membership. */
  bookingFee?: Maybe<Scalars['Float']>;
  /** The booking fee type of the membership. */
  bookingFeeType?: Maybe<FeeType>;
  /** Whether naming a membership is available. */
  canChangeSeats?: Maybe<Scalars['Boolean']>;
  /** Whether naming a membership is available. */
  canNameMembership?: Maybe<Scalars['Boolean']>;
  /** Whether renaming a membership is available. */
  canRenameMembership?: Maybe<Scalars['Boolean']>;
  /** Whether individual event tickets can be transferred. */
  canTransferTickets?: Maybe<Scalars['Boolean']>;
  canUsePoints?: Maybe<Scalars['Boolean']>;
  /** The fee for changing seats for the membership. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** The description of the membership. */
  description?: Maybe<Scalars['String']>;
  descriptionImage?: Maybe<Scalars['Upload']>;
  /** Represents additional info required to host a digital event. */
  digitalInfo?: Maybe<UpdateDigitalInfoInput>;
  emailNotification?: Maybe<Scalars['String']>;
  enableAutoRenew?: Maybe<Scalars['Boolean']>;
  /** The end date of the membership. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The events associated with the membership. */
  events?: Maybe<Array<Scalars['String']>>;
  /** The payment gateways enabled for the membership. */
  gateways?: Maybe<Array<Scalars['String']>>;
  /** Flag to determine if the membership is hidden from the public */
  hiddenFromPublic?: Maybe<Scalars['Boolean']>;
  importantNotice?: Maybe<Scalars['String']>;
  invoiceInformation?: Maybe<Scalars['String']>;
  /** The active status of the membership. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The kind of membership. */
  membershipKind?: Maybe<MembershipKind>;
  /** The zones of the seating. */
  membershipZones?: Maybe<Array<UpdateMembershipZoneInput>>;
  /** Name of the membership. */
  name?: Maybe<Scalars['String']>;
  /** The previous memberships related to the membership. */
  previousMemberships?: Maybe<Array<Scalars['String']>>;
  printBanner1?: Maybe<Scalars['Upload']>;
  printBanner2?: Maybe<Scalars['Upload']>;
  /** The renaming fee for the membership. */
  renamingFee?: Maybe<Scalars['Float']>;
  /** The season associated with the membership. */
  season?: Maybe<Scalars['String']>;
  /** Shipping options. */
  shippingOptions?: Maybe<ShippingOptionsInput>;
  showSchedule?: Maybe<Scalars['Boolean']>;
  /** The start date of the membership. */
  startDate?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Array<Scalars['String']>>;
  termsAndConditions?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  ticketCover?: Maybe<MembershipTicketCoverInput>;
};

export type UpdateMembershipMultiBuyPromotionInput = {
  buyMembershipTypeId?: Maybe<Scalars['String']>;
  buyQuantity?: Maybe<Scalars['Float']>;
  enabled?: Maybe<Scalars['Boolean']>;
  getMembershipTypeId?: Maybe<Scalars['String']>;
  getQuantity?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type UpdateMembershipPromoCodeInput = {
  /** The unique code that represents the promotion. */
  code?: Maybe<Scalars['String']>;
  /** The discount amount of the promotion. */
  discountAmount?: Maybe<Scalars['Float']>;
  /** The discount type associated with the promotion. */
  discountType?: Maybe<DiscountType>;
  enabled?: Maybe<Scalars['Boolean']>;
  /** The end date condition of the promotion. Returns `null` when inactive. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The maximum order amount condition of the promotion. Returns `null` when inactive. */
  maxOrderAmount?: Maybe<Scalars['Float']>;
  /** The maximum amount of use condition of the promotion. Returns `null` when inactive. */
  maxUsage?: Maybe<Scalars['Float']>;
  membershipTypes?: Maybe<Array<Scalars['String']>>;
  /** The minimum order amount condition of the promotion. Returns `null` when inactive. */
  minOrderAmount?: Maybe<Scalars['Float']>;
  /** The start date condition of the promotion. Returns `null` when inactive. */
  startDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<MembershipPromoCodeType>;
};

export type UpdateMembershipTypeEventTransferPriceInput = {
  membershipTypeId: Scalars['String'];
  membershipZoneId: Scalars['String'];
  prices: Array<MembershipTypeTransferPriceInput>;
};

export type UpdateMembershipTypeInput = {
  /** Restrictions for the membership type. */
  description?: Maybe<Scalars['String']>;
  isGuestCard?: Maybe<Scalars['Boolean']>;
  /** The kind of the membership type. */
  kind?: Maybe<MembershipTypeKind>;
  maxPurchaseQuantity?: Maybe<Scalars['Float']>;
  minPurchaseQuantity?: Maybe<Scalars['Float']>;
  /** Name of the membership type. */
  name?: Maybe<Scalars['String']>;
  namingFields?: Maybe<Array<FormFieldInput>>;
  /** Restrictions for the membership type. */
  restrictions?: Maybe<Scalars['String']>;
  /** The ticket fee. */
  ticketFee?: Maybe<Scalars['Float']>;
  /** The ticket fee type. */
  ticketFeeType?: Maybe<FeeType>;
};

export type UpdateMembershipZoneInput = {
  /** The  memebershipZone id. */
  id?: Maybe<Scalars['String']>;
  /** The active status of the membership seating. Defaults to `false`. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The list of membership types of the seating zone. */
  membershipTypes: Array<UpdateMembershipZoneMembershipTypeInput>;
  /** The pricing level of the seating zone. Will be set to `zone` for non-seated events. */
  pricingLevel: ZonePricingLevel;
  /** The  zone. */
  zone: MembershipZoneZoneInput;
};

export type UpdateMembershipZoneMembershipTypeInput = {
  /** The active status of the membership type. Defaults to `false`. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The membership type id of the seating. */
  membershipTypeId: Scalars['String'];
  /** The price of the zone per membership. Pass null if `pricingLevel` of the parent is set to other than `zone`. */
  price?: Maybe<Scalars['Float']>;
  /** The sections of the membership seating. Pass null if `pricingLevel` of the parent is set to other than `section`. */
  sections?: Maybe<Array<UpdateMembershipZoneMembershipTypeSectionInput>>;
};

export type UpdateMembershipZoneMembershipTypeSectionInput = {
  /** The section name. Should be equal to one of the sections defined in the venue. */
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  /** The section id. Should be equal to one of the sections defined in the venue. */
  sectionId: Scalars['String'];
};

export type UpdateOrderInput = {
  /** If the order is starting a membership subscription */
  automaticRenewMambership?: Maybe<Scalars['Boolean']>;
  /** Billing address provided by the customer. */
  billingAddress?: Maybe<AddressInput>;
  /** Buyer details. */
  buyerInformation?: Maybe<BuyerInformationInput>;
  /** The delivery method chosen by the customer. */
  deliveryMethod?: Maybe<ShippingOption>;
  /** If the purchaser has added dietary requirements */
  dietaryRequirements?: Maybe<Scalars['String']>;
  /** How the user heard about the event */
  heardAboutUs?: Maybe<HeardAboutUsType>;
  /** Heard about us details when heardAboutUs is other */
  heardAboutUsDetails?: Maybe<Scalars['String']>;
  /** Shipping address provided by the customer. */
  shippingAddress?: Maybe<AddressInput>;
  /** Whether the shipping address matches the billing address. */
  shippingAddressMatchesBillingAddress?: Maybe<Scalars['Boolean']>;
  /** The step the order currently is in. */
  step: Scalars['Float'];
  /**
   * Whether the order is updated on admin portal.
   *     If the user is an admin, we won't update the details even if this is true.
   */
  updateDetails?: Maybe<Scalars['Boolean']>;
  userAcquisition?: Maybe<UserAcquisition>;
};

export type UpdateOrderStatusInput = {
  compReason?: Maybe<Scalars['String']>;
  isComp?: Maybe<Scalars['Boolean']>;
  /** Selected payment method if order status is paid (allowed: cash, eftpos, invoice, other). */
  method?: Maybe<PaymentProvider>;
  /** Will send the customer an invoice email for hold orders if set */
  sendHoldOrderCustomerEmail?: Maybe<Scalars['Boolean']>;
  /** Will not send order confirmation email if set */
  skipOrderConfirmation?: Maybe<Scalars['Boolean']>;
  /** Order status to transition to. */
  status?: Maybe<OrderStatus>;
};

export type UpdateOrderTicketCoverInput = {
  includeTicketCover: Scalars['Boolean'];
};

export type UpdateOrganizationInput = {
  address?: Maybe<AddressInput>;
  branding?: Maybe<BrandingInput>;
  currency?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  helpLink?: Maybe<Scalars['String']>;
  landingPage?: Maybe<LandingPage>;
  marketing?: Maybe<MarketingInput>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  privacyPolicy?: Maybe<Scalars['String']>;
  /** Shipping options. */
  shippingLabels?: Maybe<ShippingLabelsInput>;
  showMembershipRenewal?: Maybe<Scalars['Boolean']>;
  showMemberships?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  supportInfo?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  termsAndConditions?: Maybe<Scalars['String']>;
};

export type UpdatePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type UpdatePointInput = {
  gatewayIds: Array<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['String']>;
};

export type UpdatePointItemInput = {
  amount: Scalars['Float'];
  backgroundImage?: Maybe<Scalars['Upload']>;
  banner?: Maybe<Scalars['Upload']>;
  description?: Maybe<Scalars['String']>;
  descriptionImage?: Maybe<Scalars['Upload']>;
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
  price: Scalars['Float'];
  thumbnail?: Maybe<Scalars['Upload']>;
};

export type UpdatePosUserInput = {
  accessCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type UpdateProfileInput = {
  accessEventIds?: Maybe<Array<Scalars['String']>>;
  accessMembershipIds?: Maybe<Array<Scalars['String']>>;
  /** Billing address provided by the user. */
  billingAddress?: Maybe<PartialAddressInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  companyName?: Maybe<Scalars['String']>;
  credits?: Maybe<Scalars['Float']>;
  /** Elevate users privileges from user to admin. */
  elevatePrivileges?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  instagramUsername?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  referralId?: Maybe<Scalars['String']>;
  /** Shipping address provided by the user. */
  shippingAddress?: Maybe<PartialAddressInput>;
  /** Whether the shipping address matches the billing address. */
  shippingAddressMatchesBillingAddress?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  taxNumber?: Maybe<Scalars['String']>;
  twoFactorAuthenticationSecret?: Maybe<Scalars['String']>;
  usableForMarketing?: Maybe<Scalars['Boolean']>;
  userAcquisition?: Maybe<UserAcquisition>;
};

/** Updates a Referral Campaign */
export type UpdateReferralCampaignDto = {
  eventId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  membershipId?: Maybe<Scalars['String']>;
  referralOwnerRewardQuantity?: Maybe<Scalars['Float']>;
  referralOwnerRewardQuantityType?: Maybe<ReferralRewardQuantityType>;
  referralOwnerRewardType?: Maybe<ReferralRewardType>;
  referralReleaseId?: Maybe<Scalars['String']>;
  referralUserRewardQuantity?: Maybe<Scalars['Float']>;
  referralUserRewardQuantityType?: Maybe<ReferralRewardQuantityType>;
  referralUserRewardType?: Maybe<ReferralRewardType>;
};

export type UpdateReleaseInput = {
  /** Can this release use Windcaves Account2Account functionality */
  allowAccount2Account?: Maybe<Scalars['Boolean']>;
  /** Can customers create hold orders for this release */
  allowHoldOrders?: Maybe<Scalars['Boolean']>;
  /** Are comps visible to public */
  allowPublicComp?: Maybe<Scalars['Boolean']>;
  /** Request purchasers  */
  askForDietaryRequirements?: Maybe<Scalars['Boolean']>;
  /** Ask how did you heard about us during the purchasing flow */
  askHowDidYouHearAboutUs?: Maybe<Scalars['Boolean']>;
  /** The end condition of the release. */
  endCondition?: Maybe<ReleaseEndCondition>;
  /** The end date of the release. Pass null if the endCondition is set to other than `date`. */
  endConditionDate?: Maybe<Scalars['DateTime']>;
  /** The quantity limit of the release. Pass null if the endCondition is set to other than `quantity`. */
  endConditionQuantity?: Maybe<Scalars['Float']>;
  /** The status of the release. */
  isActive?: Maybe<Scalars['Boolean']>;
  maxPurchaseQuantity?: Maybe<Scalars['Float']>;
  minPurchaseQuantity?: Maybe<Scalars['Float']>;
  /** Release name. */
  name?: Maybe<Scalars['String']>;
  /** The password for the unlisted release. Pass null if the release type is set to other than `unlisted`. */
  password?: Maybe<Scalars['String']>;
  presaleHeader?: Maybe<Scalars['Upload']>;
  /** The information of the unlisted release. Pass null if the `type` is set to other than `unlisted`. */
  presaleInformation?: Maybe<Scalars['String']>;
  presaleLogo?: Maybe<Scalars['Upload']>;
  /** The title of the unlisted release. Pass null if the `type` is set to other than `unlisted`. */
  presaleTitle?: Maybe<Scalars['String']>;
  releaseEventAddons?: Maybe<Array<Scalars['String']>>;
  /** The information that will be shown to customers on the ticketing page. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** The zones of the release. */
  releaseZones?: Maybe<Array<ReleaseZoneInput>>;
  /** The slug for the unlisted release. Pass null if the release type is set to other than `unlisted`. */
  slug?: Maybe<Scalars['String']>;
  /** The start condition of the release. */
  startCondition?: Maybe<ReleaseStartCondition>;
  /** The start date of the release. Pass null if the startCondition is set to other than `date`. */
  startConditionDate?: Maybe<Scalars['DateTime']>;
  /** Release type. */
  type?: Maybe<ReleaseType>;
};

export type UpdateReleaseZoneInput = {
  admission: Scalars['Boolean'];
  capacity?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type UpdateResaleConfigInput = {
  /** The fee the buyer pays for transferring a ticket. */
  buyerTransferringTicketFee?: Maybe<Scalars['Float']>;
  canTransferTicket: Scalars['Boolean'];
  eventId: Scalars['String'];
  /** The fee the seller pays for transferring a ticket. */
  sellerTransferringTicketFee?: Maybe<Scalars['Float']>;
  ticketResaleEnabled: Scalars['Boolean'];
  ticketResaleWaitlistEnabled: Scalars['Boolean'];
};

export type UpdateScoutCommunicationInput = {
  campaignId?: Maybe<Scalars['String']>;
  includeOptedOutOfMarketing?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  listId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  purpose?: Maybe<ScoutPurpose>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  segmentId?: Maybe<Scalars['String']>;
  shouldUpdate?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Float']>;
  trackReleaseId?: Maybe<Scalars['String']>;
  trackSource?: Maybe<ReportingFilterSource>;
  trackSourceId?: Maybe<Scalars['String']>;
  type?: Maybe<ScoutCommunicationType>;
};

export type UpdateScoutCommunicationUserInput = {
  stage: ScoutStage;
  usableForMarketing?: Maybe<Scalars['Boolean']>;
};

export type UpdateScoutInput = {
  exclude?: Maybe<Array<FilterSelectionInput>>;
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  promoteTo?: Maybe<Array<FilterSelectionInput>>;
};

export type UpdateSeasonInput = {
  backgroundImageFullPage?: Maybe<Scalars['Upload']>;
  /** Season description. */
  description?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Float']>;
  /** Season active status. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Season name. */
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['Upload']>;
};

export type UpdateTagInput = {
  name?: Maybe<Scalars['String']>;
};

export type UpdateTicketHolderInput = {
  /** Birth date of the ticket holder. */
  birthDate?: Maybe<Scalars['DateTime']>;
  /** Comments of the ticket holder. */
  comments?: Maybe<Scalars['String']>;
  /** Company name of the ticket holder (optional). */
  companyName?: Maybe<Scalars['String']>;
  customFields?: Maybe<Array<CustomFieldInput>>;
  /** Dietary requirements of the ticket holder. */
  dietaryRequirements?: Maybe<Scalars['String']>;
  /** Residential address in Dunedin */
  dunedinResidentialAddress?: Maybe<Scalars['String']>;
  /** Email of the ticket holder. */
  email?: Maybe<Scalars['String']>;
  /** Phone number of the emergency contact */
  emergencyContact?: Maybe<Scalars['String']>;
  /** Phone number of the emergency contact */
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  /** Name of the emergency contact */
  emergencyName?: Maybe<Scalars['String']>;
  /** First name of the ticket holder. */
  firstName?: Maybe<Scalars['String']>;
  /** Gender of the ticket holder. */
  gender?: Maybe<Gender>;
  /** Last name of the ticket holder. */
  lastName?: Maybe<Scalars['String']>;
  /** How long someone is staying at an event */
  lengthOfStay?: Maybe<Scalars['String']>;
  /** License plate of the ticket holder. */
  licensePlate?: Maybe<Scalars['String']>;
  /** Since when the ticket holder is a member. */
  memberSince?: Maybe<Scalars['DateTime']>;
  /** Membership number of the ticket holder. */
  membershipNumber?: Maybe<Scalars['String']>;
  optInToMarketing?: Maybe<Scalars['Boolean']>;
  /** Phone number of the ticket holder. */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Postal code of the ticket holder. */
  postalCode?: Maybe<Scalars['String']>;
  /** Student id of the ticket holder */
  studentId?: Maybe<Scalars['String']>;
  vaccinePass?: Maybe<Scalars['Upload']>;
};

export type UpdateTicketTypeInput = {
  /** Booking fee. */
  bookingFee?: Maybe<Scalars['Float']>;
  /** Booking fee type. */
  bookingFeeType?: Maybe<FeeType>;
  /** Event dates. */
  dates?: Maybe<Array<CreateTicketTypeDate>>;
  /** Restrictions for the ticket group. */
  description?: Maybe<Scalars['String']>;
  /** The amount of people in a group. Returns null if the ticket type is not grouped. */
  groupAmount?: Maybe<Scalars['Float']>;
  /** Mark a ticket type as high demand */
  inHighDemand?: Maybe<Scalars['Boolean']>;
  /** isActive for v2 event */
  isActive?: Maybe<Scalars['Boolean']>;
  /** isComplimentary for v2 event */
  isComplimentary?: Maybe<Scalars['Boolean']>;
  /** Whether the ticket type is grouped. */
  isGrouped?: Maybe<Scalars['Boolean']>;
  /** The kind of the ticket type. */
  kind?: Maybe<TicketTypeKind>;
  maxPurchaseQuantity?: Maybe<Scalars['Float']>;
  minPurchaseQuantity?: Maybe<Scalars['Float']>;
  /** Ticket group name. */
  name?: Maybe<Scalars['String']>;
  namingFields?: Maybe<Array<FormFieldInput>>;
  /** Price for v2 event */
  price?: Maybe<Scalars['Float']>;
  /** Print at home information for the ticket group. */
  printAtHomeInformation?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  /** releaseZoneId for v2 event */
  releaseZoneId?: Maybe<Scalars['String']>;
  /** Restrictions for the ticket group. */
  restrictions?: Maybe<Scalars['String']>;
  /** MMark a ticket type as sold out */
  soldOut?: Maybe<Scalars['Boolean']>;
  /** Per ticket stadium levy ticket fee. */
  stadiumLevyFee?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<Scalars['String']>>;
  /** Image banner specific for a ticket type on the print at home ticket */
  ticketBanner?: Maybe<Scalars['Upload']>;
  /** Image banner specific for a ticket type on the print at home ticket */
  ticketImage?: Maybe<Scalars['Upload']>;
};

export type UpdateUserInput = {
  accessEventIds?: Maybe<Array<Scalars['String']>>;
  accessMembershipIds?: Maybe<Array<Scalars['String']>>;
  /** Billing address provided by the user. */
  billingAddress?: Maybe<PartialAddressInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  companyName?: Maybe<Scalars['String']>;
  credits?: Maybe<Scalars['Float']>;
  /** Elevate users privileges from user to admin. */
  elevatePrivileges?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  instagramUsername?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  referralId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
  /** Shipping address provided by the user. */
  shippingAddress?: Maybe<PartialAddressInput>;
  /** Whether the shipping address matches the billing address. */
  shippingAddressMatchesBillingAddress?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  taxNumber?: Maybe<Scalars['String']>;
  twoFactorAuthenticationSecret?: Maybe<Scalars['String']>;
  usableForMarketing?: Maybe<Scalars['Boolean']>;
  userAcquisition?: Maybe<UserAcquisition>;
};

export type UpdateVenueInput = {
  accessibilityInfo?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  capacity?: Maybe<Scalars['Int']>;
  capacityUtilization?: Maybe<Scalars['Float']>;
  gates?: Maybe<Array<GateInput>>;
  location?: Maybe<CoordinateInput>;
  name?: Maybe<Scalars['String']>;
  zones?: Maybe<Array<ZoneInput>>;
};


export type UsSegment = {
  __typename?: 'UsSegment';
  accuracy: Scalars['Float'];
  /** Address that was provided for the Us Segment */
  address: PartialAddress;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  organization: Organization;
  /** A single letter representation of the segment a user is in */
  segment: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type User = {
  __typename?: 'User';
  LTV: Scalars['Float'];
  /** The IDs of the events the user can access when their account is scoped for only certain events */
  accessEventIds?: Maybe<Array<Scalars['String']>>;
  /** The IDs of the memberships the user can access when their account is scoped for only certain events */
  accessMembershipIds?: Maybe<Array<Scalars['String']>>;
  /** Billing address associated with the user. */
  billingAddress?: Maybe<PartialAddress>;
  birthDate?: Maybe<Scalars['DateTime']>;
  /** The company name of the user. */
  companyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** User credits balance */
  credits?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  eventTickets: Array<Ticket>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  gender?: Maybe<Gender>;
  hasMemberships: Scalars['Boolean'];
  hasPassword: Scalars['Boolean'];
  hasTickets: Scalars['Boolean'];
  hasVerifiedEmail: Scalars['Boolean'];
  history: HistoryConnection;
  id: Scalars['ID'];
  /** User instagram username */
  instagramUsername?: Maybe<Scalars['String']>;
  isLoggedInWithLinkedAccount: Scalars['Boolean'];
  isTwoFactorAuthenticationEnabled: Scalars['Boolean'];
  lastName: Scalars['String'];
  lastTimeLoggedInAt?: Maybe<Scalars['DateTime']>;
  membershipTickets: Array<Ticket>;
  orders: OrderConnection;
  organization: Organization;
  otherUnsubscribeReason?: Maybe<Scalars['String']>;
  /** Permision set based on roles */
  permissions: Array<Permission>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** User points balance */
  points: Scalars['Float'];
  /** unique uuid of a customer used to link to referral campaigns */
  referralId: Scalars['String'];
  /** The region of the user, derived from the postal code of billing address. */
  region?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  scoutCommunications: Array<ScoutCommunication>;
  /** Shipping address associated with the user. */
  shippingAddress?: Maybe<PartialAddress>;
  /** Whether the shipping address matches the billing address. */
  shippingAddressMatchesBillingAddress: Scalars['Boolean'];
  /** The Shopify user entry for the user if they have used the organization's Shopify store */
  shopifyUser?: Maybe<ShopifyUser>;
  tags: Array<Tag>;
  /** The tax number of the user. */
  taxNumber?: Maybe<Scalars['String']>;
  unsubscribeReason?: Maybe<UnsubscribeReason>;
  updatedAt: Scalars['DateTime'];
  usSegment?: Maybe<UsSegment>;
  usableForMarketing?: Maybe<Scalars['Boolean']>;
};


export type UserHistoryArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<HistoryOrderByInput>;
};


export type UserOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderOrderByInput>;
  where?: Maybe<OrderWhereInput>;
};

export type UserAcquisition = {
  referralCodeId?: Maybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: User;
};

export type UserOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
  firstName?: Maybe<OrderByDirection>;
  lastName?: Maybe<OrderByDirection>;
  updatedAt?: Maybe<OrderByDirection>;
};

export type UserWhereInput = {
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
};

export type VaccinePass = {
  __typename?: 'VaccinePass';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Raw payload of the vaccine pass */
  payload?: Maybe<Scalars['String']>;
  /** Decoded payload of the vaccine pass */
  payloadDecoded: Scalars['JSON'];
  /** Ticket that the pass is asscoiated with */
  ticket?: Maybe<Ticket>;
  updatedAt: Scalars['DateTime'];
  /** Pass owner */
  user?: Maybe<User>;
};

export type Venue = {
  __typename?: 'Venue';
  /** Accessibility information. */
  accessibilityInfo?: Maybe<Scalars['String']>;
  /** Venue address */
  address: Address;
  capacity?: Maybe<Scalars['Int']>;
  /** Venue capacity utilization as a percentage. */
  capacityUtilization: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  createdFromEvent: Scalars['Boolean'];
  /** Filter options that are available for a seated venue */
  filterOptions?: Maybe<Array<Scalars['String']>>;
  gates: Array<Gate>;
  /** Does this stadium require a per ticket levy to be charged */
  hasStadiumLevy: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Venue coordinates (lat, lng). */
  location?: Maybe<Coordinate>;
  name: Scalars['String'];
  /** Venue seating settings. */
  seating: VenueSeating;
  /** Seats.IO chart key */
  seatsChartKey?: Maybe<Scalars['String']>;
  trackHardCopyTickets: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  /** Seats.IO zones */
  zoneObjects?: Maybe<Array<ZoneObject>>;
  zones: Array<Zone>;
  /** Seats.IO zones */
  zonesOldField?: Maybe<Array<ZoneObject>>;
};

/** Venue seating settings. */
export enum VenueSeating {
  Digital = 'Digital',
  NonSeated = 'NonSeated',
  Seated = 'Seated'
}

export type VerifyEmailInput = {
  token: Scalars['String'];
};

export type VerifyMagicLinkOutput = {
  __typename?: 'VerifyMagicLinkOutput';
  redirectUrl: Scalars['String'];
};

export type VerifyPinPayment3Ds = {
  refId: Scalars['String'];
  sessionToken: Scalars['String'];
};

/** Represents a date in an event. */
export type Waitlist = {
  __typename?: 'Waitlist';
  createdAt: Scalars['DateTime'];
  event: Event;
  id: Scalars['ID'];
  organization: Organization;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type WaitlistOverviewOutput = {
  __typename?: 'WaitlistOverviewOutput';
  customersOptedIntoMarketing?: Maybe<Scalars['Float']>;
  numberOfOrders?: Maybe<Scalars['Float']>;
  numberOfSuccesfulUsers?: Maybe<Scalars['Float']>;
  numberOfTickets?: Maybe<Scalars['Float']>;
  totalCustomers?: Maybe<Scalars['Float']>;
  totalNewCustomers?: Maybe<Scalars['Float']>;
  totalReturningCustomers?: Maybe<Scalars['Float']>;
  totalRevenue?: Maybe<Scalars['Float']>;
  yetToConvert?: Maybe<Scalars['Float']>;
};

/** Represents a customer on a waitlist for an event. This customer may or may not exist as an actual user. */
export type WaitlistUser = {
  __typename?: 'WaitlistUser';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  event: Event;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  organization: Organization;
  phoneNumber?: Maybe<Scalars['String']>;
  /** The referral code used when registering for presale access. Will be tied to the referring user/waitlist user */
  referralCode?: Maybe<ReferralCode>;
  updatedAt: Scalars['DateTime'];
  usableForMarketing: Scalars['Boolean'];
  /** The user that is on the waitlist. This may be null if the user does not already exist in our system. */
  user: User;
};

/** Contains encoded ticket data in the format required for various wallet providers. */
export type WalletTicket = {
  __typename?: 'WalletTicket';
  /** (Apple): Binary contents of a .pkpass file to be used with Apple Wallet. */
  applePassPayload?: Maybe<Scalars['String']>;
  /** (Apple): authentication token to be used with Apple Wallet. */
  authenticationToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** (Google): The object ID to identify this ticket in the Google Wallet API. */
  googleObjectId?: Maybe<Scalars['String']>;
  /** (Google): A JWT-encoded representation of a ticket to be used with Google Pay. */
  googlePayJwtTicket?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The organization associated with the ticket. */
  organization?: Maybe<Organization>;
  /** The ticket that is encoded in the entity */
  ticket?: Maybe<Ticket>;
  updatedAt: Scalars['DateTime'];
};

export type WalletTicketOutput = {
  __typename?: 'WalletTicketOutput';
  /** The base64 encoded payload to add ticket to Apple Wallet */
  appleWalletPayload?: Maybe<Scalars['String']>;
  /** Google Wallet URL that contains the payload to add ticket to Google Wallet */
  googleWalletPayload?: Maybe<Scalars['String']>;
};

export type WindcavePaymentContext = {
  __typename?: 'WindcavePaymentContext';
  checkoutUrl: Scalars['String'];
  orderId: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
};

/** The payment result. */
export type WindcavePaymentResultOutput = {
  __typename?: 'WindcavePaymentResultOutput';
  redirectUrl: Scalars['String'];
};

/** Represents a Xero account. */
export type XeroAccountOutput = {
  __typename?: 'XeroAccountOutput';
  accountId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxType?: Maybe<Scalars['String']>;
};

/** Represents all available Xero accounts. */
export type XeroAllAccountsOutput = {
  __typename?: 'XeroAllAccountsOutput';
  availableAccounts?: Maybe<Array<XeroAccountOutput>>;
  currentAccountCode?: Maybe<Scalars['String']>;
};

export type XeroCallbackInput = {
  code: Scalars['String'];
};

export type XeroConfigurationInput = {
  accountCode: Scalars['String'];
  invoiceStatus: XeroInvoiceStatus;
  numDaysToPay: Scalars['Float'];
  sendFlicketInvoice: Scalars['Boolean'];
  useCompanyNameOnInvoice: Scalars['Boolean'];
};

export type XeroConfigurationOutput = {
  __typename?: 'XeroConfigurationOutput';
  accountCode?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<XeroInvoiceStatus>;
  numDaysToPay?: Maybe<Scalars['Float']>;
  sendFlicketInvoice?: Maybe<Scalars['Boolean']>;
  useCompanyNameOnInvoice?: Maybe<Scalars['Boolean']>;
};

/** Xero invoice status */
export enum XeroInvoiceStatus {
  Authorised = 'AUTHORISED',
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED'
}

export type Zone = {
  __typename?: 'Zone';
  admission: Scalars['Boolean'];
  /** Zone capacity. When used seats.io is source of truth */
  capacity?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  seated: Scalars['Boolean'];
  sectionModels?: Maybe<Array<Scalars['String']>>;
  sections?: Maybe<Array<Section>>;
  stand?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Allowed date for the zone. */
export type ZoneAllocatedDate = {
  __typename?: 'ZoneAllocatedDate';
  /** Alternate entry time for the zone. */
  alternateEntryTime: Scalars['String'];
  eventDateId: Scalars['ID'];
};

export type ZoneAllocatedDateInput = {
  /** Alternate entry time for the zone. */
  alternateEntryTime: Scalars['String'];
  eventDateId: Scalars['ID'];
};

export type ZoneAllocatedGate = {
  __typename?: 'ZoneAllocatedGate';
  availabilityCount?: Maybe<ZoneAllocationAvailability>;
  /** Alternate entry time for the zone. */
  dates?: Maybe<Array<ZoneAllocatedDate>>;
  gates?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  sections?: Maybe<Array<ZoneAllocatedGateSection>>;
  zoneId?: Maybe<Scalars['String']>;
};

export type ZoneAllocatedGateInput = {
  availabilityCount?: Maybe<ZoneAllocationAvailabilityInput>;
  /** Alternate entry time for the zone. */
  dates?: Maybe<Array<ZoneAllocatedDateInput>>;
  gates?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  sections?: Maybe<Array<ZoneAllocatedGateSectionInput>>;
  zoneId?: Maybe<Scalars['String']>;
};

export type ZoneAllocatedGateSection = {
  __typename?: 'ZoneAllocatedGateSection';
  gates?: Maybe<Array<Scalars['String']>>;
  /** Section name. */
  name: Scalars['String'];
  /** Section id. */
  sectionId?: Maybe<Scalars['String']>;
};

export type ZoneAllocatedGateSectionInput = {
  gates?: Maybe<Array<Scalars['String']>>;
  /** Section name. */
  name: Scalars['String'];
  /** Section id. */
  sectionId?: Maybe<Scalars['String']>;
};

export type ZoneAllocationAvailability = {
  __typename?: 'ZoneAllocationAvailability';
  available?: Maybe<Scalars['Float']>;
  booked?: Maybe<Scalars['Float']>;
  holdByMembership?: Maybe<Scalars['Float']>;
  notForSale?: Maybe<Scalars['Float']>;
};

export type ZoneAllocationAvailabilityInput = {
  available?: Maybe<Scalars['Float']>;
  booked?: Maybe<Scalars['Float']>;
  holdByMembership?: Maybe<Scalars['Float']>;
  notForSale?: Maybe<Scalars['Float']>;
};

/** The zone belonging to a venue (can only be set for non-seated venues) */
export type ZoneInput = {
  admission?: Maybe<Scalars['Boolean']>;
  capacity?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Float'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  stand?: Maybe<Scalars['String']>;
};

/** Seats.IO Zone. */
export type ZoneObject = {
  __typename?: 'ZoneObject';
  capacity?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sections: Array<Scalars['String']>;
  stand?: Maybe<Scalars['String']>;
};

export enum ZonePricingLevel {
  Section = 'SECTION',
  Zone = 'ZONE'
}

/** Represents a section level pricing. */
export type ZonePricingTypeSection = {
  __typename?: 'ZonePricingTypeSection';
  /** Section name. */
  name: Scalars['String'];
  /** The price of the section ticket type or the section membership type. */
  price?: Maybe<Scalars['Float']>;
  /** Section id. */
  sectionId: Scalars['String'];
};

export type FindOrderIdForTicketOutput = {
  __typename?: 'findOrderIdForTicketOutput';
  /** Order Id */
  orderId: Scalars['String'];
};

export type AddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'line1' | 'line2' | 'suburb' | 'postalCode' | 'city' | 'country'>
);

export type EventBaseFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'createdAt' | 'updatedAt'>
);

export type EventInfoFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'title' | 'description' | 'startDate' | 'endDate' | 'isFeatured' | 'importantNotice' | 'termsAndConditions' | 'accessibility' | 'isR18'>
  & { dates: Array<(
    { __typename?: 'EventDate' }
    & Pick<EventDate, 'startDate' | 'endDate'>
  )> }
);

export type ReleaseBaseFragment = (
  { __typename?: 'Release' }
  & Pick<Release, 'id' | 'createdAt' | 'updatedAt'>
);

export type ReleaseInfoFragment = (
  { __typename?: 'Release' }
  & Pick<Release, 'name' | 'slug' | 'type' | 'presaleTitle' | 'presaleInformation'>
);

export type VenueAddressFragment = (
  { __typename?: 'Venue' }
  & { address: (
    { __typename?: 'Address' }
    & AddressFragment
  ), location?: Maybe<(
    { __typename?: 'Coordinate' }
    & Pick<Coordinate, 'lat' | 'lng'>
  )> }
);

export type VenueBaseFragment = (
  { __typename?: 'Venue' }
  & Pick<Venue, 'id' | 'createdAt' | 'updatedAt'>
);

export type VenueInfoFragment = (
  { __typename?: 'Venue' }
  & Pick<Venue, 'name' | 'capacity' | 'accessibilityInfo'>
  & { gates: Array<(
    { __typename?: 'Gate' }
    & Pick<Gate, 'id' | 'name'>
  )> }
);

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName' | 'email'>
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ScanTicketMutationVariables = Exact<{
  eventId: Scalars['String'];
  input: ScanTicketInput;
}>;


export type ScanTicketMutation = (
  { __typename?: 'Mutation' }
  & { scanTicket: (
    { __typename?: 'ScanTicketOutput' }
    & Pick<ScanTicketOutput, 'status' | 'message'>
    & { ticket?: Maybe<(
      { __typename?: 'Ticket' }
      & Pick<Ticket, 'id' | 'createdAt' | 'ticketNumber' | 'parentTicketNumber' | 'status' | 'seatZone' | 'isHold'>
      & { event?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'title'>
      )>, gates?: Maybe<Array<(
        { __typename?: 'Gate' }
        & Pick<Gate, 'id'>
      )>>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'email'>
      )>, ticketType?: Maybe<(
        { __typename?: 'TicketType' }
        & Pick<TicketType, 'id' | 'name'>
      )>, scans?: Maybe<Array<(
        { __typename?: 'Scan' }
        & Pick<Scan, 'id' | 'updatedAt' | 'scannedAt' | 'direction' | 'type'>
        & { gate: (
          { __typename?: 'Gate' }
          & Pick<Gate, 'id'>
        ), scanner: (
          { __typename?: 'User' }
          & Pick<User, 'id'>
        ) }
      )>>, order?: Maybe<(
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'orderNumber'>
        & { buyerInformation?: Maybe<(
          { __typename?: 'BuyerInformation' }
          & Pick<BuyerInformation, 'firstName' | 'lastName' | 'email'>
        )> }
      )>, addon?: Maybe<(
        { __typename?: 'EventAddon' }
        & Pick<EventAddon, 'id' | 'name'>
      ) | { __typename?: 'MembershipAddon' }> }
    )> }
  ) }
);

export type ScanTicketBulkMutationVariables = Exact<{
  input: ScanTicketBulkInput;
}>;


export type ScanTicketBulkMutation = (
  { __typename?: 'Mutation' }
  & { scanTicketBulk: Array<(
    { __typename?: 'ScanTicketOutput' }
    & Pick<ScanTicketOutput, 'status' | 'message'>
  )> }
);

export type CurrentOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentOrganizationQuery = (
  { __typename?: 'Query' }
  & { currentOrganization: (
    { __typename?: 'CurrentOrganizationOutput' }
    & Pick<CurrentOrganizationOutput, 'id' | 'name'>
  ) }
);

export type FetchEventQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FetchEventQuery = (
  { __typename?: 'Query' }
  & { event: (
    { __typename?: 'Event' }
    & { venue: (
      { __typename?: 'Venue' }
      & VenueBaseFragment
      & VenueInfoFragment
      & VenueAddressFragment
    ) }
    & EventBaseFragment
    & EventInfoFragment
  ) }
);

export type ListEventsQueryVariables = Exact<{
  where?: Maybe<EventWhereInput>;
  orderBy?: Maybe<EventOrderByInput>;
}>;


export type ListEventsQuery = (
  { __typename?: 'Query' }
  & { events: (
    { __typename?: 'EventConnection' }
    & { edges: Array<(
      { __typename?: 'EventEdge' }
      & { node: (
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'title' | 'startDate'>
      ) }
    )> }
  ) }
);

export type MyScannedTicketsCountQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type MyScannedTicketsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'myScannedTicketsCount'>
);

export type NamesQueryVariables = Exact<{
  q?: Maybe<Scalars['String']>;
}>;


export type NamesQuery = (
  { __typename?: 'Query' }
  & { organizationNames: (
    { __typename?: 'OrganizationConnection' }
    & { edges: Array<(
      { __typename?: 'OrganizationEdge' }
      & { node: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type SearchTicketsQueryVariables = Exact<{
  eventId: Scalars['String'];
  q?: Maybe<Scalars['String']>;
}>;


export type SearchTicketsQuery = (
  { __typename?: 'Query' }
  & { searchTickets: (
    { __typename?: 'TicketConnection' }
    & { edges: Array<(
      { __typename?: 'TicketEdge' }
      & { node: (
        { __typename?: 'Ticket' }
        & Pick<Ticket, 'id' | 'firstName' | 'lastName' | 'email' | 'createdAt' | 'ticketNumber' | 'parentTicketNumber' | 'status' | 'isHold'>
        & { event?: Maybe<(
          { __typename?: 'Event' }
          & Pick<Event, 'id' | 'title'>
        )>, gates?: Maybe<Array<(
          { __typename?: 'Gate' }
          & Pick<Gate, 'id'>
        )>>, user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName' | 'email'>
        )>, ticketType?: Maybe<(
          { __typename?: 'TicketType' }
          & Pick<TicketType, 'id' | 'name'>
        )>, scans?: Maybe<Array<(
          { __typename?: 'Scan' }
          & Pick<Scan, 'id' | 'updatedAt' | 'scannedAt' | 'direction' | 'type'>
          & { gate: (
            { __typename?: 'Gate' }
            & Pick<Gate, 'id'>
          ), scanner: (
            { __typename?: 'User' }
            & Pick<User, 'id'>
          ) }
        )>>, order?: Maybe<(
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'orderNumber'>
          & { buyerInformation?: Maybe<(
            { __typename?: 'BuyerInformation' }
            & Pick<BuyerInformation, 'firstName' | 'lastName' | 'email'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type SearchTicketsByEventAppQueryVariables = Exact<{
  eventId: Scalars['String'];
  q?: Maybe<Scalars['String']>;
}>;


export type SearchTicketsByEventAppQuery = (
  { __typename?: 'Query' }
  & { searchTicketsByEventApp: (
    { __typename?: 'SearchEventTicketsAppOutput' }
    & { tickets: Array<(
      { __typename?: 'AppTicket' }
      & Pick<AppTicket, 'id' | 'firstName' | 'lastName' | 'email' | 'ticketNumber' | 'parentTicketNumber' | 'status' | 'gateIds' | 'isHold' | 'isEventAddon' | 'seatZone' | 'ticketTypeName' | 'eventAddonName' | 'orderNumber'>
      & { scans: Array<(
        { __typename?: 'AppTicketScan' }
        & Pick<AppTicketScan, 'id' | 'scannedAt' | 'direction' | 'type'>
      )>, buyerInformation?: Maybe<(
        { __typename?: 'AppTicketUserDetails' }
        & Pick<AppTicketUserDetails, 'firstName' | 'lastName' | 'email'>
      )>, user?: Maybe<(
        { __typename?: 'AppTicketUserDetails' }
        & Pick<AppTicketUserDetails, 'firstName' | 'lastName' | 'email'>
      )> }
    )> }
  ) }
);

export const EventBaseFragmentDoc = gql`
    fragment EventBase on Event {
  id
  createdAt
  updatedAt
}
    `;
export const EventInfoFragmentDoc = gql`
    fragment EventInfo on Event {
  title
  description
  dates {
    startDate
    endDate
  }
  startDate
  endDate
  isFeatured
  importantNotice
  termsAndConditions
  accessibility
  isR18
}
    `;
export const ReleaseBaseFragmentDoc = gql`
    fragment ReleaseBase on Release {
  id
  createdAt
  updatedAt
}
    `;
export const ReleaseInfoFragmentDoc = gql`
    fragment ReleaseInfo on Release {
  name
  slug
  type
  presaleTitle
  presaleInformation
}
    `;
export const AddressFragmentDoc = gql`
    fragment Address on Address {
  line1
  line2
  suburb
  postalCode
  city
  country
}
    `;
export const VenueAddressFragmentDoc = gql`
    fragment VenueAddress on Venue {
  address {
    ...Address
  }
  location {
    lat
    lng
  }
}
    ${AddressFragmentDoc}`;
export const VenueBaseFragmentDoc = gql`
    fragment VenueBase on Venue {
  id
  createdAt
  updatedAt
}
    `;
export const VenueInfoFragmentDoc = gql`
    fragment VenueInfo on Venue {
  name
  capacity
  accessibilityInfo
  gates {
    id
    name
  }
}
    `;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    id
    fullName
    email
  }
}
    `;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export const ScanTicketDocument = gql`
    mutation scanTicket($eventId: String!, $input: ScanTicketInput!) {
  scanTicket(eventId: $eventId, input: $input) {
    status
    message
    ticket {
      id
      createdAt
      ticketNumber
      parentTicketNumber
      status
      seatZone
      event {
        id
        title
      }
      gates {
        id
      }
      user {
        firstName
        lastName
        email
      }
      ticketType {
        id
        name
      }
      isHold
      scans {
        id
        updatedAt
        scannedAt
        gate {
          id
        }
        scanner {
          id
        }
        direction
        type
      }
      order {
        id
        orderNumber
        buyerInformation {
          firstName
          lastName
          email
        }
      }
      addon {
        ... on EventAddon {
          id
          name
        }
      }
    }
  }
}
    `;
export const ScanTicketBulkDocument = gql`
    mutation scanTicketBulk($input: ScanTicketBulkInput!) {
  scanTicketBulk(input: $input) {
    status
    message
  }
}
    `;
export const CurrentOrganizationDocument = gql`
    query currentOrganization {
  currentOrganization {
    id
    name
  }
}
    `;
export const FetchEventDocument = gql`
    query fetchEvent($id: String!) {
  event(id: $id) {
    ...EventBase
    ...EventInfo
    venue {
      ...VenueBase
      ...VenueInfo
      ...VenueAddress
    }
  }
}
    ${EventBaseFragmentDoc}
${EventInfoFragmentDoc}
${VenueBaseFragmentDoc}
${VenueInfoFragmentDoc}
${VenueAddressFragmentDoc}`;
export const ListEventsDocument = gql`
    query listEvents($where: EventWhereInput, $orderBy: EventOrderByInput) {
  events(where: $where, orderBy: $orderBy) {
    edges {
      node {
        id
        title
        startDate
      }
    }
  }
}
    `;
export const MyScannedTicketsCountDocument = gql`
    query myScannedTicketsCount($eventId: String!) {
  myScannedTicketsCount(eventId: $eventId)
}
    `;
export const NamesDocument = gql`
    query names($q: String) {
  organizationNames(where: {q: $q}) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export const SearchTicketsDocument = gql`
    query searchTickets($eventId: String!, $q: String) {
  searchTickets(eventId: $eventId, where: {q: $q}) {
    edges {
      node {
        id
        firstName
        lastName
        email
        createdAt
        ticketNumber
        parentTicketNumber
        status
        event {
          id
          title
        }
        gates {
          id
        }
        user {
          firstName
          lastName
          email
        }
        ticketType {
          id
          name
        }
        isHold
        scans {
          id
          updatedAt
          scannedAt
          gate {
            id
          }
          scanner {
            id
          }
          direction
          type
        }
        order {
          id
          orderNumber
          buyerInformation {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
}
    `;
export const SearchTicketsByEventAppDocument = gql`
    query searchTicketsByEventApp($eventId: String!, $q: String) {
  searchTicketsByEventApp(eventId: $eventId, q: $q) {
    tickets {
      id
      firstName
      lastName
      email
      ticketNumber
      parentTicketNumber
      status
      gateIds
      isHold
      isEventAddon
      seatZone
      ticketTypeName
      eventAddonName
      scans {
        id
        scannedAt
        direction
        type
      }
      orderNumber
      buyerInformation {
        firstName
        lastName
        email
      }
      user {
        firstName
        lastName
        email
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    login(variables: LoginMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoginMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoginMutation>(LoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'login', 'mutation');
    },
    logout(variables?: LogoutMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LogoutMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LogoutMutation>(LogoutDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'logout', 'mutation');
    },
    scanTicket(variables: ScanTicketMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ScanTicketMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ScanTicketMutation>(ScanTicketDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'scanTicket', 'mutation');
    },
    scanTicketBulk(variables: ScanTicketBulkMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ScanTicketBulkMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ScanTicketBulkMutation>(ScanTicketBulkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'scanTicketBulk', 'mutation');
    },
    currentOrganization(variables?: CurrentOrganizationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CurrentOrganizationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CurrentOrganizationQuery>(CurrentOrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'currentOrganization', 'query');
    },
    fetchEvent(variables: FetchEventQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FetchEventQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FetchEventQuery>(FetchEventDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'fetchEvent', 'query');
    },
    listEvents(variables?: ListEventsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ListEventsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListEventsQuery>(ListEventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listEvents', 'query');
    },
    myScannedTicketsCount(variables: MyScannedTicketsCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MyScannedTicketsCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MyScannedTicketsCountQuery>(MyScannedTicketsCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'myScannedTicketsCount', 'query');
    },
    names(variables?: NamesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NamesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NamesQuery>(NamesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'names', 'query');
    },
    searchTickets(variables: SearchTicketsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SearchTicketsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SearchTicketsQuery>(SearchTicketsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'searchTickets', 'query');
    },
    searchTicketsByEventApp(variables: SearchTicketsByEventAppQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SearchTicketsByEventAppQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SearchTicketsByEventAppQuery>(SearchTicketsByEventAppDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'searchTicketsByEventApp', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;