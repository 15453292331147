import retry from "fetch-retry";
import { useEffect, useState } from "react";

export const fetchWithRetry = () => retry(fetch, {
  retries: 4,
  retryDelay: 800
}); 

// Source material for those interested
// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
// https://dev.to/maxmonteil/is-your-app-online-here-s-how-to-reliably-know-in-just-10-lines-of-js-guide-3in7

function getOnlineStatus() {
  return typeof navigator !== "undefined" &&
    typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;
}

export default function useOnlineStatus() {
  let [onlineStatus, setOnlineStatus] = useState(getOnlineStatus());
  
  async function goOnline() {

    // avoid CORS errors with a request to your own origin
    const url = new URL(window.location.origin)

    // random value to prevent cached responses
    url.searchParams.set('date', Date.now().toString())

    try {
      await fetchWithRetry()(
        url.toString(),
        { method: 'HEAD' },
      )

      return setOnlineStatus(true);
    } catch {
      return;
    }
  }

  function goOffline() {
    setOnlineStatus(false);
  }
  useEffect(() => {
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);
    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  return onlineStatus;
}