import { useReduxState } from "re-reduced";
import * as selectors from "@state/selectors";
import * as Sentry from "@sentry/gatsby";

export const log = (...args: unknown[]) => {
  if (process.env.GATSBY_NODE_ENV === "development") console.log(...args)
}


export function useTelemetry() {

  const {
    event,
  } = useReduxState({
    event: selectors.getSelectedEvent
  });

 
  return function track(msg: string, tags: Record<string, any> = {}) {

    const stringifiedTags = Object.keys(tags).reduce((acc, key) => {
      const value = tags[key];
      acc[key] = typeof value !== "string" ? JSON.stringify(tags[key]) : value;
      return acc;
    }, {} as Record<string, string>);

    log("[Telemetry]", msg, stringifiedTags);

    const restrictedEventId = process.env.GATSBY_ENABLE_TELEMTRY_FOR_EVENT_ID;

    // Don't send to sentry if we have restricted to a specific event 
    // and the current event is not that event.
    if (restrictedEventId && event?.id === restrictedEventId) {
      Sentry.captureMessage(`[Telemetry] ${msg}`, {
        tags: stringifiedTags
      })
    }
    
    
  }
}

