import React from "react";
import Rodal from "rodal";
import styled from "styled-components";
import "rodal/lib/rodal.css";

import { assets, colors, colorsRGB, PRIMARY_COLOR } from "@util/constants";
import { Color } from "@util/types";

const Children = styled.div<{ color?: Color }>`
  position: relative;
  height: 100%;
`;

const Close = styled.img`
  position: absolute;
  right: 20px;
  top: 18px;
  z-index: 1;
`;

type animation =
  | "zoom"
  | "fade"
  | "flip"
  | "door"
  | "rotate"
  | "slideUp"
  | "slideDown"
  | "slideLeft"
  | "slideRight";

interface Props {
  children: JSX.Element;
  onClose: () => void;
  onAnimationEnd?: () => void;
  visible: boolean;
  width?: string;
  height?: string;
  animationType?: animation;
  backgroundColor?: Color;
  maskBackgroundColor?: Color;
}

const Modal = (props: Props) => {
  const {
    children,
    width,
    height,
    visible,
    animationType = "fade",
    onClose,
    onAnimationEnd,
  } = props;

  function maskBackground() {
    switch (props.maskBackgroundColor) {
      case "green":
        return colorsRGB.green();
      case "orange":
        return colorsRGB.orange();
      case "red":
        return colorsRGB.red();
      case "purple":
        return colorsRGB.purple();
      case "black":
        return colorsRGB.black();
      default:
        return colorsRGB.black(0.8);
    }
  }

  return (
    <Rodal
      showCloseButton={false}
      visible={visible}
      onClose={onClose}
      animation={animationType}
      duration={300}
      onAnimationEnd={onAnimationEnd}
      customMaskStyles={{ background: maskBackground() }}
      customStyles={{
        width: width ?? "80%",
        height: height ?? "85%",
        overflow: "auto",
        backgroundColor: colors[props.backgroundColor ?? "grey"],
        border: `3px solid
        ${props.maskBackgroundColor
            ? colors[props.maskBackgroundColor]
            : PRIMARY_COLOR
          }`,
      }}
    >
      <Close src={assets.close} onClick={onClose} />
      {visible && <Children>{children}</Children>}
    </Rodal>
  );
};

export default Modal;
