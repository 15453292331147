import * as Sentry from "@sentry/gatsby";
import { Action } from "re-reduced";
import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";

import {
  ScanTicketBulk,
  TicketScanDirection
} from "@graphql/sdk";
import actions from "@state/actions";
import {
  getEventId, getGateId,
  getGates,
  getScanDirection
} from "@state/selectors";
import { DeviceTicket, ScanPayload } from "@state/types";
import { scanStorage, ticketStorage } from "@util/cache";
import { validateScan } from "@util/validateScan";
import { sortBy } from "lodash";


export default function* scanDeviceTicket(
  action: Action<ScanPayload>
): SagaIterator {
  yield put(actions.scanDeviceTicket.request());

  try {
    const ticketNumber = action.payload.ticketNumber;
    let foundTicket: DeviceTicket = yield call(ticketStorage!.get, ticketNumber);

    if (!foundTicket) {

      // If the ticket is not found, search the parentTicketNumber because this might be a membership ticket
      // that is being scanned. We need to use a DB search for this as the parentTicketNumber is not indexed.
      const searchFunction = (ticketNumber: string, ticket: DeviceTicket) => {
        return ticket.parentTicketNumber === ticketNumber
      }
      const results = yield call(ticketStorage!.search, ticketNumber, searchFunction);

      if (results?.length > 0) {
        // There can only be 1 ticket per event that references the same parentTicketNumber
        // and because search returns an array, we will grab the first index if it exists.
        foundTicket = results[0];
      } else {

        const errorMsg = "Ticket number not found for this event";
        
        yield put(actions.scanTicketOnline.success({
          status: "issue",
          errorMsg
        }));
        
        Sentry.captureMessage(errorMsg, {
          extra: {
            ticketNumber
          }
        })
        return;
      }
    }

    const eventId = yield select(getEventId);

    // Find all of the pending scans for this ticket
    const pendingScans: ScanTicketBulk[] = yield call(scanStorage!.values);
    const pendingScansForTicket = pendingScans.filter(scan => scan.ticketNumber === ticketNumber);

    // Combine ticket scans and pending scans, ordered by scan time
    const allScans = sortBy([...foundTicket.scans, ...pendingScansForTicket], "scannedAt");

    const scanDirectionState: TicketScanDirection = yield select(getScanDirection);
    const scanDirection = action.payload.direction || scanDirectionState;
    const gateId = yield select(getGateId);
    const gates = yield select(getGates);
    const scanType = action.payload.scanType;

    // Create the new scan object
    const newScan: ScanTicketBulk = {
      eventId,
      gateId,
      ticketNumber,
      scanDirection,
      scanType,
      scannedAt: new Date().toISOString()
    };

    // validate the ticket status
    const { status, message } = validateScan({
      ticket: foundTicket,
      scan: newScan,
      allScans,
      gates
    })

    const scannedTicket = { errorMsg: message, ticket: foundTicket, status };
    yield put(actions.scanTicketOnline.success(scannedTicket));

    // Finally, if we are successful save the scan to local DB
    if (status === "success") {
      console.debug('Valid scan, save to storage')
      const scanEntries: [IDBValidKey, ScanTicketBulk][] = [...(pendingScansForTicket ?? []), newScan].map(scan => ([scan.scannedAt, scan]));
      yield call(scanStorage!.setMany, scanEntries);
      yield put(actions.updatePendingScanCount());
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
