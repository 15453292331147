import * as Sentry from "@sentry/gatsby";
import { createReducer, reduce } from "re-reduced";

import { TicketScanDirection } from "@graphql/sdk";
import { BaseURL } from "@util/constants";
import actions from "./actions";
import { RequestStatus, State } from "./types";

export const INITIAL_STATE: State = {
  cameraPermission: false,
  baseUrl: BaseURL!,
  loggedIn: false,
  online: false,
  route: "login",
  mode: "online",

  requestStatus: RequestStatus.Idle,
  fetchingEvent: false,
  scanDirection: TicketScanDirection.ScanIn,
  auth: { sessionExpiry: undefined },
  searchDeviceTickets: [],
  pendingScans: 0,
  syncing: false
};

let offlineStartTime: number = 0;

const reducer = createReducer<State>(
  [
    reduce(actions.clearState, (state) => ({
      ...INITIAL_STATE,
      cameraPermission: state.cameraPermission,
      online: state.online
    })),
    reduce(actions.login.request, (state) => ({
      ...state,
      requestStatus: RequestStatus.Pending,
    })),
    reduce(actions.login.failure, (state, payload) => ({
      ...state,
      requestStatus: RequestStatus.Failed,
      error: payload,
    })),
    reduce(actions.login.success, (state, payload) => ({
      ...state,
      loggedIn: true,
      requestStatus: RequestStatus.Success,
      user: {
        ...payload,
      },
    })),
    reduce(actions.updateAuth, (state, payload) => ({
      ...state,
      auth: {
        ...payload,
      },
    })),
    reduce(actions.updatePendingScanCount.success, (state, payload) => ({
      ...state,
      pendingScans: payload
    })),
    reduce(actions.logout.request, (state) => ({
      ...state,
      requestStatus: RequestStatus.Pending,
    })),
    reduce(actions.logout.failure, (state, payload) => ({
      ...state,
      requestStatus: RequestStatus.Failed,
      error: payload,
    })),
    reduce(actions.logout.success, (state) => ({
      ...state,
      loggedIn: false,
      requestStatus: RequestStatus.Success,
    })),
    reduce(actions.getEvents.request, (state) => ({
      ...state,
      requestStatus: RequestStatus.Pending,
    })),
    reduce(actions.getEvents.failure, (state, payload) => ({
      ...state,
      requestStatus: RequestStatus.Failed,
      error: payload,
      events: undefined,
    })),
    reduce(actions.getEvents.success, (state, payload) => ({
      ...state,
      requestStatus: RequestStatus.Success,
      events: payload,
    })),
    reduce(actions.fetchEvent.request, (state) => ({
      ...state,
      fetchingEvent: true
    })),
    reduce(actions.fetchEvent.failure, (state, payload) => ({
      ...state,
      fetchingEvent: false,
      error: payload,
      selectedEvent: undefined,
    })),
    reduce(actions.fetchEvent.success, (state, payload) => ({
      ...state,
      fetchingEvent: false,
      selectedEvent: payload,
      route: "scanner",
    })),
    reduce(actions.selectGateId, (state, payload) => ({
      ...state,
      gateId: payload,
    })),
    reduce(actions.setScanDirection, (state, payload) => ({
      ...state,
      scanDirection: payload,
    })),
    reduce(actions.updateRoute, (state, payload) => ({
      ...state,
      route: payload,
    })),
    reduce(actions.updateLocal, (state, payload) => ({
      ...state,
      localServer: {
        ...state.localServer,
        ...payload,
      },
    })),
    reduce(actions.updateOnline, (state, payload) => {
      
      if (payload === true && offlineStartTime > 0) {
        const offlineDuration = Date.now() - offlineStartTime;

        Sentry.captureMessage("User back online", {
          tags: {
            offlineDuration
          }
        })
        offlineStartTime = 0;
      } else {
        offlineStartTime = Date.now();
      }
      
      return {
        ...state,
        online: payload,
      }
    }),
    reduce(actions.fetchSearchTicketsByEventApp.request, (state) => ({
      ...state,
      requestStatus: RequestStatus.Pending,
    })),
    reduce(actions.fetchSearchTicketsByEventApp.failure, (state, payload) => ({
      ...state,
      requestStatus: RequestStatus.Failed,
      error: payload,
    })),
    reduce(actions.fetchSearchTicketsByEventApp.success, (state, payload) => ({
      ...state,
      requestStatus: RequestStatus.Success,
      error: undefined,
      deviceTickets: payload,
    })),
    reduce(actions.setSuccessMessage, (state, payload) => ({
      ...state,
      successMessage: payload,
    })),
    reduce(actions.setErrorMessage, (state, payload) => ({
      ...state,
      error: payload,
    })),
    reduce(actions.rehydrateTicketsByEventApp, (state, payload) => ({
      ...state,
      deviceTickets: payload,
    })),
    reduce(actions.setMode.success, (state, payload) => ({
      ...state,
      mode: payload,
    })),
    reduce(actions.scanTicketOnline.request, (state) => ({
      ...state,
      requestStatus: RequestStatus.Pending,
    })),
    reduce(actions.scanTicketOnline.failure, (state, payload) => ({
      ...state,
      requestStatus: RequestStatus.Failed,
      error: payload,
    })),
    reduce(actions.scanTicketOnline.success, (state, payload) => ({
      ...state,
      requestStatus: RequestStatus.Success,
      error: undefined,
      scannedTicket: payload,
    })),
    reduce(actions.clearScanTicket, (state) => ({
      ...state,
      scannedTicket: undefined,
    })),
    reduce(actions.scanDeviceTicket.success, (state) => ({
      ...state,
      error: undefined
    })),
    reduce(actions.searchDeviceTickets.success, (state, payload) => ({
      ...state,
      searchDeviceTickets: payload,
    })),
    reduce(actions.searchDeviceTickets.failure, (state, payload) => ({
      ...state,
      requestStatus: RequestStatus.Failed,
      error: payload,
    })),
    reduce(actions.syncPendingScans.request, (state) => ({
      ...state,
      syncing: true
    })),
    reduce(actions.syncPendingScans.failure, (state) => ({
      ...state,
      syncing: false
    })),
    reduce(actions.syncPendingScans.success, (state) => ({
      ...state,
      syncing: false
    })),
    reduce(actions.setBaseUrl, (state, payload) => ({
      ...state,
      baseUrl: payload,
    })),
    reduce(actions.setCameraPermission, (state, payload) => ({
      ...state,
      cameraPermission: payload,
    })),
    reduce(actions.setScannedTicketError, (state, payload) => ({
      ...state,
      scannedTicket: {
        ticket: state.scannedTicket?.ticket,
        status: "error",
        errorMsg: payload,
      },
    })),
  ],
  INITIAL_STATE
);

export default reducer;
