import * as Sentry from "@sentry/gatsby";
import { SagaIterator } from "redux-saga";
import { call } from "redux-saga/effects";
import { Action } from "re-reduced";

import { DeviceTicket, UpdateTicketPayload } from "@state/types";
import { ticketStorage } from "@util/cache";



/**
 * Updates a local ticket in the DB.
 * 
 * Uses get and then set as opposed to update because update does not
 * create the item if it deosn't exist.
 * 
 */
export default function* updateLocalTicket(action: Action<UpdateTicketPayload>): SagaIterator {

  const { ticket, ticketNumber } = action.payload;

  try {
    const localTicket = yield call(ticketStorage!.get, ticketNumber);

    const updatedTicket: DeviceTicket = {
      ...localTicket,
      ...ticket,
      scans: [
        ...(localTicket && localTicket?.scans ? localTicket.scans : []),
        ...(ticket && ticket?.scans ? ticket.scans : [])
      ]
    }
    yield call(ticketStorage!.set, ticketNumber, updatedTicket);
  } catch (error) {
    Sentry.captureException(error);
  }
}
