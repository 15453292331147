import { Action } from "re-reduced";
import { SagaIterator } from "redux-saga";
import { call, put, select } from "redux-saga/effects";

import { TicketScanDirection } from "@graphql/sdk";
import actions from "@state/actions";
import { getOrgId } from "@state/selectors";
import * as api from "@util/api";
import { getErrorMessage } from "@lib/graphql-error";

export default function* fetchEvent(action: Action<string>): SagaIterator {
  const organizationId = yield select(getOrgId);
  yield put(actions.fetchEvent.request());
  try {
    const event = yield call(api.fetchEvent, organizationId, action.payload);
    yield put(actions.fetchEvent.success(event));
    yield put(actions.selectGateId(event.venue.gates[0].id));
    yield put(actions.setScanDirection(TicketScanDirection.ScanIn));
  } catch (error) {
    yield put(actions.fetchEvent.failure(getErrorMessage(error)));
  }
}
