import React from "react";
import styled from "styled-components";

import { colors } from "@util/constants";
import { Color } from "@util/types";

const Loader = styled.div<{
  baseColor?: Color;
  color?: Color;
  dimensions?: { width: number; height: number };
}>`
  border: 2px solid
    ${(props) => (props.baseColor ? colors[props.baseColor] : colors.orange)};
  border-radius: 50%;
  border-top: 3px solid
    ${(props) => (props.color ? colors[props.color] : colors.transparent)};
  width: ${(props) =>
    props.dimensions && props.dimensions.width ? props.dimensions.width : 40}px;
  height: ${(props) =>
    props.dimensions && props.dimensions.height
      ? props.dimensions.height
      : 40}px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
  transform-origin: 50% 51%;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface Props {
  baseColor?: Color;
  color?: Color;
  dimensions?: { width: number; height: number };
}

const Loading = (props: Props) => {
  return (
    <Loader
      color={props.color}
      baseColor={props.baseColor}
      dimensions={props.dimensions}
    />
  );
};

export default Loading;
