import React from "react";
import store, { persistor } from "./src/state/createStore";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Layout from "./src/components/shared/layout";

export default ({ element, props }: any) => {
  if (typeof window === "undefined") {
    return <Provider store={store}>{element}</Provider>;
  }
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Layout {...props}>{element}</Layout>
      </PersistGate>
    </Provider>
  );
};
