import axios, { AxiosError, AxiosResponse } from "axios";

import { ListEventsQuery, ListEventsQueryVariables, NamesQuery, SearchTicketsByEventAppQuery } from "@graphql/sdk";
import { sdk } from "@lib/graphql";
import { getStoreBaseUrl } from "@util/helper";
import {
  getLocalStorageWithKey,
  handlePromise,
  setToLocalStorageWithKey
} from "./helper";
import {
  LoginParams,
  ScanTicketParams,
  SearchTicketsByEventParams,
  SyncTicketsParams
} from "./interfaces";

// const apiCred = require("../../functions/constants");

export const MOCK_SCAN_URL = "/.netlify/functions/scan";
export const MOCK_LOGIN_URL = "/.netlify/functions/login";
export const MOCK_EVENT_URL = "/.netlify/functions/event";

export const searchOrganizations = async (search: string) => {
  const baseUrl = getStoreBaseUrl();

  const { data, error } = await handlePromise(() =>
    sdk({ baseUrl }).names({ q: search })
  );

  return data ? data : error;
};

export async function searchTickets(orgId: string, eventId: string, q: string) {
  const baseUrl = getStoreBaseUrl();
  const input = { eventId, q };
  const { data, error } = await handlePromise(() =>
    sdk({ baseUrl, orgId }).searchTicketsByEventApp(input)
  );

  return data ? data : error;
}

export function isSearchTicketsByEventAppQuery(
  response: SearchTicketsByEventAppQuery | any
): response is SearchTicketsByEventAppQuery {
  return (
    (response as SearchTicketsByEventAppQuery).searchTicketsByEventApp !==
    undefined
  );
}

export function isNamesQuery(
  response: NamesQuery | any
): response is NamesQuery {
  return (response as NamesQuery).organizationNames !== undefined;
}

// new api for sagas
export function login(params: LoginParams) {
  const baseUrl = getStoreBaseUrl();
  const { email, password, orgId } = params;
  const input = { email, password };

  return sdk({ baseUrl, orgId }).login({ input });
}


export const getCurrentOrganization = async (orgId: string) => {
  const baseUrl = getStoreBaseUrl();
  return sdk({ baseUrl, orgId }).currentOrganization();
}

export function logout(orgId: string) {
  const baseUrl = getStoreBaseUrl();
  return sdk({ baseUrl, orgId }).logout();
}

export function fetchEvent(orgId: string, eventId: string) {
  const baseUrl = getStoreBaseUrl();
  return sdk({ baseUrl, orgId }).fetchEvent({ id: eventId }).then(res => res.event);
}

export type ListEvent = ListEventsQuery["events"]["edges"][number]["node"];

export function listEvents(orgId: string, params: ListEventsQueryVariables) {
  const baseUrl = getStoreBaseUrl();
  return sdk({ baseUrl, orgId }).listEvents(params).then(result => result?.events?.edges.map(({ node }) => node));
}

export function searchTicketsByEventApp(params: SearchTicketsByEventParams) {
  const { variables, orgId } = params;
  const baseUrl = getStoreBaseUrl();
  return sdk({ baseUrl, orgId }).searchTicketsByEventApp(variables);
}

export function syncTickets(params: SyncTicketsParams) {
  const { orgId, scans } = params;
  const baseUrl = getStoreBaseUrl();
  return sdk({ baseUrl, orgId }).scanTicketBulk({ input: { scans } });
}

export function scanTicket({
  ticketNumber,
  scanDirection,
  gateId,
  eventId,
  orgId,
  scanType,
}: ScanTicketParams) {
  const baseUrl = getStoreBaseUrl();
  const input = {
    gateId,
    scanDirection,
    scanType,
    ticketNumber,
  };

  return sdk({ baseUrl, orgId }).scanTicket({
    eventId,
    input: {
      ...input,
    },
  });
}

// Mock api using api middleware
const saveRequestToLocal = async (
  url: string,
  scanIn?: boolean,
  ticketNumber?: string,
  gateId?: string
) => {
  const scanType = ticketNumber?.includes("-1") ? "HARDCOPY" : "DIGITAL";
  const storedRequests = getLocalStorageWithKey("requests");
  const requestToSave = {
    url,
    ticketNumber,
    scanType,
    gateId,
    timeStamp: new Date(),
    scanDirection: scanIn ? "SCAN_IN" : "SCAN_OUT",
  };

  if (storedRequests !== undefined && storedRequests.length > 0) {
    await setToLocalStorageWithKey("requests", [
      ...storedRequests,
      requestToSave,
    ]);
  } else {
    await setToLocalStorageWithKey("requests", [requestToSave]);
  }
};

export const mockAPI = async (
  url: string,
  scanIn?: boolean,
  ticketNumber?: string,
  gateId?: string
) => {
  const scanType = ticketNumber?.includes("-1") ? "HARDCOPY" : "DIGITAL";
  // saving to localStorage
  saveRequestToLocal(url, scanIn, ticketNumber, gateId);

  // call api
  const body =
    url === MOCK_SCAN_URL
      ? {
          scanType,
          ticketNumber: ticketNumber
            ? ticketNumber.replace("-1", "")
            : undefined,
          scanDirection: scanIn ? "SCAN_IN" : "SCAN_OUT",
          gateId: gateId,
        }
      : undefined;

  return axios
    .post(url, { data: body })
    .then((response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      console.log({ error });
      return error;
    });
};

export function isResponse(
  response: AxiosResponse | AxiosError
): response is AxiosResponse {
  return (response as AxiosResponse).data !== undefined;
}

export const getLocalTicketList = async (url: string) => {
  return axios
    .post(`${url}`)
    .then((response: AxiosResponse) => {
      return response;
    })
    .catch((error: AxiosError) => {
      console.log({ error });
      return error;
    });
};
