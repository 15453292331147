import {
  Dispatch,
  SetStateAction, useEffect, useRef, useState
} from "react";

import store from "@state/createStore";
import { DeviceTicket } from "@state/types";
import { SCAN_STATUS } from "./types";

export type StickyKeys =
  | "requests"
  | "responses"
  | "scans"
  | "localTicketList"
  | "gateId"
  | "sessionExpiry";

export const TIMEOUT_MSG =
  "Taking too long to respond, check the internet connection or try again.";

type State<T> = { data?: T; error?: any };

export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function handlePromise<T>(promise: () => Promise<T>): Promise<State<T>> {
  return promise()
    .then((data) => ({ data, error: undefined }))
    .catch((error: Error) => {
      return { data: undefined, error };
    });
}

export function requestTimeout<T>(promise: Promise<T>, ms = 5000) {
  return Promise.race([
    Promise.resolve(promise),
    new Promise((_resolve, reject) => {
      setTimeout(() => {
        reject(new Error(TIMEOUT_MSG));
      }, ms);
    }),
  ]);
}

export function useStickyState<S>(
  defaultValue: S | (() => S),
  key: StickyKeys
): [S, Dispatch<SetStateAction<S>>] {
  const [value, setValue] = useState(() => {
    if (typeof window !== "undefined") {
      // the variable is defined
      const stickyValue = window.localStorage.getItem(key);

      return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    }

    return null;
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);
  return [value, setValue];
}

export function setToLocalStorageWithKey(key: StickyKeys, value: any) {
  return window.localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorageWithKey(key: StickyKeys) {
  if (typeof window !== "undefined") {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : undefined;
  }
  return null;
}

export function arraysEquality(array1: any[], array2: any[]) {
  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  );
}

export function debounce(callback: any, wait: number) {
  let timeout: any = null;
  // @ts-ignore
  return (...args) => {
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
  };
}

export const truncateString = (str: string, pattern: string, char: string) => {
  const truncateBefore = str.slice(str.indexOf(pattern) + pattern.length);
  return truncateBefore.slice(0, truncateBefore.indexOf(char));
};

export const determineTicketStatus = (result: any) => {
  let setStatus: SCAN_STATUS;
  let setTicket;
  let errorMsg;
  const { status, ticket, message } = result.scanTicket;

  switch (status.toString()) {
    case "OK":
      setStatus = "success";
      setTicket = ticket;
      break;
    case "ERROR":
      if (ticket !== null) {
        if (message.toLowerCase().includes("not set")) {
          setStatus = "issue";
        } else if (message.toLowerCase().includes("incorrect gate")) {
          setStatus = "wrongGate";
        } else {
          setStatus = "issue";
        }
        setTicket = ticket;
        errorMsg = message;
        break;
      }
      setStatus = "error";
      errorMsg = message;
      break;
    default:
      setStatus = "idle";
      break;
  }

  return { setStatus, setTicket, errorMsg };
};

export function getStoreBaseUrl() {
  return store.getState().baseUrl;
}

export function checkForIOS() {
  if (typeof navigator === "undefined") {
    return;
  }

  return (
    ["iPhone Simulator", "iPod Simulator", "iPhone", "iPod"].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export const ticketHolderName = (ticket: DeviceTicket | undefined) => {

  if (ticket?.firstName) {
    return `${ticket?.firstName} ${ticket?.lastName ?? ""}`.trim();
  }

  if (ticket?.user?.firstName) {
    return `${ticket?.user?.firstName} ${ticket?.user?.lastName ?? ""}`.trim();
  }

  if (ticket?.buyerInformation?.firstName) {
    return `${ticket?.buyerInformation?.firstName} ${ticket?.buyerInformation?.lastName ?? ""}`.trim();
  }

  return "";
}


export const getTicketTypeName = (ticket: DeviceTicket | undefined ) => {
  if (ticket?.seatZone && ticket?.ticketTypeName) {
    return `${ticket?.seatZone} - ${ticket?.ticketTypeName}`;
  }

  return ticket?.ticketTypeName as string;
}