import * as Sentry from "@sentry/gatsby";
import { GraphQLClient } from "graphql-request";

import { ticketStorage } from "@util/cache";
import { pages } from "@util/constants";
import { navigate } from "gatsby";
import { toast } from "react-toastify";
import { getSdk } from "../graphql/sdk";
import store from "./../state/createStore";
import { extractGraphQLErrorProperties } from "./graphql-error";
interface Params {
  baseUrl: string;
  orgId?: string | undefined;
}

const client = (baseUrl: string, orgId: string | undefined) =>
  new GraphQLClient(baseUrl, {
    credentials: "include",
    headers: {
      ...(orgId && {
        "Flicket-Org-Id": orgId,
      })
    },
  });

export const sdk = ({ baseUrl, orgId }: Params) =>
  getSdk(client(baseUrl, orgId), sentryWrapper)


type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const sentryWrapper: SdkFunctionWrapper = async <T>(
  action: () => Promise<T>
) => {

  return action().catch((error) => {
    const { status, statusCode, message } = extractGraphQLErrorProperties(
      error as Error
    );

    if (message.match(/unauthorized/i) || status === 401 || statusCode === 401) {
      ticketStorage?.clear();
      store.dispatch({ type: "STATE/CLEAR_STATE" });
      navigate(pages.login);
      toast.error("Please login first");
    } else {
      Sentry.captureException(error, {
        tags: {
          status,
          statusCode
        },
      });
    }


    throw error;
  });
};