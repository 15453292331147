import { get } from "lodash";

const errorLocations = {
  graphQL: "response.errors",
};

const graphQLExtensionLocations = {
  statusCode: "extensions.exception.response.statusCode",
  status: "extensions.exception.response.status",
};

export interface ExtendedGraphQLError {
  message: string;
  status?: number;
  statusCode?: number;
}

export const getErrorMessage = (
  err: unknown,
  type: keyof typeof errorLocations = "graphQL"
) => {
  const errorLocation = errorLocations[type];
  let message: string = get(
    err as Error,
    errorLocation,
    ""
  );

  if (Array.isArray(message)) {
    message = message[0]?.message || message[0];
  }

  return message;
};

const getErrors = (err: Error) => {
  const errors = get(err, errorLocations.graphQL) as unknown;
  return (Array.isArray(errors) ? errors[0] : errors) as Error;
};

export const extractGraphQLErrorProperties = (err: Error): ExtendedGraphQLError => {
  const message = getErrorMessage(err, "graphQL");
  const error = getErrors(err);

  return {
    status: get(error, graphQLExtensionLocations.status),
    statusCode: get(error, graphQLExtensionLocations.statusCode),
    message,
  };
};