
import { clear, createStore, delMany, get, keys, set, setMany, update, values } from 'idb-keyval';
import { openDB } from "idb";
import { DeviceTicket } from '@state/types';
import { ScanTicketBulk } from '@graphql/sdk';


const createStorage = <T>(storageName: string) => {
  if (typeof window === "undefined") return;

  const store = createStore(storageName, storageName);

  return {
    keys: () => keys(store),
    values: () => values<T>(store),
    clear: () => clear(store),
    get: (key: IDBValidKey) => get<T>(key, store),
    set: (key: IDBValidKey, val: T) => set(key, val, store),
    update: (key: IDBValidKey, callback: (oldValue: T | undefined) => T) => update<T>(key, callback, store),
    setMany: (entries: [IDBValidKey, T][]) => setMany(entries, store),
    delMany: (keys: IDBValidKey[]) => delMany(keys, store),
    search: async (query: string, callback: (query: string, item: T) => boolean): Promise<T[]> => {

      const db =  await openDB(storageName);
      let cursor = await db.transaction(storageName).store.openCursor();
      let results: T[]  = [];

      while (cursor) {
        const match = callback(query, cursor.value as T);
        if (match) {
          results.push(cursor.value)
        }

        cursor = await cursor.continue();
      }

      return results;
    }
  }
}

// https://www.npmjs.com/package/idb-keyval
export const ticketStorage = createStorage<DeviceTicket>('tickets');
export const scanStorage = createStorage<ScanTicketBulk>('scans');